import React from "react";
import styles from "./tickethistory.module.css";
import { getList } from "../../listStore";
import CacheContext from "../../contexts/CacheContext";

const formatFunctions = getList("formatFunctions");

const format_time_string = (str) => {
    const arr = str.split(":");

    return `${arr[0]}:${arr[1]} ${arr[2].split(" ")[1]}`;
};

const highlight_message = (txt, key) => (
    <span className={styles.message_highlight} key={key}>
        {typeof txt === "string" ? (
            txt
        ) : (
            <i style={{ fontWeight: "400" }}>nothing</i>
        )}
    </span>
);

const generate_log_message = (log, attributes, credentials) => {
    log.type = log.log_type;
    log.old_values = log.old_values || {};
    log.new_values = log.new_values || {};
    const user = credentials.find((user) => user.id === log.userid);
    const userel = highlight_message(
        user.username,
        log.timestamp + "-username"
    );

    if (log.type === "ticket-created") {
        return [userel, " created the ticket"];
    }

    if (log.type === "ticket-updated") {
        const atts = attributes.filter((attribute) =>
            Object.keys(log.old_values).includes(attribute.id)
        );
        const crumbs = [userel, " changed "];

        const attribute = atts[0];

        if (!attribute) return [userel, " changed an unknown attribute"];

        const attribute_name = highlight_message(
            attribute.name,
            log.timestamp + "-" + attribute.id
        );

        if (attribute.type === "list") {
            crumbs.push("the list ", attribute_name);
            return crumbs;
        }

        crumbs.push(attribute_name);

        let originalTxt = formatFunctions[attribute.type](
            log.old_values[attribute.id],
            attribute
        );

        let updatedTxt = formatFunctions[attribute.type](
            log.new_values[attribute.id],
            attribute
        );

        if (
            !log.old_values[attribute.id] ||
            typeof log.old_values[attribute.id] === "object"
        ) {
            originalTxt = <i>nothing</i>;
        }

        if (
            !log.old_values[attribute.id] ||
            typeof log.new_values[attribute.id] === "object"
        ) {
            updatedTxt = <i>nothing</i>;
        }

        crumbs.push(
            " from ",
            highlight_message(originalTxt, log.timestamp + "-original-value"),
            " to ",
            highlight_message(updatedTxt, log.timestamp + "-updated-value")
        );

        return crumbs;
    }

    return "i'm not sure how to log this type of message";
};

const TicketHistory = (props) => {
    const { ticketid, hubid } = props;
    const { request } = React.useContext(CacheContext);
    const [log_items, set_log_items] = React.useState(-1);
    const [attributes, set_attributes] = React.useState(-1);
    const [credentials, set_credentials] = React.useState(-1);

    React.useEffect(() => {
        const hub_req = request("hub", hubid);

        if (!hub_req.has_loaded) return;

        let atts = [];
        for (const group of hub_req.data.groups) {
            const group_req = request("group", group.id);

            if (!group_req.has_loaded) return;

            atts = atts.concat(group_req.data.attributes);
        }
        set_attributes(atts);

        const users_req = request("users");

        if (!users_req.has_loaded) return;

        set_credentials(users_req.data.users);

        const { has_loaded, data } = request("ticket_logs", ticketid);

        if (!has_loaded) return;

        set_log_items(data);
    }, [request, ticketid, hubid]);

    // React.useEffect(() => {
    //     return Server.source("logs").subscribe((logs) => {
    //         const pseudo = {};

    //         for (const timestamp in logs) {
    //             const log = logs[timestamp];

    //             if (log.params.ticketid === ticketid)
    //                 pseudo[timestamp] = { ...log };
    //         }

    //         set_log_items(pseudo);
    //     });
    // }, [ticketid, hubid]);

    // React.useEffect(() => {
    //     const on_groups = (groups) => {
    //         const pseudo = {};
    //         for (const groupid in groups) {
    //             const group = groups[groupid];
    //             const attributes = group.attributes || {};

    //             for (const attributeid in attributes) {
    //                 pseudo[attributeid] = { ...attributes[attributeid] };
    //             }
    //         }
    //         set_attributes(pseudo);
    //     };

    //     Server.source("hubs")
    //         .source(hubid)
    //         .source("groups")
    //         .subscribe(on_groups);

    //     return () => {
    //         Server.source("hubs")
    //             .source(hubid)
    //             .source("groups")
    //             .unsubscribe(on_groups);
    //     };
    // }, [hubid]);

    // React.useEffect(() => {
    //     const onCredentials = (c) => set_credentials(c);

    //     Server.source("credentials").subscribe(onCredentials);

    //     return () => {
    //         Server.source("credentials").unsubscribe(onCredentials);
    //     };
    // }, []);

    if (log_items === -1 || attributes === -1 || credentials === -1)
        return (
            <div className={styles.wrap}>
                <div
                    style={{
                        textAlign: "center",
                        fontSize: "24px",
                        padding: "50px 0px",
                        color: "var(--link-text-color)",
                    }}
                >
                    <i className="fas fa-circle-notch fa-spin"></i>
                </div>
            </div>
        );

    // const groups = {};

    // for (const timestamp in log_items) {
    //     const d = new Date(+timestamp);
    //     const day_string = [
    //         d.getMonth() + 1,
    //         d.getDate(),
    //         d.getFullYear(),
    //     ].join("/");

    //     if (!groups[day_string])
    //         groups[day_string] = {
    //             date: day_string,
    //             items: {},
    //         };

    //     groups[day_string].items[timestamp] = { ...log_items[timestamp] };
    // }

    return (
        <div className={styles.wrap}>
            <div
                style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    color: "var(--dark-text-color)",
                    marginBottom: "30px",
                }}
            >
                Ticket History
            </div>
            {log_items.length === 0 && (
                <div
                    style={{
                        color: "var(--light-text-color)",
                        fontSize: "18px",
                        textAlign: "center",
                        padding: "50px 0px",
                    }}
                >
                    No history to display
                </div>
            )}
            {Object.entries(
                log_items
                    .sort((a, b) => b.timestamp.localeCompare(a.timestamp))
                    .reduce((ob, log) => {
                        const d = new Date(log.timestamp).toLocaleDateString();
                        if (!ob[d]) ob[d] = [];

                        ob[d].push(log);
                        return ob;
                    }, {})
            ).map(([date, logs]) => {
                const isToday = new Date().toLocaleDateString() === date;

                return (
                    <div key={date} className={styles.log_group}>
                        <div
                            style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                color: "var(--dark-text-color)",
                                marginBottom: "10px",
                            }}
                        >
                            {isToday ? "Today" : date}
                        </div>
                        {logs.map((log) => {
                            return (
                                <div
                                    className={styles.log_item}
                                    key={log.timestamp}
                                >
                                    <div className={styles.log_item_date}>
                                        {format_time_string(
                                            new Date(
                                                log.timestamp
                                            ).toLocaleTimeString()
                                        )}
                                    </div>
                                    <div className={styles.log_item_message}>
                                        {generate_log_message(
                                            log,
                                            attributes,
                                            credentials
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                        {/* {logs.map((log) => (
                            <div
                                className={styles.log_item}
                                key={log.timestamp}
                            >
                                <div className={styles.log_item_date}>
                                    {format_time_string(
                                        new Date(
                                            +log.timestamp
                                        ).toLocaleTimeString()
                                    )}
                                </div>
                                <div className={styles.log_item_message}>
                                    {generate_log_message(
                                        log,
                                        attributes,
                                        credentials
                                    )}
                                </div>
                            </div>
                        ))} */}
                    </div>
                );
            })}
            {/* {arrayify(groups).length === 0 && (
                <div
                    style={{
                        color: "var(--light-text-color)",
                        fontSize: "18px",
                        textAlign: "center",
                        padding: "50px 0px",
                    }}
                >
                    No history to display
                </div>
            )}
            {arrayify(groups).length !== 0 &&
                arrayify(groups)
                    .sort((a, b) => {
                        const d1 = new Date(+a.date);
                        const d2 = new Date(+b.date);

                        return d1.getTime() - d2.getTime();
                    })
                    .map((group) => {
                        return (
                            <div key={group.date} className={styles.log_group}>
                                <div style={{ fontWeight: "600" }}>
                                    {get_date_string(group.date)}
                                </div>
                                <div className={styles.log_items}>
                                    {arrayify(group.items, "timestamp")
                                        .sort((a, b) => {
                                            const d1 = new Date(+a.timestamp);
                                            const d2 = new Date(+b.timestamp);

                                            return d1.getTime() - d2.getTime();
                                        })
                                        .map((item) => {
                                            return (
                                                <div
                                                    className={styles.log_item}
                                                    key={item.timestamp}
                                                >
                                                    <div
                                                        className={
                                                            styles.log_item_date
                                                        }
                                                    >
                                                        {format_time_string(
                                                            new Date(
                                                                +item.timestamp
                                                            ).toLocaleTimeString()
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.log_item_message
                                                        }
                                                    >
                                                        {generate_log_message(
                                                            item,
                                                            attributes,
                                                            credentials
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        );
                    })} */}
        </div>
    );
};

export default TicketHistory;
