import React from "react";
// import Server from "../../Server";
import styles from "./hub.module.css";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import TicketPreview from "../ticketpreview/ticketpreview";
import TicketView from "../ticketview/ticketview";
import _ from "lodash";
import CacheContext from "../../contexts/CacheContext";
import { transformTemplate } from "../../transformTemplate";

const BASE_LIMIT = 25;

const Hub = (props) => {
    const { hubid } = props.match.params;
    const { request } = React.useContext(CacheContext);
    const [hub, set_hub] = React.useState(-1);
    const [filter, setFilter] = React.useState(-1);
    const [searchQuery, setSearchQuery] = React.useState();
    const [tickets, set_tickets] = React.useState(-1);
    const [wrapRef, setWrapRef] = React.useState(-1);
    const [previews, set_previews] = React.useState(-1);
    const [all_attributes, set_all_attributes] = React.useState(-1);
    const [limit, set_limit] = React.useState(BASE_LIMIT);
    const [meta, set_meta] = React.useState(-1);
    const [loading_tickets, set_loading_tickets] = React.useState(false);

    const hide_away_count = React.useRef(0);
    const tickets_wrap = React.useRef();
    const scrollTop = React.useRef(0);

    React.useEffect(() => {
        const hub_req = request("hub", hubid);

        if (!hub_req.has_loaded) return;

        set_hub(hub_req.data);

        if (filter === -1) {
            return;
        }

        const p = {
            title: hub_req.data.title_preview,
        };
        const a = [];

        for (const group of hub_req.data.groups) {
            const { has_loaded, data } = request("group", group.id);

            if (!has_loaded) return;

            p[group.id] = group.preview;
            a.push(...data.attributes);
        }

        set_previews(p);
        set_all_attributes(a);

        set_loading_tickets(true);
        const tickets_req = request("tickets", {
            hubid,
            search: searchQuery || "",
            filter,
            limit,
        });

        if (!tickets_req.has_loaded) return;

        set_loading_tickets(false);
        set_tickets(tickets_req.data.ticketids || []);
        set_meta(tickets_req.data.meta);
    }, [request, hubid, filter, searchQuery, limit]);

    React.useEffect(() => {
        set_limit(BASE_LIMIT);
    }, [filter, searchQuery]);

    // React.useEffect(() => {
    //     console.log(all_attributes);
    // }, [all_attributes]);

    React.useEffect(() => {
        if (filter === -1 && hub.filters) {
            let foundFilter = hub.filters.find((filt) => filt.index === 0);

            if (foundFilter) setFilter(foundFilter.id);
        }
    }, [hub.filters, filter]);

    React.useEffect(() => {
        hide_away_count.current++;
        if (filter === -1 || searchQuery === -1 || hide_away_count.current <= 2)
            return;

        props.history.push("/" + hubid);
    }, [searchQuery, filter, hubid, props.history]);

    React.useEffect(() => {
        if (!scrollTop.current || !tickets_wrap.current) return;

        tickets_wrap.current.scrollTop = scrollTop.current;
    }, [tickets]);

    const update_search_query = _.debounce(setSearchQuery, 250);

    if ([hub, filter, previews, all_attributes].includes(-1))
        return (
            <div
                style={{
                    padding: "50px 0px",
                    textAlign: "center",
                    fontSize: "24px",
                }}
            >
                <i className="link fas fa-circle-notch fa-spin"></i>
            </div>
        );

    const sortedTickets = (tickets === -1 ? [] : tickets).sort(
        (a, b) => b.dateCreated - a.dateCreated
    );

    return (
        <div className={styles.wrap}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div className={styles.top}>
                    <Link
                        to={"/" + hubid}
                        className={"delink " + styles.hubName}
                    >
                        {hub.name}
                    </Link>
                    <button
                        className="button"
                        onClick={() =>
                            props.history.push(props.match.url + "/new")
                        }
                    >
                        <i
                            className="fas fa-plus"
                            style={{
                                color: "var(--logo-text-color)",
                                marginRight: "6px",
                                fontSize: "14px",
                            }}
                        ></i>
                        New Ticket
                    </button>
                </div>
                <div className={styles.queryBar}>
                    <input
                        type="text"
                        className="input"
                        placeholder={`Search ${hub.name}...`}
                        onChange={(e) => update_search_query(e.target.value)}
                    />
                    <div className={styles.filters}>
                        {hub.filters
                            .sort((a, b) => a.index - b.index)
                            .map((filterInfo) => {
                                return (
                                    <div
                                        key={filterInfo.id}
                                        className={
                                            styles.filter +
                                            " " +
                                            (filterInfo.id === filter &&
                                                styles.active)
                                        }
                                        onClick={() => setFilter(filterInfo.id)}
                                    >
                                        {filterInfo.name}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
            <div
                className={
                    styles.tickets + " " + (loading_tickets && styles.loading)
                }
                id="tickets-wrap"
                ref={tickets_wrap}
                onScroll={(e) => {
                    scrollTop.current = e.target.scrollTop;
                }}
            >
                {sortedTickets.length === 0 ? (
                    loading_tickets ? (
                        <div
                            style={{
                                fontSize: "18px",
                                textAlign: "center",
                                color: "var(--link-text-color)",
                            }}
                        >
                            <i className="fas fa-circle-notch fa-spin"></i>
                        </div>
                    ) : (
                        <span
                            style={{
                                textAlign: "center",
                                color: "var(--light-text-color)",
                                fontSize: "24px",
                                marginTop: "30px",
                            }}
                        >
                            No Tickets To Display...
                        </span>
                    )
                ) : (
                    sortedTickets.map((ticket) => {
                        return (
                            <TicketPreview
                                key={ticket.id}
                                ticket={ticket}
                                previews={previews}
                                attributes={all_attributes}
                                statuses={hub.statuses}
                            />
                        );
                    })
                )}

                {limit < meta.total && (
                    <div
                        style={{
                            padding: "30px 0px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <button
                            className="button"
                            onClick={() => set_limit(limit + BASE_LIMIT)}
                        >
                            {loading_tickets ? (
                                <div>
                                    Loading...{" "}
                                    <i
                                        className="fas fa-circle-notch fa-spin"
                                        style={{ marginLeft: "6px" }}
                                    ></i>
                                </div>
                            ) : (
                                "Load More"
                            )}
                        </button>
                    </div>
                )}
            </div>
            <div
                className={styles.ticketWrap + " " + styles.active}
                ref={setWrapRef}
            >
                {wrapRef !== -1 && (
                    <Switch>
                        <Route
                            path={props.match.url + "/"}
                            exact
                            render={() => {
                                if (wrapRef)
                                    wrapRef.classList.remove(styles.active);
                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexGrow: "1",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: "24px",
                                                fontWeight: "400",
                                                color:
                                                    "var(--light-text-color)",
                                            }}
                                        >
                                            No Ticket Selected...
                                        </div>
                                    </div>
                                );
                            }}
                        />
                        <Route
                            path={"/:hubid/:ticketid/all"}
                            render={(nav_props) => {
                                setFilter(hub.all_filter);
                                hide_away_count.current--;
                                const {
                                    hubid,
                                    ticketid,
                                } = nav_props.match.params;

                                return (
                                    <Redirect to={`/${hubid}/${ticketid}`} />
                                );
                            }}
                        />
                        <Route
                            path={"/:hubid/:ticketid"}
                            render={(navProps) => {
                                if (wrapRef)
                                    wrapRef.classList.add(styles.active);

                                if (tickets === -1) return null;

                                const selected_ticket = tickets.find(
                                    (ticket) =>
                                        ticket.id ===
                                        navProps.match.params.ticketid
                                );

                                return (
                                    <TicketView
                                        {...navProps}
                                        attributes={all_attributes}
                                        statuses={hub.statuses}
                                        get_title={() =>
                                            transformTemplate(
                                                previews.title,
                                                selected_ticket,
                                                all_attributes
                                            )
                                        }
                                        ticket={selected_ticket}
                                    />
                                );
                            }}
                        />
                    </Switch>
                )}
            </div>
        </div>
    );
};

export default Hub;
