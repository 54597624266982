import React from "react";
import { getList } from "../../../listStore";
import arrayify from "../../../arrayify";
import styles from "./select.module.css";
import CacheContext from "../../../contexts/CacheContext";

const SelectViewItem = (props) => {
    const { ticketid } = props;
    const { execute } = React.useContext(CacheContext);
    const [isEditing, setIsEditing] = React.useState(false);

    const formatter = getList("formatFunctions").select;
    const options = props.attribute.options.map((option) => {
        return (
            <div
                className={
                    styles.option +
                    " " +
                    (props.value === option.id && styles.selected)
                }
                key={option.id}
                onClick={async () => {
                    setIsEditing(false);

                    if (props.value === option.id) return;

                    const currentGroups = props.ticket.presentGroups;

                    const needsRemoval = props.attribute.options
                        .map((option) => option.add)
                        .filter((v) => v !== "");

                    const newGroups = arrayify(currentGroups)
                        .filter(
                            (group) => !needsRemoval.includes(group.groupid)
                        )
                        .reduce((ob, group) => {
                            ob[group.id] = { groupid: group.groupid };
                            return ob;
                        }, {});

                    if (option.add) {
                        newGroups[option.add] = {
                            groupid: option.add,
                        };
                    }

                    execute("update_ticket", {
                        ticketid,
                        changes: {
                            [props.attribute.id]: option.id,
                            presentGroups: newGroups,
                        },
                    });

                    // Server.source("tickets")
                    //     .source(props.attribute.hubid)
                    //     .source(props.attribute.ticketid)
                    //     .source(props.attribute.id)
                    //     .set(option.id)
                    //     .then(async () => {
                    //         const currentGroups = await Server.source("tickets")
                    //             .source(props.attribute.hubid)
                    //             .source(props.attribute.ticketid)
                    //             .source("presentGroups")
                    //             .get();

                    //         const needsRemoval = arrayify(
                    //             props.attribute.options
                    //         )
                    //             .map((option) => {
                    //                 return option.add;
                    //             })
                    //             .filter((v) => v !== "");

                    //         const newGroups = arrayify(currentGroups)
                    //             .filter(
                    //                 (group) =>
                    //                     !needsRemoval.includes(group.groupid)
                    //             )
                    //             .reduce((ob, group) => {
                    //                 ob[group.id] = {
                    //                     groupid: group.groupid,
                    //                 };
                    //                 return ob;
                    //             }, {});

                    //         if (option.add) {
                    //             newGroups[option.add] = {
                    //                 groupid: option.add,
                    //             };
                    //         }

                    //         Server.source("tickets")
                    //             .source(props.attribute.hubid)
                    //             .source(props.attribute.ticketid)
                    //             .source("presentGroups")
                    //             .set(newGroups);
                    //     });
                }}
            >
                {option.name}
            </div>
        );
    });

    React.useEffect(() => {
        const onClick = () => setIsEditing(false);

        if (isEditing) {
            window.addEventListener("click", onClick);
        }

        return () => {
            window.removeEventListener("click", onClick);
        };
    }, [isEditing]);

    if (!isEditing) {
        return (
            <div
                onClick={() => setIsEditing(true)}
                style={{ cursor: "pointer" }}
            >
                {formatter(props.value, props.attribute) ||
                    (props.attribute.showInForm ? (
                        <span
                            style={{
                                color: "var(--light-text-color)",
                                fontWeight: "400",
                            }}
                        >
                            Nothing Here...
                        </span>
                    ) : (
                        <div style={{ display: "flex", padding: "5px 0px" }}>
                            {options}
                        </div>
                    ))}
            </div>
        );
    } else {
        return (
            <div
                style={{ display: "flex", padding: "5px 0px" }}
                onClick={(e) => e.stopPropagation()}
            >
                {options}
            </div>
        );
    }
};

export default SelectViewItem;
