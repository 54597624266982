import React from "react";
import { withRouter } from "react-router-dom";
import Dropdown from "../dropdown/dropdown";
import styles from "./nav.module.css";
import Logo from "../logo/logo";
import { AuthContext } from "../../contexts";
import useConfig from "../../hooks/useConfig";

const Nav = (props) => {
    const { config, has_premium } = useConfig();
    const { auth, logout } = React.useContext(AuthContext);

    if (!config) return null;

    return (
        <div className={styles.wrap}>
            <div
                className={styles.logo}
                onClick={() => props.history.push("/")}
            >
                <Logo />
                <div className={styles.logoText}>{config.name}</div>
            </div>
            {auth !== -1 && (
                <div>
                    <Dropdown
                        baseText={auth.username}
                        toggleClass={styles.user}
                        direction="left"
                    >
                        {auth.role === "admin" && [
                            <Dropdown.Item
                                key="hub-settings-link"
                                onClick={() => {
                                    props.history.push("/settings");
                                }}
                            >
                                Settings
                            </Dropdown.Item>,
                        ]}
                        {!has_premium && (
                            <Dropdown.Item
                                onClick={() => {
                                    window.location = `https://hubtrk.com/premium/?code=${config.code}`;
                                }}
                            >
                                <div
                                    className="link"
                                    style={{ fontWeight: "500" }}
                                >
                                    Upgrade To Hubtrack Premium
                                </div>
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={logout}>
                            <div
                                style={{
                                    color: "var(--danger-text-color)",
                                    fontWeight: "600",
                                }}
                            >
                                Logout
                            </div>
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            )}
        </div>
    );
};

export default withRouter(Nav);
