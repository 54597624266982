const merge_arr_by_key = (arr1, arr2, key = "id") => {
    const final_arr = [...(arr1 || [])];

    for (const val of arr2 || []) {
        if (!final_arr.some((val_final) => val_final[key] === val[key])) {
            final_arr.push(val);
        } else {
            const index = final_arr.findIndex(
                (val_final) => val_final[key] === val[key]
            );

            final_arr[index] = {
                ...final_arr[index],
                ...val,
            };
        }
    }

    return final_arr;
};

export default merge_arr_by_key;
