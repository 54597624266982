// import React from "react";
// import styles from "./logs.module.css";
// import Server from "../../Server";
// import { Link } from "react-router-dom";
// import { getList } from "../../listStore";
// import Waiter from "../waiter/waiter";
// import { transformTemplate } from "../../transformTemplate";

// const formatFunctions = getList("formatFunctions");

// const LogMessage = (props) => {
//     const { line } = props;

//     const fontWeight600 = (txt) => {
//         return (
//             <span
//                 style={{ fontWeight: "600", color: "var(--base-text-color)" }}
//             >
//                 {txt}
//             </span>
//         );
//     };

//     const formatted = (value, attribute) => {
//         if (
//             !value ||
//             value === undefined ||
//             (typeof value === "object" && Object.keys(value).length === 0) ||
//             value === ""
//         )
//             return (
//                 <i
//                     style={{
//                         fontWeight: "400",
//                         color: "var(--light-text-color)",
//                     }}
//                 >
//                     nothing
//                 </i>
//             );

//         if (attribute && formatFunctions[attribute.type])
//             return formatFunctions[attribute.type](value, attribute);
//         else return "";
//     };

//     const generateMessage = () => {
//         const { hubid, ticketid, attributeid, original, updated } = line.params;
//         const titleTemplate = props.info.hubs[hubid].previews.title;
//         const ticket = props.info.tickets[hubid][ticketid];

//         const attribute = props.info.attributes[attributeid];

//         const hublink = (
//             <Link to={"/" + hubid}>
//                 {(props.info.hubs[hubid] || {}).name || "a now deleted hub"}
//             </Link>
//         );
//         const ticketlink = (
//             <span>
//                 {ticket ? (
//                     <span>
//                         the ticket{" "}
//                         <Link to={"/" + hubid + "/" + ticketid + "/all"}>
//                             <Waiter
//                                 initialValue="Loading..."
//                                 func={() => {
//                                     return transformTemplate(
//                                         titleTemplate,
//                                         hubid,
//                                         "base",
//                                         ticket
//                                     );
//                                 }}
//                             />
//                         </Link>
//                     </span>
//                 ) : (
//                     "a now deleted ticket"
//                 )}
//             </span>
//         );

//         const username = fontWeight600(props.info.credentials[line.user]);

//         if (line.type === "change") {
//             const attributename = fontWeight600(
//                 (props.info.attributes[attributeid] || {}).name ||
//                     "a now deleted attribute"
//             );

//             return (
//                 <span>
//                     {username} changed {attributename}{" "}
//                     {attribute !== undefined && (
//                         <span>
//                             <span>
//                                 {attribute.type !== "list" && (
//                                     <span>
//                                         from{" "}
//                                         {fontWeight600(
//                                             formatted(
//                                                 original,
//                                                 props.info.attributes[
//                                                     attributeid
//                                                 ]
//                                             )
//                                         )}{" "}
//                                         to{" "}
//                                         {fontWeight600(
//                                             formatted(
//                                                 updated,
//                                                 props.info.attributes[
//                                                     attributeid
//                                                 ]
//                                             )
//                                         )}{" "}
//                                     </span>
//                                 )}
//                             </span>
//                             <span>in {ticketlink}</span>
//                         </span>
//                     )}{" "}
//                     in {hublink}
//                 </span>
//             );
//         }

//         if (line.type === "import") {
//             return (
//                 <span>
//                     {username} imported tickets into {hublink}
//                 </span>
//             );
//         }

//         if (line.type === "delete_ticket") {
//             return (
//                 <span>
//                     {username} deleted the ticket {ticketid} in {hublink}
//                 </span>
//             );
//         }

//         if (line.type === "create_ticket") {
//             return (
//                 <span>
//                     {username} created {ticketlink} in {hublink}
//                 </span>
//             );
//         }

//         if (line.type === "delete_all") {
//             return (
//                 <span>
//                     {username} deleted all the tickets in {hublink}
//                 </span>
//             );
//         }
//     };

//     const getDate = (m) => {
//         const d = new Date(m);

//         const formatTimeString = (str) => {
//             const arr = str.split(":");

//             return `${arr[0]}:${arr[1]} ${arr[2].split(" ")[1]}`;
//         };

//         return (
//             <span className={styles.date}>
//                 {formatTimeString(d.toLocaleTimeString())}
//             </span>
//         );
//     };

//     return (
//         <div className={styles.line}>
//             {getDate(line.date)}
//             <span>{generateMessage()}</span>
//         </div>
//     );
// };

// const LogGroup = (props) => {
//     const getDateString = () => {
//         const d = new Date(props.date);
//         const today = new Date();

//         if (
//             d.getDate() === today.getDate() &&
//             d.getMonth() === today.getMonth() &&
//             d.getFullYear() === today.getFullYear()
//         ) {
//             return "Today";
//         } else {
//             return props.date;
//         }
//     };

//     return (
//         <div className={styles.group}>
//             <div className={styles.grouptitle}>{getDateString()}</div>
//             {props.lines
//                 .map((line) => ({ ...line, date: +line.date }))
//                 .sort((a, b) => b.date - a.date)
//                 .map((line) => {
//                     return (
//                         <LogMessage
//                             line={line}
//                             info={props.info}
//                             key={line.date}
//                         />
//                     );
//                 })}
//         </div>
//     );
// };

// const Logs = (props) => {
//     const [businessInfo, setBusinessInfo] = React.useState(-1);
//     const [logs, setLogs] = React.useState(-1);
//     const [info, setInfo] = React.useState(-1);
//     const [log_count_limit, set_log_count_limit] = React.useState(50);

//     React.useEffect(() => {
//         const onLogs = (l) => {
//             setLogs({ ...l });
//         };

//         const onHubs = (h) => {
//             const pseudo = {};
//             const attpseudo = {};

//             for (const hubkey in h) {
//                 pseudo[hubkey] = h[hubkey];

//                 const hub = h[hubkey];

//                 for (const groupid in hub.groups || {}) {
//                     const atts = hub.groups[groupid].attributes || {};

//                     for (const attid in atts) {
//                         attpseudo[attid] = atts[attid];
//                     }
//                 }
//             }

//             setInfo((old) => ({
//                 ...(old || {}),
//                 hubs: pseudo,
//                 attributes: attpseudo,
//             }));
//         };

//         const onCredentials = (u) => {
//             const pseudo = {};

//             for (const userid in u) {
//                 pseudo[userid] = u[userid].username;
//             }

//             setInfo((old) => ({ ...(old || {}), credentials: pseudo }));
//         };

//         const onTickets = (t) => {
//             setInfo((old) => ({ ...(old || {}), tickets: t }));
//         };

//         const onBusinessInfo = (v) => setBusinessInfo(v);

//         Server.source("logs").subscribe(onLogs);
//         Server.source("credentials").subscribe(onCredentials);
//         Server.source("hubs").subscribe(onHubs);
//         Server.source("tickets").subscribe(onTickets);

//         Server.source("businessInfo").subscribe(onBusinessInfo);

//         return () => {
//             Server.source("logs").unsubscribe(onLogs);
//             Server.source("credentials").unsubscribe(onCredentials);
//             Server.source("hubs").unsubscribe(onHubs);
//             Server.source("businessInfo").unsubscribe(onBusinessInfo);
//             Server.source("tickets").unsubscribe(onTickets);
//         };
//     }, []);

//     if (logs === -1 || businessInfo === -1) return <div>Loading...</div>;

//     const groups = {};

//     for (const [timekey] of Object.entries(logs)
//         .reverse()
//         .slice(0, log_count_limit)
//         .reverse()) {
//         const d = new Date(+timekey);
//         const key = [d.getMonth() + 1, d.getDate(), d.getFullYear()].join("/");

//         if (!groups[key]) groups[key] = [];

//         groups[key].push({
//             ...logs[timekey],
//             date: +timekey,
//         });
//     }

//     const toReturn = [];

//     const keys = Object.keys(groups).sort((a, b) => {
//         const d1 = new Date(a);
//         const d2 = new Date(b);

//         return d2.getTime() - d1.getTime();
//     });

//     for (const datekey of keys) {
//         toReturn.push(
//             <LogGroup
//                 key={datekey}
//                 date={datekey}
//                 lines={groups[datekey]}
//                 info={info}
//             />
//         );
//     }

//     if (businessInfo.premium === false) {
//         return (
//             <div className="notPremium">
//                 <div>
//                     <span
//                         className="premium-badge"
//                         style={{ marginRight: "8px" }}
//                     >
//                         <i className="fas fa-star"></i>
//                     </span>
//                     Logs are a Hubtrack Premium Feature
//                 </div>
//                 <div>
//                     With logs, you can keep track of every change that occurs in
//                     your hub, automatically. This allows you to see who is
//                     making changes to tickets. To use logs, sign up for Hubtrack
//                     Premium. We promise, you're gonna love it.
//                 </div>
//                 <a
//                     href={
//                         "https://hubtrk.com/premium/?id=" +
//                         businessInfo.stripeid
//                     }
//                     className="button is-link"
//                 >
//                     Get Hubtrack Premium
//                 </a>
//             </div>
//         );
//     }

//     if (toReturn.length === 0) {
//         return (
//             <div
//                 style={{
//                     textAlign: "center",
//                     fontSize: "24px",
//                     color: "var(--light-text-color)",
//                 }}
//             >
//                 No logs yet...
//             </div>
//         );
//     }

//     return (
//         <div className={styles.wrap}>
//             {toReturn}
//             {log_count_limit < Object.keys(logs).length && (
//                 <div
//                     style={{
//                         paddingBottom: "30px",
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "center",
//                     }}
//                 >
//                     <button
//                         className={`button is-light ${styles.load_more_btn}`}
//                         onClick={() =>
//                             set_log_count_limit(log_count_limit + 50)
//                         }
//                     >
//                         Load More
//                     </button>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default Logs;

import React from "react";
import styles from "./logs.module.css";
import CacheContext from "../../contexts/CacheContext";
import { getList } from "../../listStore";
import useConfig from "../../hooks/useConfig";

const formatFunctions = getList("formatFunctions");

const BASE_LIMIT = 50;

const get_time_string = (d) => {
    d = new Date(d);

    const arr = d.toLocaleTimeString().split(":");

    return `${arr[0]}:${arr[1]} ${arr[2].split(" ")[1]}`;
};

const LoginMessage = ({ log, common_data }) => {
    const user = (common_data.users || []).find(
        (user) => user.id === log.userid
    );

    if (!user) return <i className="fas fa-circle-notch fa-spin link"></i>;

    return (
        <div>
            <span style={{ fontWeight: "700" }}>{user.username}</span> logged in
        </div>
    );
};

const TicketCreatedMessage = ({ log, common_data }) => {
    const user = (common_data.users || []).find(
        (user) => user.id === log.userid
    );
    const hub = (common_data.hubs || []).find((hub) => hub.id === log.hubid);

    if (!user || !hub)
        return <i className="fas fa-circle-notch fa-spin link"></i>;

    return (
        <div>
            <span style={{ fontWeight: "700" }}>{user.username}</span> created a
            ticket in <span style={{ fontWeight: "700" }}>{hub.name}</span>
        </div>
    );
};

const TicketDeletedMessage = ({ log, common_data }) => {
    const user = (common_data.users || []).find(
        (user) => user.id === log.userid
    );
    const hub = (common_data.hubs || []).find((hub) => hub.id === log.hubid);

    if (!user || !hub)
        return <i className="fas fa-circle-notch fa-spin link"></i>;

    return (
        <div>
            <span style={{ fontWeight: "700" }}>{user.username}</span> deleted a
            ticket in <span style={{ fontWeight: "700" }}>{hub.name}</span>
        </div>
    );
};

const TicketUpdatedMessage = ({ log, common_data }) => {
    log.old_values = log.old_values || {};
    log.new_values = log.new_values || {};
    const user = (common_data.users || []).find(
        (user) => user.id === log.userid
    );
    const hub = (common_data.hubs || []).find((hub) => hub.id === log.hubid);
    const attributes = (common_data.attributes || []).filter((att) =>
        Object.keys(log.new_values || {}).includes(att.id)
    );

    if (!user || !hub)
        return <i className="fas fa-circle-notch fa-spin link"></i>;

    return (
        <div>
            <span style={{ fontWeight: "700" }}>{user.username}</span> changed{" "}
            {attributes.length === 0 ? (
                <span style={{ fontWeight: "700" }}>
                    some unknown attributes
                </span>
            ) : (
                attributes.map((att) => {
                    return (
                        <span key={log.id + "-" + att.id}>
                            <span style={{ fontWeight: "700" }}>
                                {att.name}
                            </span>{" "}
                            from{" "}
                            <span style={{ fontWeight: "700" }}>
                                {formatFunctions[att.type](
                                    log.old_values[att.id],
                                    att
                                )}
                            </span>{" "}
                            to{" "}
                            <span style={{ fontWeight: "700" }}>
                                {formatFunctions[att.type](
                                    log.new_values[att.id],
                                    att
                                )}
                            </span>
                        </span>
                    );
                })
            )}{" "}
            in a ticket in <span style={{ fontWeight: "700" }}>{hub.name}</span>
        </div>
    );
};

const LogItem = ({ log, common_data, history }) => {
    let El = () => {
        return <div>Log Type Unknown</div>;
    };
    let onClick = null;

    switch (log.log_type) {
        case "user-login":
            El = LoginMessage;
            break;
        case "ticket-created":
            El = TicketCreatedMessage;
            onClick = () => history.push(`/${log.hubid}/${log.ticketid}/all`);
            break;
        case "ticket-deleted":
            El = TicketDeletedMessage;
            break;
        case "ticket-updated":
            El = TicketUpdatedMessage;
            onClick = () => history.push(`/${log.hubid}/${log.ticketid}/all`);
            break;
        default:
            break;
    }
    return (
        <div
            className={styles.log_item + " " + (onClick && styles.hoverable)}
            onClick={onClick}
        >
            <div className={styles.timestamp}>
                {get_time_string(log.timestamp)}
            </div>
            <El log={log} common_data={common_data} />
        </div>
    );
};

const Logs = (props) => {
    const { request } = React.useContext(CacheContext);
    const { has_premium, config } = useConfig();
    const [logs, set_logs] = React.useState(-1);
    const [meta, set_meta] = React.useState(-1);
    const [limit, set_limit] = React.useState(BASE_LIMIT);
    const [common_data, set_common_data] = React.useState(-1);

    React.useEffect(() => {
        if (!has_premium) return;
        const { has_loaded, data } = request("logs", { limit });

        if (!has_loaded) return;

        const c = {};

        const users_req = request("users");

        if (!users_req.has_loaded) return;

        c.users = users_req.data.users;

        const hubs_req = request("hubs");

        if (!hubs_req.has_loaded) return;

        c.hubs = hubs_req.data.hubs;

        c.attributes = [];

        for (const hub of c.hubs) {
            const hub_req = request("hub", hub.id);

            if (!hub_req.has_loaded) return;

            for (const group of hub_req.data.groups) {
                const group_req = request("group", group.id);

                if (!group_req.has_loaded) return;

                c.attributes.push(...group_req.data.attributes);
            }
        }

        set_logs(data.logs);
        set_meta(data.meta);
        set_common_data(c);
    }, [request, limit, has_premium]);

    if (!has_premium) {
        return (
            <div className="notPremium">
                <div>
                    <span
                        className="premium-badge"
                        style={{ marginRight: "8px" }}
                    >
                        <i className="fas fa-star"></i>
                    </span>
                    Logs are a Hubtrack Premium Feature
                </div>
                <div>
                    With logs, you can keep track of every change that occurs in
                    your hub, automatically. This allows you to see who is
                    making changes to tickets. To use logs, sign up for Hubtrack
                    Premium. We promise, you're gonna love it.
                </div>
                <a
                    href={"https://hubtrk.com/premium/?code=" + config.code}
                    className="button is-link"
                >
                    Get Hubtrack Premium
                </a>
            </div>
        );
    }

    if ([logs, meta, common_data].includes(-1))
        return (
            <div
                className="link"
                style={{ fontSize: "24px", textAlign: "center" }}
            >
                <i className="fas fa-circle-notch fa-spin"></i>
            </div>
        );

    return (
        <div>
            <div className={styles.logs}>
                {logs.length === 0 && (
                    <div
                        style={{
                            fontSize: "18px",
                            color: "var(--light-text-color)",
                            textAlign: "center",
                            padding: "30px 0px",
                        }}
                    >
                        Nothing Here Yet...
                    </div>
                )}
                {Object.entries(
                    logs.reduce((ob, log) => {
                        const d = new Date(log.timestamp).toLocaleDateString();
                        if (!ob[d]) ob[d] = [];

                        ob[d].push(log);
                        return ob;
                    }, {})
                ).map(([date, logs]) => {
                    const isToday = new Date().toLocaleDateString() === date;

                    return (
                        <div key={date} style={{ marginBottom: "30px" }}>
                            <div
                                style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    color: "var(--dark-text-color)",
                                    marginBottom: "10px",
                                }}
                            >
                                {isToday ? "Today" : date}
                            </div>
                            {logs.map((log) => (
                                <LogItem
                                    key={log.id}
                                    log={log}
                                    common_data={common_data}
                                    history={props.history}
                                />
                            ))}
                        </div>
                    );
                })}
            </div>
            {limit < meta.total && (
                <button
                    className="button"
                    onClick={() => set_limit(limit + BASE_LIMIT)}
                >
                    Load More
                </button>
            )}
        </div>
    );
};

export default Logs;
