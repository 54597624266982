import React from "react";
import styles from "./filtereditor.module.css";
import { getList } from "../../listStore";
import arrayify from "../../arrayify";
import ValueDropdown from "../valuedropdown/valuedropdown";
import DatePicker from "react-datepicker";

const filterOptions = getList("filterOptions");
const filterSequences = getList("filterSequences");
const formatFunctions = getList("formatFunctions");
const subAttributes = getList("subAttributes");

const FilterSequenceInput = (props) => {
    const [editing, set_editing] = React.useState(props.value === undefined);

    if (!editing)
        return (
            <span className={styles.value} onClick={() => set_editing(true)}>
                {props.displayValue}
            </span>
        );

    if (props.valueType === "text") {
        return (
            <input
                type="text"
                className="input"
                style={{ padding: "5px", width: "172px" }}
                defaultValue={props.value}
                onBlur={(e) => {
                    if (e.target.value !== "") set_editing(false);
                    props.onValue(e.target.value);
                }}
                autoFocus
            />
        );
    }

    if (props.valueType === "number") {
        return (
            <input
                type="number"
                className="input"
                style={{ padding: "5px", width: "172px" }}
                defaultValue={props.value}
                onBlur={(e) => {
                    if (isNaN(e.target.value)) return;
                    set_editing(false);
                    props.onValue(+e.target.value);
                }}
                autoFocus
            />
        );
    }

    if (props.valueType === "select") {
        const options = props.attribute?.options;

        return (
            <ValueDropdown
                values={options}
                value={props.value}
                forceOpen
                onSelect={(optionid) => {
                    props.onValue(optionid);
                    set_editing(false);
                }}
                backgroundColor="#f7c794"
                textColor="#444444"
            />
        );
    }

    if (props.valueType === "date") {
        return (
            <DatePicker
                selected={props.value}
                onClickOutside={() => set_editing(false)}
                onChange={(date) => {
                    props.onValue(date.getTime());
                    set_editing(false);
                }}
                className={"input " + styles.dateInput}
                autoFocus
            />
        );
    }
};

const FilterCombiner = (props) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <div
                className="danger-link"
                style={{ alignSelf: "center", paddingRight: "10px" }}
                onClick={() => props.remove(props.id)}
            >
                <i className="fas fa-times"></i>
            </div>
            <ValueDropdown
                values={(props.filters || [])
                    .sort((a, b) => a.index - b.index)
                    .filter((filter) => filter.permanent !== true)
                    .filter((filter) => filter.id !== props.filterid)}
                value={props.filter.filterid}
                onSelect={(filterid) => {
                    props.setValue(props.id, "filterid", filterid);
                }}
                backgroundColor="#e0e6e9"
                textColor="#333333"
                key={props.id + "-filter_combiner"}
            />
        </div>
    );
};

const sequenceColors = {
    attribute: {
        background: "#76e5ef",
        text: "#444444",
    },
    quantifier: {
        background: "#83FFBC",
        text: "#444444",
    },
    subAttribute: {
        background: "#8c8be4",
    },
    groupAttribute: {
        background: "#a4e48a",
        text: "#444444",
    },
};

const FilterSequence = (props) => {
    let currItem = { getNext: () => filterSequences.attribute }; //hacky, i know
    const disp = [];

    do {
        const copy = { ...currItem.getNext(props) };
        if (copy.type === "list") {
            const values = copy.getValues(props);
            disp.push(
                <ValueDropdown
                    values={arrayify(values || {})}
                    value={props.sequence[copy.key]}
                    onSelect={(val) => {
                        props.setValue(props.id, copy.key, val);
                    }}
                    key={props.id + "-" + copy.key}
                    backgroundColor={
                        sequenceColors[copy.key]?.background || null
                    }
                    textColor={sequenceColors[copy.key]?.text || null}
                />
            );
        } else if (copy.type === "input") {
            const attributeid = props.sequence.attribute;
            let attribute = props.attributes.find((a) => a.id === attributeid);

            //this is a little hacky and takes away from the concept of only manipulating listStore
            if (attribute.type === "list") {
                const subid = props.sequence.subAttribute;
                const next = subAttributes[subid].next || {};

                if (next.key === "groupAttribute") {
                    attribute = props.attributes.find(
                        (a) => a.id === props.sequence.groupAttribute
                    );
                }
            }

            disp.push(
                <FilterSequenceInput
                    valueType={copy.valueType}
                    value={props.sequence[copy.key]}
                    displayValue={formatFunctions[copy.valueType](
                        props.sequence[copy.key],
                        attribute
                    )}
                    onValue={(val) => {
                        props.setValue(props.id, copy.key, val);
                    }}
                    attribute={attribute}
                    key={props.id + "-" + copy.key}
                />
            );
        }

        if (
            (copy.canProgress && !copy.canProgress(props)) ||
            props.sequence[copy.key] === undefined
        ) {
            break;
        }

        currItem = currItem.getNext(props); //a good word for this code is gross. But it works.
    } while (currItem.getNext !== undefined);

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns:
                    "repeat(" + (disp.length + 1) + ", min-content)",
                gap: "3px",
            }}
        >
            <div
                className="danger-link"
                style={{ alignSelf: "center", paddingRight: "10px" }}
                onClick={() => props.remove(props.id)}
            >
                <i className="fas fa-times"></i>
            </div>
            {disp}
        </div>
    );
};

const FilterGroup = (props) => {
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                }}
            >
                {!props.isBase && (
                    <div
                        className="danger-link"
                        style={{
                            alignSelf: "center",
                            paddingRight: "13px",
                        }}
                        onClick={() => props.remove(props.id)}
                    >
                        <i className="fas fa-times"></i>
                    </div>
                )}
                <ValueDropdown
                    values={arrayify(filterOptions.groupType)}
                    value={props.group.groupType}
                    onSelect={(type) => {
                        props.setValue(props.id, "groupType", type);
                    }}
                    backgroundColor="#EDC2C2"
                    textColor="#444444"
                />
                <div style={{ width: "3px" }}></div>
                <ValueDropdown
                    values={arrayify(filterOptions.type)}
                    baseText="+"
                    backgroundColor="#FFFFFF"
                    textColor="#444444"
                    dontForceOpen
                    onSelect={(component) => {
                        props.addComponentToGroup(props.id, component);
                    }}
                />
            </div>
            <div
                style={{
                    paddingLeft: "30px",
                    display: "grid",
                    gap: "10px",
                }}
            >
                {props.all
                    .filter((req) => req.parent === props.id)
                    .map((req) => {
                        const generalProps = {
                            ...props,
                            id: req.id,
                        };

                        if (req.type === "condition") {
                            return (
                                <FilterSequence
                                    {...generalProps}
                                    key={req.id}
                                    sequence={req}
                                />
                            );
                        }

                        if (req.type === "group") {
                            return (
                                <FilterGroup
                                    {...generalProps}
                                    key={req.id}
                                    group={req}
                                    isBase={false}
                                />
                            );
                        }

                        if (req.type === "filter") {
                            return (
                                <FilterCombiner
                                    {...generalProps}
                                    key={req.id}
                                    filter={req}
                                />
                            );
                        }

                        return <div>Unknown Filter Type</div>;
                    })}
            </div>
        </div>
    );
};

const FilterEditor = (props) => {
    const { filterid } = props;
    const [reqs, setReqs] = React.useState(-1);
    const [attributes, set_attributes] = React.useState(-1);

    React.useEffect(() => {
        const onReqs = (r) => setReqs([...r]);

        const onGroups = (groups) => {
            const listedGroups = [];
            const allAttributes = {};

            for (const groupid in groups) {
                const group = groups[groupid];
                for (const attributeid in group.attributes || {}) {
                    const attribute = group.attributes[attributeid];
                    if (attribute.type === "list") {
                        listedGroups.push(attribute.listGroup);
                    }

                    allAttributes[attributeid] = { ...attribute };
                }
            }

            for (const attributeid in allAttributes) {
                const attribute = allAttributes[attributeid];

                if (listedGroups.includes(attribute.groupid))
                    allAttributes[attributeid].isListed = true;
            }

            set_attributes(Object.values(allAttributes));
        };

        onGroups(props.groups || {});
        onReqs(
            (props.reqs || []).map((req) => ({ ...req, type: req.req_type }))
        );
    }, [props.groups, props.reqs]);

    if (reqs === -1) return <div>Loading...</div>;

    const base_req = reqs.find((req) => req.id === props.base_requirement_id);

    if (!base_req) return <div>Loading...</div>;

    const updateReqs = (c) => {
        props.onChange([...c]);
    };

    return (
        <FilterGroup
            filterid={filterid}
            group={base_req}
            setValue={(id, key, value) => {
                const pseudo = [...reqs];
                const index = pseudo.findIndex((req) => req.id === id);

                if (index === -1) return;

                pseudo[index] = {
                    ...pseudo[index],
                    [key]: value,
                };

                updateReqs(pseudo);
            }}
            addComponentToGroup={(groupid, componentid) => {
                const pseudo = [...reqs].concat({
                    req_type: componentid,
                    parent: groupid,
                });

                updateReqs(pseudo);
            }}
            remove={(id) => {
                const pseudo = [...reqs].filter((req) => req.id !== id);

                updateReqs(pseudo);
            }}
            all={reqs}
            attributes={attributes}
            filters={props.filters}
            id={props.base_requirement_id}
            isBase={true}
        />
    );
};

export default FilterEditor;
