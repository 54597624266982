import React from "react";
import styles from "./select.module.css";

const Select = (props) => {
    const [visible, setVisible] = React.useState(props.active || false);
    const dropperHeight = React.useRef();
    const wrapRef = React.useRef();
    const dropperRef = React.useRef();

    React.useEffect(() => {
        const onClick = (e) => {
            if (e.target !== wrapRef.current) {
                setVisible(false);
            }
        };

        window.addEventListener("click", onClick);

        dropperHeight.current = dropperRef.current.offsetHeight;

        return () => {
            window.removeEventListener("click", onClick);
        };
    }, []);

    React.useEffect(() => {
        if (visible) {
            dropperRef.current.style.height = dropperHeight.current + "px";
        } else {
            dropperRef.current.style.height = "0px";
        }
    }, [visible]);

    return (
        <div
            className={styles.wrap}
            style={props.wrapStyle || {}}
            onClick={(e) => {
                setVisible(!visible);
                e.stopPropagation();
            }}
            ref={wrapRef}
        >
            <span>{(props.value || {}).name}</span>
            <i className={"fas fa-angle-down " + styles.down}></i>
            <div
                className={
                    styles.dropper + " " + (visible ? styles.active : "")
                }
                ref={dropperRef}
            >
                {props.values.map((v) => {
                    return (
                        <div
                            key={"dropper-item-" + v.value}
                            className={
                                styles.dropperItem +
                                " " +
                                (v.value === (props.value || {}).value &&
                                    styles.selected)
                            }
                            onClick={(e) => {
                                if (props.onSelect) props.onSelect(v);
                                setVisible(false);
                                e.stopPropagation();
                            }}
                        >
                            {v.name}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Select;
