const arrayify = (ob, key = "id") => {
    if (!ob) return [];
    const keys = Object.keys(ob);
    const pseudo = [];
    for (var i = 0; i < keys.length; i++) {
        pseudo.push({
            ...ob[keys[i]],
            [key]: keys[i],
        });
    }
    return pseudo;
};

export default arrayify;
