import axios from "axios";
import merge_arr_by_key from "./merge_arr_by_key";

const create_user = ({ params: { username, password, role }, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .post("/users", {
                username,
                password,
                role,
            })
            .then(({ data: new_user }) => {
                set_at_path("users", (existing_users) =>
                    merge_arr_by_key(existing_users, [new_user])
                );
                resolve();
            })
            .catch(() => {
                reject("There was an error creating the user");
            });
    });
};

const delete_user = ({ params: userid, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/users/${userid}`)
            .then(() => {
                set_at_path("users", (existing_users) =>
                    existing_users.filter((user) => user.id !== userid)
                );
                resolve();
            })
            .catch(() => {
                reject("There was an error deleting the user");
            });
    });
};

const update_user = ({ params: { userid, updates }, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .patch(`/users/${userid}`, updates)
            .then(({ data: changes }) => {
                set_at_path("users", (existing_users) =>
                    existing_users.map((user) => {
                        if (user.id !== userid) return user;

                        return {
                            ...user,
                            ...changes,
                        };
                    })
                );
                resolve();
            })
            .catch(() => {
                reject("There was an error updating the user");
            });
    });
};

const create_hub = ({ params: name, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .post("/hubs", {
                name,
            })
            .then((response) => {
                set_at_path("hubs", (existing_hubs) =>
                    existing_hubs.concat(response.data)
                );
                resolve(response.data);
            })
            .catch(() => {
                reject("There was an error creating the hub");
            });
    });
};

const delete_hub = ({ params: hubid, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/hubs/${hubid}`)
            .then(() => {
                set_at_path("hubs", (existing_hubs) =>
                    existing_hubs.filter((hub) => hub.id !== hubid)
                );
                resolve();
            })
            .catch(() => {
                reject("There was an error deleting the hub");
            });
    });
};

const update_hub = ({ params: { hubid, changes }, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .patch(`/hubs/${hubid}`, changes)
            .then(({ data: updates }) => {
                set_at_path("hubs", (existing_hubs) => {
                    return existing_hubs.map((hub) => {
                        if (hub.id !== hubid) return hub;

                        return {
                            ...hub,
                            ...updates,
                        };
                    });
                });
                resolve();
            })
            .catch(() => {
                reject("There was an error updating your hub");
            });
    });
};

const create_filter = ({ params: hubid, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .post("/filters", {
                hubid,
            })
            .then(({ data: filter }) => {
                set_at_path("filters", (existing_filters) =>
                    existing_filters.concat(filter)
                );
                resolve(filter.id);
            })
            .catch(() => {
                reject("There was an error creating your filter");
            });
    });
};
const delete_filter = ({ set_at_path, cache, params: filterid }) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/filters/${filterid}`)
            .then(() => {
                const to_set_filters = [...cache.filters]
                    .sort((a, b) => a.index - b.index)
                    .filter((filter) => filter.id !== filterid);
                const index_updates = to_set_filters.reduce(
                    (ob, filter, index) => {
                        ob[filter.id] = { index };
                        return ob;
                    },
                    {}
                );
                axios
                    .post("/filters/update_many", index_updates)
                    .then(({ data: changes }) => {
                        set_at_path(
                            "filters",
                            to_set_filters.map((filter) => {
                                return {
                                    ...filter,
                                    ...(changes[filter.id] || {}),
                                };
                            })
                        );
                    })
                    .catch(() => {
                        reject("There was an error reindexing your filters");
                    });
            })
            .catch(() => {
                reject("There was an error deleting your filter");
            });
    });
};
const update_filter = ({
    set_at_path,
    params: { filterid, changes },
    set_load_marker,
    cache,
}) => {
    return new Promise((resolve, reject) => {
        axios
            .patch(`/filters/${filterid}`, changes)
            .then(({ data: updates }) => {
                for (const hash in cache.queries) {
                    const query_params = atob(hash).split("_");

                    if (query_params[2] === filterid) {
                        set_load_marker(hash, false);
                    }
                }
                set_at_path("filters", (existing_filters) =>
                    existing_filters.map((filter) => {
                        if (filter.id !== filterid) return filter;
                        return {
                            ...filter,
                            ...updates,
                        };
                    })
                );
                resolve();
            })
            .catch(() => {
                reject("There was an error updating your filter");
            });
    });
};
const update_many_filters = ({ set_at_path, params: changes }) => {
    return new Promise((resolve, reject) => {
        axios
            .post("/filters/update_many", changes)
            .then(({ data: updates }) => {
                set_at_path("filters", (existing_filters) =>
                    existing_filters.map((filter) => {
                        return {
                            ...filter,
                            ...(updates[filter.id] || {}),
                        };
                    })
                );
                resolve();
            })
            .catch(() => {
                reject("There was an error updating your filters");
            });
    });
};

const create_attribute = ({ params: groupid, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .post("/attributes", {
                groupid,
            })
            .then(({ data: new_attribute }) => {
                set_at_path("attributes", (existing_attributes) =>
                    existing_attributes.concat(new_attribute)
                );
                resolve(new_attribute);
            })
            .catch(() => {
                reject("There was an error creating your attribute");
            });
    });
};

const delete_attribute = ({ params: attributeid, cache, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/attributes/${attributeid}`)
            .then(() => {
                const to_set_attributes = [...cache.attributes]
                    .filter((attribute) => attribute.id !== attributeid)
                    .sort((a, b) => a.index - b.index);
                axios
                    .post(
                        "/attributes/update_many",
                        to_set_attributes.reduce((ob, attribute, index) => {
                            ob[attribute.id] = { index };
                            return ob;
                        }, {})
                    )
                    .then(({ data: changes }) => {
                        set_at_path(
                            "attributes",
                            to_set_attributes.map((attribute) => {
                                return {
                                    ...attribute,
                                    ...(changes[attribute.id] || {}),
                                };
                            })
                        );
                        resolve();
                    })
                    .catch(() => {
                        reject("There was an error reindexing your attributes");
                    });
            })
            .catch(() => {
                reject("There was an error deleting your attribute");
            });
    });
};

const update_attribute = ({
    params: { attributeid, changes },
    set_at_path,
}) => {
    return new Promise((resolve, reject) => {
        axios
            .patch(`/attributes/${attributeid}`, changes)
            .then(({ data: updates }) => {
                set_at_path("attributes", (existing_attributes) =>
                    existing_attributes.map((attribute) => {
                        if (attribute.id !== attributeid) return attribute;
                        return {
                            ...attribute,
                            ...updates,
                        };
                    })
                );
            })
            .catch(() => {
                reject("There was an error updating your attribute");
            });
    });
};

const update_many_attributes = ({ params: changes, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/attributes/update_many`, changes)
            .then(({ data: updates }) => {
                set_at_path("attributes", (existing_attributes) =>
                    existing_attributes.map((attribute) => {
                        return {
                            ...attribute,
                            ...(updates[attribute.id] || {}),
                        };
                    })
                );
            })
            .catch(() => {
                reject("There was an error updating your attributes");
            });
    });
};

const create_group = ({ params: { name, hubid }, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .post("/groups", {
                name,
                hubid,
            })
            .then(({ data: new_group }) => {
                set_at_path("groups", (existing_groups) =>
                    existing_groups.concat(new_group)
                );
                resolve(new_group.id);
            })
            .catch(() => {
                reject("There was an error creating your group");
            });
    });
};

const delete_group = ({ params: groupid, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/groups/${groupid}`)
            .then(() => {
                set_at_path("groups", (existing_groups) =>
                    existing_groups.filter((group) => group.id !== groupid)
                );
                resolve();
            })
            .catch(() => {
                reject("There was an error deleting your group");
            });
    });
};

const update_group = ({ params: { groupid, changes }, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .patch(`/groups/${groupid}`, changes)
            .then(({ data: updates }) => {
                set_at_path("groups", (existing_groups) =>
                    existing_groups.map((group) => {
                        if (group.id !== groupid) return group;

                        return {
                            ...group,
                            ...updates,
                        };
                    })
                );
                resolve();
            })
            .catch(() => {
                reject("There was an error updating your group");
            });
    });
};

const create_status = ({ params: hubid, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .post("/statuses", { hubid })
            .then(({ data: new_status }) => {
                set_at_path("statuses", (existing_statuses) =>
                    existing_statuses.concat(new_status)
                );
                resolve(new_status.id);
            })
            .catch(() => {
                reject("There was an error creating your status");
            });
    });
};

const delete_status = ({ params: statusid, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/statuses/${statusid}`)
            .then(() => {
                set_at_path("statuses", (existing_statuses) =>
                    existing_statuses.filter((status) => status.id !== statusid)
                );
                resolve();
            })
            .catch(() => {
                reject("There was an error deleting your status");
            });
    });
};

const update_status = ({ params: { statusid, changes }, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .patch(`/statuses/${statusid}`, changes)
            .then(({ data: updates }) => {
                set_at_path("statuses", (existing_statuses) =>
                    existing_statuses.map((status) => {
                        if (status.id !== statusid) return status;

                        return {
                            ...status,
                            ...updates,
                        };
                    })
                );
                resolve();
            })
            .catch(() => {
                reject("There was an error updating your status");
            });
    });
};

const create_webhook = ({ set_at_path, params: hubid }) => {
    return new Promise((resolve, reject) => {
        axios
            .post("/webhooks", {
                hubid,
            })
            .then(({ data: webhook }) => {
                set_at_path("webhooks", (existing_webhooks) =>
                    existing_webhooks.concat(webhook)
                );
                resolve(webhook.id);
            })
            .catch(() => reject("There was an error creating your webhook"));
    });
};

const delete_webhook = ({ params: webhookid, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/webhooks/${webhookid}`)
            .then(() => {
                set_at_path("webhooks", (existing_webhooks) =>
                    existing_webhooks.filter(
                        (webhook) => webhook.id !== webhookid
                    )
                );
                resolve();
            })
            .catch(() => reject("There was an error deleting your webhook"));
    });
};

const update_webhook = ({ params: { webhookid, changes }, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .patch(`/webhooks/${webhookid}`, changes)
            .then(({ data: updates }) => {
                set_at_path("webhooks", (existing_webhooks) =>
                    existing_webhooks.map((webhook) => {
                        if (webhook.id !== webhookid) return webhook;

                        return {
                            ...webhook,
                            ...updates,
                        };
                    })
                );
                resolve();
            })
            .catch(() => reject("There was an error updating your webhook"));
    });
};

const create_ticket = ({ params: ticket, cache, set_values_at_paths }) => {
    return new Promise((resolve, reject) => {
        axios
            .post("/tickets", ticket)
            .then(async ({ data: new_ticket }) => {
                const { queries } = cache;

                const to_add = [];

                for (const request_hash in queries) {
                    const { data: valid } = await axios.post(
                        "/tickets/verify",
                        {
                            ticketid: new_ticket.id,
                            request_hash,
                        }
                    );

                    if (valid) to_add.push(request_hash);
                }

                const { data: members } = await axios.post(
                    `/statuses/get_statuses_for_ticket`,
                    {
                        ticket: new_ticket,
                    }
                );

                new_ticket.members = members;

                set_values_at_paths([
                    {
                        path: "tickets",
                        value: (existing_tickets) =>
                            (existing_tickets || []).concat(new_ticket),
                    },
                    {
                        path: "queries",
                        value: (existing_queries) => {
                            const pseudo = { ...existing_queries };

                            for (const h in pseudo) {
                                if (to_add.includes(h)) {
                                    pseudo[h].ticketids.push(new_ticket.id);
                                    pseudo[h].meta.total += 1;
                                }
                            }

                            return pseudo;
                        },
                    },
                ]);

                resolve(new_ticket);
            })
            .catch(() => reject("There was an error creating your ticket"));
    });
};

const delete_ticket = ({ params: ticketid, set_values_at_paths }) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/tickets/${ticketid}`)
            .then(() => {
                set_values_at_paths([
                    {
                        path: "tickets",
                        value: (existing_tickets) =>
                            existing_tickets.filter(
                                (ticket) => ticket.id !== ticketid
                            ),
                    },
                    {
                        path: "queries",
                        value: (existing_queries) => {
                            const pseudo = { ...existing_queries };

                            for (const h in pseudo) {
                                if (pseudo[h].ticketids.includes(ticketid))
                                    pseudo[h].meta.total -= 1;
                            }

                            return pseudo;
                        },
                    },
                ]);
                resolve();
            })
            .catch(() => reject("There was an error deleting your ticket"));
    });
};

const delete_many_tickets = ({ params: hubid, set_at_path }) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/tickets/many/${hubid}`)
            .then(() => {
                set_at_path("tickets", (existing_tickets) =>
                    existing_tickets?.filter((ticket) => ticket.hubid !== hubid)
                );
            })
            .catch(() =>
                reject("There was an error deleting all your tickets")
            );
    });
};

const update_ticket = ({
    params: { ticketid, changes },
    cache,
    set_values_at_paths,
}) => {
    return new Promise((resolve, reject) => {
        axios
            .patch(`/tickets/${ticketid}`, changes)
            .then(async ({ data: updates }) => {
                const { queries } = cache;

                axios
                    .post("/tickets/verify_many", {
                        ticketid,
                        hashes: Object.keys(queries),
                    })
                    .then(async ({ data: query_statuses }) => {
                        const original_ticket =
                            cache.tickets?.find((t) => t.id === ticketid) || {};

                        const { data: members } = await axios.post(
                            "/statuses/get_statuses_for_ticket",
                            {
                                ticket: { ...original_ticket, ...updates },
                            }
                        );

                        const { data: logs } = await axios.get(
                            `/logs/ticket/${ticketid}`
                        );

                        updates.members = members;

                        set_values_at_paths([
                            {
                                path: "tickets",
                                value: (existing_tickets) =>
                                    existing_tickets.map((ticket) => {
                                        if (ticket.id !== ticketid)
                                            return ticket;

                                        return {
                                            ...ticket,
                                            ...updates,
                                        };
                                    }),
                            },
                            {
                                path: "queries",
                                value: (existing_queries) => {
                                    const pseudo = { ...existing_queries };

                                    for (const h in pseudo) {
                                        const query_params = atob(h).split("_");

                                        if (
                                            query_params[0] !==
                                            original_ticket.hubid
                                        )
                                            continue;

                                        if (query_statuses[h]) {
                                            if (
                                                !pseudo[h].ticketids.includes(
                                                    ticketid
                                                )
                                            ) {
                                                pseudo[h].meta.total += 1;
                                            }

                                            pseudo[h].ticketids = pseudo[
                                                h
                                            ].ticketids.concat(ticketid);
                                        } else {
                                            if (
                                                pseudo[h].ticketids.includes(
                                                    ticketid
                                                )
                                            ) {
                                                pseudo[h].meta.total -= 1;
                                            }

                                            pseudo[h].ticketids = pseudo[
                                                h
                                            ].ticketids.filter(
                                                (t) => t !== ticketid
                                            );
                                        }
                                    }

                                    return pseudo;
                                },
                            },
                            {
                                path: `ticket_logs.${ticketid}`,
                                value: (existing_logs) =>
                                    merge_arr_by_key(existing_logs, logs),
                            },
                        ]);

                        resolve();
                    })
                    .catch(() =>
                        reject("There was an error verifying your ticket")
                    );
            })
            .catch(() => reject("There was an error updating your ticket"));
    });
};

export default {
    create_user,
    delete_user,
    update_user,
    create_hub,
    delete_hub,
    update_hub,
    create_filter,
    delete_filter,
    update_filter,
    update_many_filters,
    create_group,
    delete_group,
    update_group,
    create_attribute,
    delete_attribute,
    update_attribute,
    update_many_attributes,
    create_status,
    delete_status,
    update_status,
    create_webhook,
    delete_webhook,
    update_webhook,
    create_ticket,
    delete_ticket,
    delete_many_tickets,
    update_ticket,
};
