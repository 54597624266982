import React from "react";
import styles from "./filtersettings.module.css";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import FilterEditor from "../filtereditor/filtereditor";
import Dropdown from "../dropdown/dropdown";
import { useAlert } from "react-alert";
import CacheContext from "../../contexts/CacheContext";
import objectify from "../../objectify";
import useConfig from "../../hooks/useConfig";

const FilterList = sortableContainer((props) => {
    return <div className={styles.filterList}>{props.children}</div>;
});

const FilterListItem = sortableElement((props) => {
    return (
        <div
            className={
                styles.filterItem +
                " " +
                (props.active && styles.active) +
                " " +
                (props.filter.permanent === true && styles.disabled)
            }
            onClick={() => {
                if (
                    !props.filter.permanent ||
                    props.filter.permanent === false
                ) {
                    props.onClick();
                }
            }}
        >
            {props.filter.name || (
                <span
                    style={{
                        color: `var(--${
                            props.active ? "logo" : "light"
                        }-text-color)`,
                        fontWeight: "400",
                    }}
                >
                    No Name
                </span>
            )}
        </div>
    );
});

const SettingsPane = (props) => {
    const alert = useAlert();

    const filter_name_ref = React.useRef();

    const { reqs, name } = props.filter;

    const onEditorChange = (reqs) => {
        props.on_update({ reqs });
    };

    React.useEffect(() => {
        if (!filter_name_ref.current) return;

        filter_name_ref.current.value = props.filter.name;
    }, [props.filter]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <div className="title">Edit Filter</div>
                <Dropdown direction="left">
                    <Dropdown.Item
                        onClick={() => {
                            alert.show(
                                `Are you sure you would like to delete "${name}"?`,
                                {
                                    timeout: 0,
                                    type: "confirm",
                                    title: `Delete ${name}`,
                                    actions: [
                                        {
                                            text: "Delete",
                                            color: "var(--danger-text-color)",
                                            onClick: () => {
                                                props.on_delete();
                                            },
                                        },
                                    ],
                                }
                            );
                        }}
                    >
                        <div className="danger-link">
                            {" "}
                            <i className="fas fa-trash-alt"></i> Delete Filter
                        </div>
                    </Dropdown.Item>
                </Dropdown>
            </div>
            <div className="input-label">Filter Name</div>
            <input
                type="text"
                placeholder="Give This Filter A Name: "
                className="input"
                defaultValue={name}
                ref={filter_name_ref}
                onBlur={(e) => {
                    props.on_update({ name: e.target.value });
                }}
            />
            <div className="input-label">Filter</div>
            <FilterEditor
                onChange={onEditorChange}
                reqs={reqs}
                groups={props.groups}
                hubid={props.hubid}
                filters={props.filters}
                filterid={props.filter.id}
                base_requirement_id={props.filter.base_requirement}
            />
        </React.Fragment>
    );
};

const FilterSettings = (props) => {
    const { hubid } = props.match.params;
    const { has_premium } = useConfig();

    const { request, execute } = React.useContext(CacheContext);

    const [activeFilter, setActiveFilter] = React.useState(-1);
    const [hub, set_hub] = React.useState(-1);
    const [attributes, set_attributes] = React.useState(-1);

    const { filters, groups } = hub;

    React.useEffect(() => {
        const { has_loaded, data } = request("hub", hubid);

        if (!has_loaded) return;

        set_hub(data);
    }, [request, hubid]);

    React.useEffect(() => {
        if (!groups) return;
        const p = [];

        for (const group of groups) {
            const { has_loaded, data } = request("group", group.id);

            if (!has_loaded) return;

            p.push(...data.attributes);
        }

        set_attributes(p);
    }, [groups, request]);

    React.useEffect(() => {
        if (!filters) return;

        if (
            (activeFilter === -1 ||
                !filters.find((filt) => filt.id === activeFilter)) &&
            filters !== -1
        ) {
            const firstValid = filters.find((filter) => !filter.permanent);
            if (firstValid) {
                setActiveFilter(firstValid.id);
            } else {
                setActiveFilter(-1);
            }
        }
    }, [activeFilter, filters]);

    if (hub === -1 || attributes === -1) return <div>Loading...</div>;

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const reSorted = arrayMove([...filters], oldIndex, newIndex);

        execute(
            "update_many_filters",
            reSorted.reduce((ob, filter, index) => {
                ob[filter.id] = { index };
                return ob;
            }, {})
        );
    };

    return (
        <div className={styles.wrap}>
            <div className={styles.filters}>
                <FilterList onSortEnd={onSortEnd} distance={1}>
                    {filters
                        .sort((a, b) => a.index - b.index)
                        .map((filter) => {
                            return (
                                <FilterListItem
                                    key={filter.id}
                                    index={filter.index}
                                    filter={filter}
                                    active={activeFilter === filter.id}
                                    onClick={() => setActiveFilter(filter.id)}
                                />
                            );
                        })}
                </FilterList>
                <div
                    className={
                        styles.addFilter +
                        " " +
                        (!has_premium && filters.length >= 3 && "disabled")
                    }
                    onClick={() => {
                        execute("create_filter", hubid).then(setActiveFilter);
                    }}
                >
                    New Filter
                </div>
            </div>

            <div className={styles.filterSettings}>
                {activeFilter !== -1 && (
                    <SettingsPane
                        filter={
                            filters.find((f) => f.id === activeFilter) || {}
                        }
                        filters={filters}
                        setActiveFilter={setActiveFilter}
                        hubid={hubid}
                        groups={objectify(
                            groups.map((group) => ({
                                ...group,
                                attributes: objectify(
                                    attributes.filter(
                                        (att) => att.groupid === group.id
                                    )
                                ),
                            }))
                        )}
                        on_delete={() => {
                            execute("delete_filter", activeFilter);
                        }}
                        on_update={(changes) => {
                            execute("update_filter", {
                                filterid: activeFilter,
                                changes,
                            });
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default FilterSettings;
