import React from "react";
import styles from "./number_group_list.module.css";

const NumberGroupList = (props) => {
    const [editing, setEditing] = React.useState(false);

    if (editing) {
        return (
            <input
                type="number"
                className={"input " + (editing ? styles.editing : "no-box")}
                value={props.value || (+props.value === 0 && "0") || ""}
                style={{ color: "var(--dark-text-color)" }}
                onChange={(e) => {
                    props.onChange(+e.target.value || "");
                }}
                onFocus={(e) => e.currentTarget.select()}
                autoFocus
                onBlur={() => {
                    setEditing(false);
                    if (props.onBlur) props.onBlur();
                }}
            />
        );
    } else {
        return (
            <div className={styles.value} onClick={() => setEditing(true)}>
                {props.value || (+props.value === 0 && <span>0</span>) || (
                    <span className="is-light">Nothing Here...</span>
                )}
            </div>
        );
    }
};

export default NumberGroupList;
