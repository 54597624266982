const axios = require("axios"); // Only worked when I did it this way, don't judge me
let io;

const sockets_connect = (host) => {
    io = require("socket.io-client")(host);

    io.on("connect", () => {
        axios.defaults.headers.common["socketid"] = io.id;
    });
};

const join_room_using_code = (code) => {
    io.emit("room", code);
};

const sockets_subscribe = (event_keys, cb, ignore_if_sender) => {
    const on_event_key = (data, sender_id) => {
        const is_sender = sender_id === io.id;

        if (!is_sender || !ignore_if_sender) cb(data);
    };

    for (const event_key of event_keys) {
        io.on(event_key, on_event_key);
    }

    return () => {
        for (const event_key of event_keys) {
            io.off(event_key, on_event_key);
        }
    };
};

export { join_room_using_code };
export { sockets_connect };
export { sockets_subscribe };
export default {};
