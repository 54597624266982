import React from "react";
import styles from "./select_group_list.module.css";

const SelectGroupList = (props) => {
    const [editing, setEditing] = React.useState(false);

    React.useEffect(() => {
        const onClick = (e) => {
            setEditing(false);
        };

        if (editing) {
            window.addEventListener("click", onClick);
        }

        return () => {
            window.removeEventListener("click", onClick);
        };
    }, [editing]);

    const { addGroup, value: v, attribute, onChange } = props;
    React.useEffect(() => {
        const op = attribute.options.find((op) => op.id === v);
        if (v !== undefined && op === undefined) {
            //somebody deleted that option
            return onChange(undefined);
        }

        if (v) {
            addGroup(op.add, attribute.id);
        }
    }, [addGroup, attribute.id, attribute.options, v, onChange]);

    if (editing) {
        return (
            <div className={styles.options}>
                {props.attribute.options.map((option) => (
                    <div
                        key={option.id}
                        className={
                            styles.option +
                            " " +
                            (option.id === props.value && styles.selected)
                        }
                        onClick={() => {
                            props.onChange(option.id);
                        }}
                    >
                        {option.name}
                    </div>
                ))}
            </div>
        );
    } else {
        return (
            <div className={styles.value} onClick={() => setEditing(true)}>
                {(
                    props.attribute.options.find(
                        (op) => op.id === props.value
                    ) || {}
                ).name || <span className="is-light">Nothing Here...</span>}
            </div>
        );
    }
};

export default SelectGroupList;
