import React from "react";
import styles from "./pvsettings.module.css";
import Dropdown from "../dropdown/dropdown";
import CacheContext from "../../contexts/CacheContext";

const findFirstValidIndex = (content) => {
    const index = content.indexOf("{{");

    if (index === -1) return -1;

    const nextChar = content.charAt(index + 2);

    if (nextChar !== "" && nextChar !== " ") {
        let possibleIndice = findFirstValidIndex(content.substring(index + 2));
        if (possibleIndice === -1) return -1;

        return index + 2 + possibleIndice;
    } else {
        return index + 2;
    }
};

const PreviewLine = (props) => {
    const [editing, setEditing] = React.useState(false);
    const [caretDestination, setCaretDestination] = React.useState(-1);
    const saved = React.useRef(true);
    const inputRef = React.useRef();

    const tokens = props.available
        .concat({
            id: "dateCreated",
            name: "Date Created",
        })
        .filter((att) => {
            return att.type !== "list";
        });

    React.useEffect(() => {
        if (caretDestination === -1) return;

        inputRef.current.focus();
        inputRef.current.setSelectionRange(caretDestination, caretDestination);

        setCaretDestination(-1);
    }, [caretDestination]);

    const { savePreviews } = props;
    React.useEffect(() => {
        const onClick = () => setEditing(false);

        if (editing) {
            window.addEventListener("click", onClick);
        } else if (saved.current !== true) {
            savePreviews();
            saved.current = true;
        }

        return () => {
            window.removeEventListener("click", onClick);
        };
    }, [editing, savePreviews]);

    const withMenu = () => {
        if (!props.preview) {
            return (
                <span style={{ color: "var(--light-text-color)" }}>
                    Hubtrack
                </span>
            );
        }

        const firstIndex = findFirstValidIndex(props.preview);

        if (firstIndex === -1) {
            return props.preview;
        } else {
            return [
                props.preview.substring(0, firstIndex),
                <span
                    style={{ position: "absolute", top: "100%" }}
                    key="dropdown-span"
                >
                    <Dropdown hideToggler forceOpen>
                        {tokens.map((att) => {
                            return (
                                <Dropdown.Item
                                    key={att.id}
                                    onClick={() => {
                                        let pseudo = props.preview.concat("");

                                        const first = pseudo.substring(
                                            0,
                                            firstIndex
                                        );
                                        const last = pseudo.substring(
                                            firstIndex,
                                            pseudo.length
                                        );

                                        props.setPreview(
                                            first + att.id + "}}" + last
                                        );

                                        setCaretDestination(
                                            (first + att.id + "}}").length
                                        );
                                    }}
                                >
                                    {att.name}
                                </Dropdown.Item>
                            );
                        })}
                    </Dropdown>
                </span>,
                props.preview.substring(firstIndex + 2, props.preview.length),
            ];
        }
    };

    const taggify = (prev) => {
        if (!prev) return "";

        const reggie = new RegExp("{{2}(.*?)}{2}", "g");
        const matches = (prev.match(reggie) || []).filter((match) => {
            const id = match.substring(2, match.length - 2);
            return tokens.find((token) => token.id === id);
        });

        let original = prev;
        let toReturn = [];
        let lastIndex = 0;
        for (const match of matches) {
            const id = match.substring(2, match.length - 2);
            const name = tokens.find((token) => token.id === id).name;
            const index = original.indexOf(match);

            toReturn.push(original.substring(lastIndex, index));
            toReturn.push(
                <span
                    key={"attribute-padder-" + prev.indexOf(match)}
                    style={{
                        color: "var(--link-text-color)",
                        fontWeight: "700",
                    }}
                >
                    {name}
                </span>
            );
            lastIndex = index;
            original = original.substring(("{{" + id + "}}").length);
        }

        toReturn.push(original.substring(lastIndex, original.length));

        return toReturn;
    };

    return (
        <div
            className={styles.wrap + " " + (editing && styles.editing)}
            onClick={(e) => {
                e.stopPropagation();
                setEditing(true);
            }}
        >
            <div className={styles.top}>{props.group.name}</div>
            <div className={styles.bottom}>
                {editing ? (
                    <div className={styles.inputsWrap}>
                        <input
                            type="text"
                            value={props.preview || ""}
                            onChange={(e) => {
                                props.setPreview(e.currentTarget.value);
                                saved.current = false;
                            }}
                            className={"input " + styles.main}
                            ref={inputRef}
                            autoFocus
                        />
                        <div className="input">
                            <pre>{withMenu()}</pre>
                        </div>
                    </div>
                ) : (
                    taggify(props.preview) || (
                        <span style={{ color: "var(--light-text-color)" }}>
                            Nothing Here Yet...
                        </span>
                    )
                )}
            </div>
        </div>
    );
};

const PreviewsSettings = (props) => {
    const { hubid } = props.match.params;
    const { request, execute } = React.useContext(CacheContext);
    const [attributes, set_attributes] = React.useState([]);
    const [hub, set_hub] = React.useState(-1);

    const [pseudo, set_pseudo] = React.useState(-1);

    const { groups, title_preview, base_group } = hub;

    React.useEffect(() => {
        const { has_loaded, data } = request("hub", hubid);

        if (!has_loaded) return;

        set_hub(data);
    }, [request, hubid]);

    React.useEffect(() => {
        if (!groups) return;

        const pseudo = [];
        for (const group of groups) {
            const { has_loaded, data } = request("group", group.id);

            if (!has_loaded) return;

            pseudo.push(...data.attributes);
        }

        set_attributes(pseudo);
    }, [groups, request]);

    React.useEffect(() => {
        if (!groups) return;

        set_pseudo((old_pseudo) => {
            const group_previews = groups.reduce((ob, group) => {
                ob[group.id] = group.preview;
                return ob;
            }, {});

            return {
                ...old_pseudo,
                title: title_preview,
                ...group_previews,
            };
        });
    }, [groups, title_preview]);

    const savePreviews = async () => {
        // save title and each group preview separately, since they're stored separately
        const clone = { ...pseudo };

        const new_title = clone.title;
        if (new_title !== title_preview) {
            await execute("update_hub", {
                hubid,
                changes: { title_preview: new_title },
            });
        }

        delete clone.title;

        for (const groupid in clone) {
            const group = groups.find((group) => group.id === groupid);

            if (group.preview === clone[groupid]) continue;

            await execute("update_group", {
                groupid,
                changes: {
                    preview: clone[groupid],
                },
            });
        }
    };

    if (hub === -1) return <div>Loading...</div>;

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <PreviewLine
                hubid={hubid}
                group={{ name: "Title", id: "title" }}
                preview={pseudo.title || ""}
                setPreview={(pv) => {
                    set_pseudo({
                        ...pseudo,
                        title: pv,
                    });
                }}
                savePreviews={savePreviews}
                available={attributes.filter(
                    (attribute) => attribute.groupid === base_group
                )}
            />
            {groups.map((group) => {
                return (
                    <PreviewLine
                        hubid={hubid}
                        key={"pvline-" + group.id}
                        group={group}
                        available={attributes.filter(
                            (attribute) => attribute.groupid === group.id
                        )}
                        preview={pseudo[group.id]}
                        savePreviews={savePreviews}
                        setPreview={
                            (pv) => {
                                set_pseudo({
                                    ...pseudo,
                                    [group.id]: pv,
                                });
                            }
                            // setPreviews({
                            //     ...previews,
                            //     [group.id]: pv,
                            // })
                        }
                    />
                );
            })}
            <div style={{ marginTop: "25px", opacity: "0.75" }}>
                For help editing previews, checkout our guide{" "}
                <a href="https://learn.hubtrk.com/previews/editing-previews">
                    here.
                </a>
            </div>
        </div>
    );
};

export default PreviewsSettings;
