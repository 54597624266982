import React from "react";
import styles from "./breadcrumbs.module.css";
import { Link } from "react-router-dom";

const Breadcrumbs = (props) => {
    const assembleCrumbs = () => {
        const crumbs = [];

        for (var i = 0; i < props.crumbs.length; i++) {
            const crumb = props.crumbs[i];
            if (crumb.link) {
                crumbs.push(
                    <Link
                        key={crumb.link + "" + i}
                        to={crumb.link}
                        className={styles.crumb}
                    >
                        {crumb.name}
                    </Link>
                );
            } else {
                crumbs.push(
                    <span
                        key={(crumb.name || Math.random()) + " " + i}
                        className={styles.crumb}
                    >
                        {crumb.name}
                    </span>
                );
            }
            crumbs.push(
                <span
                    key={"crumb-dash-" + i}
                    className={styles.crumb}
                    style={{ fontWeight: "bold" }}
                >
                    /
                </span>
            );
        }
        crumbs.pop();

        return crumbs;
    };

    if (!props.crumbs || props.crumbs.length === 0) return null;

    return <div className={styles.wrap}>{assembleCrumbs()}</div>;
};

export default Breadcrumbs;
