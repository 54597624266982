import React from "react";
import set_value from "set-value";
import get_value from "get-value";
import { sockets_subscribe } from "../sockets";
import load_schema from "../utils/load_schema";
import executable_schema from "../utils/executable_schema";
import { useAlert } from "react-alert";

const CacheContext = React.createContext();

const CacheContextProvider = (props) => {
    const alert = useAlert();

    const [cache, set_cache] = React.useState({});
    const [listeners, set_listeners] = React.useState({});
    const [load_markers, set_load_markers] = React.useState({});

    // React.useEffect(() => {
    //     console.log(cache);
    // }, [cache]);

    // React.useEffect(() => {
    //     console.log(load_markers);
    // }, [load_markers]);

    const get_at_path = React.useCallback(
        (path) => {
            return get_value({ ...cache }, path);
        },
        [cache]
    );

    const set_at_path = React.useCallback((path, value) => {
        set_cache((old_cache) => {
            const clone = { ...old_cache };

            if (typeof value === "function") {
                set_value(clone, path, value(get_value(clone, path)));
            } else {
                set_value(clone, path, value);
            }

            return clone;
        });
    }, []);

    const set_values_at_paths = (updates) => {
        set_cache((old_cache) => {
            const clone = { ...old_cache };

            for (const update of updates) {
                if (typeof update.value === "function") {
                    set_value(
                        clone,
                        update.path,
                        update.value(get_value(clone, update.path))
                    );
                } else {
                    set_value(clone, update.path, update.value);
                }
            }

            return clone;
        });
    };

    const set_load_marker = React.useCallback(
        (key, value = true) => {
            const clone = { ...load_markers };
            set_value(clone, key, value);
            set_load_markers(clone);
        },
        [load_markers]
    );

    const on_listener_error = (error, listener_key) => {
        console.error("There was an error in listener " + listener_key);
        console.error(error);
    };

    const add_listener = (event_keys, cb, ignore_if_sender, listener_key) => {
        const pseudo = { ...listeners };
        if (pseudo[listener_key]) return;

        pseudo[listener_key] = {
            event_keys,
            cb,
            ignore_if_sender,
        };

        set_listeners(pseudo);
    };

    const request = (key, params, cb) => {
        let schema = load_schema[key];

        if (!schema) return {};

        if (typeof schema === "function") schema = schema(params);

        const { extract, load_data, has_loaded } = schema;
        let { listeners: schema_listeners } = schema;

        for (const listener_key in schema_listeners || {}) {
            const { event_keys, cb, ignore_if_sender } = schema_listeners[
                listener_key
            ];
            add_listener(
                event_keys,
                cb,
                ignore_if_sender ?? true,
                listener_key
            );
        }

        load_data({
            cache,
            set_at_path,
            set_values_at_paths,
            params,
            load_markers,
            set_load_marker,
        }).catch((err) => {
            if (typeof err === "object") {
                alert.error(err.message);
            } else {
                alert.error(err);
            }
        });

        return {
            has_loaded: has_loaded(load_markers),
            data: extract(cache),
        };

        // new Promise((resolve, reject) => {
        //     // Sometimes this makes a request, sometimes it doesn't. Depends
        //     // on the params and such, and if the load marker is there for it
        //
        //         .then(() => {
        //             resolve(extract(cache));
        //         })
        //         .catch((err) => {
        //             if (typeof err === "object") {
        //                 alert.error(err.message);
        //             } else {
        //                 alert.error(err);
        //             }

        //             reject(err);
        //         });
        // });

        // return ret;

        // const ret = {};

        // ret.get = () => {
        //     return new Promise((resolve, reject) => {
        //         ret.cancel = () => {
        //             console.log("cancelling");
        //             reject();
        //         };

        //         const schema = load_schema[key];

        //         if (!schema) return {};

        //         const {
        //             listeners: schema_listeners,
        //             extract,
        //             load_data,
        //         } = schema;

        //         for (const listener_key in schema_listeners) {
        //             const { event_keys, cb } = schema_listeners[listener_key];
        //             add_listener(event_keys, cb, listener_key);
        //         }

        //         // Sometimes this makes a request, sometimes it doesn't. Depends
        //         // on the params and such, and if the load marker is there for it
        //         load_data({
        //             cache,
        //             set_at_path,
        //             set_values_at_paths,
        //             params,
        //             load_markers,
        //             set_load_marker,
        //         })
        //             .then(() => {
        //                 resolve(extract(cache));
        //             })
        //             .catch((err) => {
        //                 if (typeof err === "object") {
        //                     alert.error(err.message);
        //                 } else {
        //                     alert.error(err);
        //                 }

        //                 reject(err);
        //             });
        //     });
        // };

        // return ret;
    };

    const execute = (key, params) => {
        return new Promise((resolve, reject) => {
            const executable = executable_schema[key];

            if (!executable) return reject();

            Promise.resolve(
                executable({
                    params,
                    cache,
                    set_at_path,
                    set_values_at_paths,
                    set_load_marker,
                })
            )
                .then(resolve)
                .catch((err) => {
                    if (err) alert.error(err);

                    reject(err);
                });
        });
    };

    React.useEffect(() => {
        let unsubscription_functions = [];
        for (const listener_key in listeners) {
            const { event_keys, cb, ignore_if_sender } = listeners[
                listener_key
            ];
            const unsubscribe = sockets_subscribe(
                event_keys,
                (data) => {
                    // the order of these is fucked, but not time to change it now
                    cb(
                        data,
                        set_at_path,
                        cache,
                        (error) => on_listener_error(error, listener_key),
                        { set_values_at_paths, set_load_marker }
                    );
                },
                ignore_if_sender
            );

            unsubscription_functions = [
                ...unsubscription_functions,
                unsubscribe,
            ];
        }

        return () => {
            unsubscription_functions.forEach((unsubscribe) => unsubscribe());
        };
    }, [listeners, cache, set_at_path, set_load_marker]);

    return (
        <CacheContext.Provider
            value={{
                cache,
                set_at_path,
                add_listener,
                set_values_at_paths,
                set_load_marker,
                load_markers,
                get_at_path,
                request,
                execute,
            }}
        >
            {props.children}
        </CacheContext.Provider>
    );
};

export default CacheContext;
export { CacheContextProvider };
