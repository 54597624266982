import React from "react";
import styles from "./selectitem.module.css";
import AttributeInput from "../attributeInput";
import CacheContext from "../../../contexts/CacheContext";

const SelectItem = (props) => {
    const { request } = React.useContext(CacheContext);
    const [addedItems, setAddedItems] = React.useState([]);
    const [to_add_id, set_to_add_id] = React.useState(-1);

    React.useEffect(() => {
        if (to_add_id === -1) return;

        const { has_loaded, data } = request("group", to_add_id);

        if (!has_loaded) return;

        setAddedItems(data.attributes);
        set_to_add_id(-1);
    }, [to_add_id, request]);

    const addItems = async (addID) => {
        props.setAddedGroup(props.attribute.id, addID);

        if (addID === "") return setAddedItems([]);

        set_to_add_id(addID);

        // alert("Refactor to allow added items");
        // let toAdd = await Server.source("hubs")
        //     .source(props.hubid)
        //     .source("groups")
        //     .source(addID)
        //     .source("attributes")
        //     .get();

        // setAddedItems(arrayify(toAdd));
    };

    if (!props.attribute.options) return <div>No options to select...</div>;

    return [
        <div className={styles.options} key={props.attribute.id}>
            {props.attribute.options.map((option) => {
                return (
                    <div
                        key={option.id}
                        className={
                            styles.option +
                            " " +
                            (props.val === option.id && styles.selected)
                        }
                        onClick={() => {
                            props.setVal(option.id);
                            addItems(option.add);
                            // props.addAttributes(option.add || "");
                        }}
                    >
                        {option.name}
                    </div>
                );
            })}
        </div>,
        ...addedItems.map((att) => {
            return (
                <AttributeInput
                    {...props}
                    key={att.id}
                    attribute={att}
                    val={props.ticket[att.id]}
                    hubid={props.hubid}
                    setTicketValue={props.setTicketValue}
                    setAddedGroup={props.setAddedGroup}
                    setVal={(val) => {
                        props.setTicketValue(att.id, val);
                        // props.setVal({
                        //     ...props.ticket,
                        //     [att.id]: val,
                        // });
                    }}
                />
            );
        }),
    ];
};

export default SelectItem;
