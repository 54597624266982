import React from "react";

const NumberItem = (props) => {
    const fieldRef = React.useRef();

    React.useEffect(() => {
        if (props.shouldFocus && fieldRef.current) {
            fieldRef.current.focus();
        }
    }, [props.shouldFocus]);

    return (
        <input
            ref={fieldRef}
            type="text"
            className="input"
            value={props.val === undefined ? "" : props.val}
            placeholder={"Enter " + props.attribute.name}
            onChange={(e) => {
                if (isNaN(e.target.value) && e.target.value !== "") return;
                props.setVal(+e.target.value);
            }}
        />
    );
};

export default NumberItem;
