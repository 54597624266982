import React from "react";
// import styles from "./savestatusindicator.module.css";

const SaveStatusIndicator = (props) => {
    return null;
    // const [pendingSaves, setPendingSaves] = React.useState(0);
    // const [lastSaveStatus, setLastSaveStatus] = React.useState({
    //     code: -1,
    //     message: "",
    // });

    // const [isVisible, setIsVisible] = React.useState(false);
    // const [message, setMessage] = React.useState("");
    // const timeout = React.useRef();

    // React.useEffect(() => {
    //     Server.onSave({
    //         start: () => {
    //             setPendingSaves((s) => s + 1);
    //         },
    //         finish: (status) => {
    //             setPendingSaves((s) => s - 1);
    //             setLastSaveStatus({ ...status });
    //         },
    //     });
    // }, []);

    // React.useEffect(() => {
    //     if (pendingSaves > 0) {
    //         setMessage("Saving...");
    //         clearTimeout(timeout.current);
    //         return setIsVisible(true);
    //     }
    // }, [pendingSaves]);

    // React.useEffect(() => {
    //     if (lastSaveStatus.code === -1) return;

    //     setIsVisible(true);
    //     setMessage(lastSaveStatus.message);

    //     timeout.current = setTimeout(() => {
    //         setIsVisible(false);
    //     }, 2000);
    // }, [lastSaveStatus]);

    // const dynamicStyles = () => {
    //     let backgroundColor = "";

    //     if (pendingSaves === 0) {
    //         if (lastSaveStatus.code === 1) {
    //             backgroundColor = "var(--success-text-color)";
    //         } else {
    //             backgroundColor = "var(--danger-text-color)";
    //         }
    //     }

    //     return {
    //         top: isVisible ? "30px" : "-60px",
    //         backgroundColor,
    //     };
    // };

    // const dynamicLogo = () => {
    //     let c = "";
    //     if (pendingSaves > 0) c = "fa-spin fa-spinner";
    //     else if (lastSaveStatus.code === 1) c = "fa-check";
    //     else if (lastSaveStatus.code === 2) c = "fa-exclamation-triangle";

    //     return <i className={"fas " + c} style={{ marginRight: "10px" }}></i>;
    // };

    // return (
    //     <div className={styles.wrap} style={dynamicStyles()}>
    //         {dynamicLogo()}
    //         {message}
    //     </div>
    // );
};

export default React.memo(SaveStatusIndicator);
