import React from "react";
import styles from "./hubsettings.module.css";
import Breadcrumbs from "../breadcrumbs/breadcrumbs";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import SquareSelection from "../square_selection/square_selection";
import PreviewsSettings from "../previewssettings/previewssettings";
import FilterSettings from "../filtersettings/filtersettings";
import { useAlert } from "react-alert";
import DangerZone from "../dangerzone/dangerzone";
import StatusSettings from "../statusettings/status_settings";
import WebhookSettings from "../webhooksettings/webhooksettings";
import CacheContext from "../../contexts/CacheContext";
import useConfig from "../../hooks/useConfig";

const More = (props) => {
    const { hubid } = props;

    const { execute, request } = React.useContext(CacheContext);
    const [hub, set_hub] = React.useState(-1);

    const alert = useAlert();

    React.useEffect(() => {
        const { has_loaded, data } = request("hub", hubid);

        if (!has_loaded) return;

        set_hub(data);
    }, [request, hubid]);

    if (hub === -1) return <div>Loading...</div>;

    return (
        <div>
            <div className="input-label">Hub Name</div>
            <input
                className="input"
                defaultValue={hub.name || ""}
                onBlur={(e) => {
                    execute("update_hub", {
                        hubid,
                        changes: { name: e.target.value },
                    });
                }}
            />
            <div style={{ marginTop: "30px" }}>
                <DangerZone
                    groups={[
                        {
                            title: "Delete All Tickets",
                            subtitle: (
                                <span>
                                    Delete all of the tickets in{" "}
                                    <span
                                        style={{
                                            fontWeight: "500",
                                            color: "var(--dark-text-color)",
                                        }}
                                    >
                                        {hub.name}
                                    </span>
                                </span>
                            ),
                            onClick: () => {
                                alert.show(
                                    `Are you sure you would like to delete all of the tickets in "${hub.name}"?`,
                                    {
                                        timeout: 0,
                                        type: "confirm",
                                        title: `Delete All Tickets`,
                                        actions: [
                                            {
                                                text: "Delete All Tickets",
                                                color:
                                                    "var(--danger-text-color)",
                                                onClick: () => {
                                                    execute(
                                                        "delete_many_tickets",
                                                        hubid
                                                    );
                                                },
                                            },
                                        ],
                                    }
                                );
                            },
                        },
                        {
                            title: "Delete Hub",
                            subtitle: (
                                <span>
                                    Permanently delete the hub{" "}
                                    <span
                                        style={{
                                            fontWeight: "500",
                                            color: "var(--dark-text-color)",
                                        }}
                                    >
                                        {hub.name}
                                    </span>
                                </span>
                            ),
                            onClick: () => {
                                alert.show(
                                    `Are you sure you would like to delete "${hub.name}" ?`,
                                    {
                                        timeout: 0,
                                        type: "confirm",
                                        title: `Delete ${hub.name}`,
                                        actions: [
                                            {
                                                text: "Delete",
                                                color:
                                                    "var(--danger-text-color)",
                                                onClick: () => {
                                                    execute(
                                                        "delete_hub",
                                                        props.hubid
                                                    ).then(() => {
                                                        props.history.push(
                                                            "/settings/hubs"
                                                        );
                                                    });
                                                    // delete_hub(
                                                    //     props.hubid
                                                    // ).then(() =>
                                                    //     props.history.push(
                                                    //         "/settings/hubs"
                                                    //     )
                                                    // );
                                                },
                                            },
                                        ],
                                    }
                                );
                            },
                        },
                    ]}
                />
            </div>
        </div>
    );
};

const Groups = (props) => {
    const { hubid } = props.match.params;
    const alert = useAlert();
    const [hub, set_hub] = React.useState(-1);
    const { request, execute } = React.useContext(CacheContext);

    React.useEffect(() => {
        const { has_loaded, data } = request("hub", hubid);

        if (!has_loaded) return;

        set_hub(data);
    }, [hubid, request]);

    if (hub === -1) return <div>Loading...</div>;

    const { groups } = hub;

    const go_to_group = (groupid) => {
        props.history.push(`/groupsettings/${hubid}/${groupid}`);
    };

    return (
        <div className={styles.groupWrap}>
            <SquareSelection
                squares={groups.filter((group) => group.permanent)}
                squareColor="#83FFBC"
                squareTextColor="#333333"
                onClick={go_to_group}
            />
            {groups === -1 ? (
                "Loading Groups..."
            ) : (
                <SquareSelection
                    squares={groups.filter((group) => !group.permanent)}
                    onClick={go_to_group}
                    onAdd={() => {
                        alert.show(
                            "What would you like to name your new group?",
                            {
                                type: "prompt",
                                timeout: 0,
                                finishText: "Create Group",
                                loadingText: "Creating...",
                                onValue: (name) => {
                                    return new Promise((resolve) => {
                                        execute("create_group", {
                                            name,
                                            hubid,
                                        }).then((groupid) => {
                                            go_to_group(groupid);
                                            resolve();
                                        });
                                    });
                                },
                            }
                        );
                    }}
                />
            )}
        </div>
    );
};

const HubSettings = (props) => {
    const { hubid } = props.match.params;
    const { has_premium } = useConfig();
    // const { name, has_loaded } = useHubName(props.match.params.hubid);

    // if (!has_loaded) return <div>Loading...</div>;

    const { request } = React.useContext(CacheContext);
    const [name, set_name] = React.useState(-1);

    React.useEffect(() => {
        const { has_loaded, data } = request("hub", hubid);

        if (!has_loaded) return;

        set_name(data.name);
    }, [request, hubid]);

    if (name === -1) return <div>Loading...</div>;

    return (
        <div className={styles.wrap}>
            <div>
                <Breadcrumbs
                    crumbs={[
                        {
                            name: "Hubs",
                            link: "/settings/hubs",
                        },
                        {
                            name,
                        },
                    ]}
                />
                <div className="divided-menu" style={{ marginTop: "20px" }}>
                    <NavLink
                        to={props.match.url + "/groups"}
                        className="divided-menu-item delink"
                        activeClassName="active"
                    >
                        Groups
                    </NavLink>
                    <NavLink
                        to={props.match.url + "/filters"}
                        className="divided-menu-item delink"
                        activeClassName="active"
                    >
                        Filters
                    </NavLink>
                    <NavLink
                        to={props.match.url + "/previews"}
                        className="divided-menu-item delink"
                        activeClassName="active"
                    >
                        Previews
                    </NavLink>
                    <NavLink
                        to={props.match.url + "/status"}
                        className="divided-menu-item delink"
                        activeClassName="active"
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            Statuses
                            {!has_premium && (
                                <span className="premium-badge">
                                    <i className="fas fa-star"></i>
                                </span>
                            )}
                        </div>
                    </NavLink>
                    <NavLink
                        to={props.match.url + "/webhooks"}
                        className="divided-menu-item delink"
                        activeClassName="active"
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            Webhooks
                            {!has_premium && (
                                <span className="premium-badge">
                                    <i className="fas fa-star"></i>
                                </span>
                            )}
                        </div>
                    </NavLink>
                    <NavLink
                        to={props.match.url + "/more"}
                        className="divided-menu-item delink"
                        activeClassName="active"
                    >
                        More
                    </NavLink>
                </div>
            </div>
            <div
                style={{
                    flexGrow: "1",
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "scroll",
                }}
            >
                <Switch>
                    <Route
                        path={"/hubsettings/:hubid/more"}
                        render={(routerProps) => {
                            return (
                                <More
                                    {...routerProps}
                                    hubid={props.match.params.hubid}
                                />
                            );
                        }}
                    />
                    <Route
                        //have to do this special in order to preserve params
                        path="/hubsettings/:hubid/groups"
                        component={Groups}
                    />
                    <Route
                        path="/hubsettings/:hubid/filters"
                        component={FilterSettings}
                    />
                    <Route
                        path="/hubsettings/:hubid/previews"
                        component={PreviewsSettings}
                    />
                    <Route
                        path="/hubsettings/:hubid/status"
                        component={StatusSettings}
                    />
                    <Route
                        path="/hubsettings/:hubid/webhooks"
                        component={WebhookSettings}
                    />
                    <Route
                        path={"/hubsettings/:hubid/"}
                        exact
                        render={(nav_props) => {
                            const { hubid } = nav_props.match.params;

                            return (
                                <Redirect to={`/hubsettings/${hubid}/groups`} />
                            );
                        }}
                    />
                </Switch>
            </div>
        </div>
    );
};

export default HubSettings;
