import React from "react";
import styles from "./settings.module.css";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import Breadcrumbs from "../breadcrumbs/breadcrumbs";
import SquareSelection from "../square_selection/square_selection";
import arrayify from "../../arrayify";
import Select from "../select/select";
import Modal from "../modal/modal";
import { getList } from "../../listStore";
import Dropdown from "../dropdown/dropdown";
import { useAlert } from "react-alert";
import Logo from "../logo/logo";
import CacheContext from "../../contexts/CacheContext";
import useConfig from "../../hooks/useConfig";
import Logs from "../logs/logs";
import axios from "axios";

const LogoSettings = (props) => {
    const { set_logo } = useConfig(); // leaving this in here for now, not worth it to migrate over to the new model

    const handleChange = (e) => {
        let img = e.target.files[0];

        if (!img) return;

        let reader = new FileReader();

        reader.onload = (e) => {
            set_logo(e.target.result);
        };

        reader.readAsDataURL(img);
    };

    return (
        <div className={!props.premium ? "disabled" : ""}>
            <div
                className="input-label"
                style={{ display: "flex", alignItems: "center" }}
            >
                Logo{" "}
                {!props.premium && (
                    <span
                        className="premium-badge"
                        style={{ marginLeft: "6px" }}
                    >
                        <i className="fas fa-star"></i>
                    </span>
                )}
            </div>
            <label
                className={"input-file-wrap " + styles.logo}
                style={{ height: "100px", marginTop: "6px" }}
            >
                <Logo />
                <input type="file" accept="image/*" onChange={handleChange} />
            </label>
        </div>
    );
};

const PremiumSettings = (props) => {
    const alert = useAlert();
    const { config: info } = useConfig();

    if (info === -1) return <div>Loading...</div>;

    return (
        <div>
            <div className={styles.premiumTitle}>Hubtrack Premium</div>
            <div className={styles.premiumSubTitle}>
                For when you're ready to take your business to the next level.
            </div>
            {info.has_premium === false && (
                <div>
                    <div className={styles.features}>
                        <div>Unlimited everything</div>
                        <div>Custom logo</div>
                        <div>
                            <i>And every new feature we add, forever...</i>
                        </div>
                    </div>
                    <a
                        className="button is-link"
                        style={{ display: "inline-block" }}
                        href={`https://hubtrk.com/premium/?code=${info.code}`}
                    >
                        Get Hubtrack Premium for only $10 per month
                    </a>
                </div>
            )}
            {info.has_premium === true && (
                <div style={{ marginTop: "20px" }}>
                    <div>
                        <div>You're already subscribed to Hubtrack Premium</div>
                        <div
                            className="danger-link"
                            style={{ marginTop: "10px" }}
                            onClick={() => {
                                alert.show(
                                    `Are you sure you would like to unsubscribe to Hubtrack Premium. This is highly irregular.`,
                                    {
                                        timeout: 0,
                                        type: "confirm",
                                        title: `Unsubcribe`,
                                        actions: [
                                            {
                                                text: "Unsubscribe",
                                                color:
                                                    "var(--danger-text-color)",
                                                onClick: () => {
                                                    return new Promise(
                                                        (resolve, reject) => {
                                                            axios
                                                                .post(
                                                                    "/downgrade"
                                                                )
                                                                .then(() => {
                                                                    alert.show(
                                                                        "Successfully unsubscribed from Hubtrack Premium"
                                                                    );
                                                                    resolve();
                                                                })
                                                                .catch(() => {
                                                                    alert.error(
                                                                        "There was an error cancelling your subscription"
                                                                    );
                                                                    resolve();
                                                                });
                                                        }
                                                    );
                                                    // alert.show(
                                                    //     "Functionality not yet supported. Please contact support."
                                                    // );
                                                    // fetch(
                                                    //     "https://hubtrk.com/server/cancelSubscription",
                                                    //     {
                                                    //         method: "POST",
                                                    //         headers: {
                                                    //             "Content-Type":
                                                    //                 "application/json",
                                                    //             Authorization:
                                                    //                 authData.token,
                                                    //         },
                                                    //         body: JSON.stringify(
                                                    //             info
                                                    //         ),
                                                    //     }
                                                    // )
                                                    //     .then((res) =>
                                                    //         res.json()
                                                    //     )
                                                    //     .then((data) => {
                                                    //         if (
                                                    //             data.hasError
                                                    //         ) {
                                                    //             alert.show(
                                                    //                 data.message
                                                    //             );
                                                    //         } else {
                                                    //             window.alert(
                                                    //                 "You've been unsubscribed from Hubtrack Premium"
                                                    //             );
                                                    //             window.location.reload();
                                                    //         }
                                                    //     });
                                                },
                                            },
                                        ],
                                    }
                                );
                            }}
                        >
                            Unsubscribe
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const More = (props) => {
    const { has_premium } = useConfig();

    return (
        <div>
            <div className={styles.group}>
                <PremiumSettings />
            </div>
            <LogoSettings premium={has_premium} />

            <div className={styles.joint}>A Ben Schoelkopf Joint</div>
        </div>
    );
};

const new_user_defaults = {
    username: undefined,
    password: "",
    verify_password: "",
    role: "normal",
};

const NewUserModal = (props) => {
    const [form_data, set_form_data] = React.useState(new_user_defaults);

    const userRoles = arrayify(getList("userRoles"), "value");

    const handle_form_change = (e) => {
        const form_key = e.target.getAttribute("form_key");
        set_form_data({
            ...form_data,
            [form_key]: e.target.value,
        });
    };

    const validate_input = (input) => {
        const { username, password, verify_password } = input;

        if (!username || username === "") return false;

        if (password !== verify_password) return false;

        return true;
    };

    const try_create_user = () => {
        const { username, password, role } = form_data;

        if (validate_input(form_data)) {
            props.on_create({ username, password, role });
            set_form_data(new_user_defaults);
        }
    };

    return (
        <Modal
            isVisible={props.isVisible}
            onClose={() => {
                props.onClose();
            }}
        >
            <div style={{ backgroundColor: "#ffffff", borderRadius: "6px" }}>
                <div
                    style={{
                        padding: "30px",
                    }}
                >
                    <div
                        style={{
                            color: "var(--dark-text-color)",
                            fontWeight: "600",
                            fontSize: "18px",
                        }}
                    >
                        Create New User
                    </div>
                    <div className="input-label">Username</div>
                    <input
                        type="text"
                        className="input"
                        form_key="username"
                        value={form_data.username || ""}
                        onChange={handle_form_change}
                    />
                    {form_data.username !== undefined &&
                        !form_data.username && (
                            <div className={styles.form_error}>
                                A username is required
                            </div>
                        )}
                    <div className="input-label">Password</div>
                    <input
                        type="password"
                        className="input"
                        form_key="password"
                        value={form_data.password || ""}
                        onChange={handle_form_change}
                    />
                    <div className="input-label">Verify Password</div>
                    <input
                        type="password"
                        className="input"
                        form_key="verify_password"
                        value={form_data.verify_password || ""}
                        onChange={handle_form_change}
                    />
                    {form_data.password !== undefined &&
                        form_data.verify_password !== undefined &&
                        form_data.password !== form_data.verify_password && (
                            <div className={styles.form_error}>
                                Passwords must match
                            </div>
                        )}
                    <div className="input-label">Role</div>
                    <Select
                        values={userRoles}
                        value={userRoles.find(
                            (role) => role.value === form_data.role
                        )}
                        onSelect={(role) => {
                            set_form_data({
                                ...form_data,
                                role: role.value,
                            });
                        }}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "30px",
                        paddingTop: "10px",
                    }}
                >
                    <div
                        className="danger-link padded"
                        style={{ fontWeight: "600" }}
                        onClick={props.onClose}
                    >
                        Cancel
                    </div>
                    <div
                        className={`link padded ${
                            !validate_input(form_data) && "disabled"
                        }`}
                        style={{ fontWeight: "600" }}
                        onClick={try_create_user}
                    >
                        Create User
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const UserPage = (props) => {
    const { request, execute } = React.useContext(CacheContext);
    const [users, set_users] = React.useState(-1);

    const userRoles = arrayify(getList("userRoles"), "value");
    const alert = useAlert();

    const [form_data, set_form_data] = React.useState({
        username: "",
        password: "",
        role: "normal",
        id: "",
    });

    const [is_changing_password, set_is_changing_password] = React.useState(
        false
    );

    React.useEffect(() => {
        const { has_loaded, data } = request("users");

        if (!has_loaded) return;

        set_users(data.users);
    }, [request]);

    React.useEffect(() => {
        if (users === -1) return;

        const user = users.find(
            (user) => user.id === props.match.params.userid
        );

        if (!user) return;

        if (form_data.id !== user.id) {
            set_form_data({
                ...user,
                password: "",
            });
        }
    }, [users, props.match.params.userid, form_data]);

    const closeModal = () => props.history.push("/settings/users");

    if (users === -1) return <div>Loading User...</div>;

    const userInfo = users.find(
        (user) => user.id === props.match.params.userid
    );

    if (!userInfo) return <Redirect to="/settings/users" />;

    return (
        <Modal isVisible onClose={closeModal}>
            <div style={{ background: "#ffffff", borderRadius: "6px" }}>
                {userInfo === -1 && "Loading..."}
                {userInfo !== -1 && (
                    <>
                        <div style={{ padding: "30px" }}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: "18px",
                                        fontWeight: "600",
                                        color: "var(--dark-text-color)",
                                    }}
                                >
                                    {userInfo.username}
                                </div>
                                <Dropdown direction="left">
                                    <Dropdown.Item
                                        onClick={() => {
                                            if (arrayify(users).length <= 1) {
                                                return;
                                            }

                                            alert.show(
                                                `Are you sure you would like to delete "${userInfo.username}"?`,
                                                {
                                                    position: "middle",
                                                    timeout: 0,
                                                    type: "confirm",
                                                    title: `Delete ${userInfo.username}`,
                                                    actions: [
                                                        {
                                                            text: "Delete",
                                                            color:
                                                                "var(--danger-text-color)",
                                                            onClick: () => {
                                                                return new Promise(
                                                                    (
                                                                        resolve
                                                                    ) => {
                                                                        execute(
                                                                            "delete_user",
                                                                            userInfo.id
                                                                        ).then(
                                                                            () => {
                                                                                resolve();
                                                                                props.history.push(
                                                                                    "/settings/users"
                                                                                );
                                                                            }
                                                                        );
                                                                    }
                                                                );
                                                            },
                                                        },
                                                    ],
                                                }
                                            );
                                        }}
                                    >
                                        <div
                                            style={{
                                                opacity:
                                                    arrayify(users).length <
                                                        2 && "0.25",
                                            }}
                                            className="danger-link"
                                        >
                                            Delete User
                                        </div>
                                    </Dropdown.Item>
                                </Dropdown>
                            </div>
                            <div
                                style={{
                                    margin: "20px 0px",
                                }}
                            >
                                <div className="input-label">Name</div>
                                <input
                                    type="text"
                                    className="input"
                                    autoFocus
                                    value={form_data.username}
                                    onChange={(e) => {
                                        set_form_data({
                                            ...form_data,
                                            username: e.target.value,
                                        });
                                    }}
                                />
                                <div className="input-label">Role</div>
                                <Select
                                    values={userRoles}
                                    value={userRoles.find(
                                        (role) => form_data.role === role.value
                                    )}
                                    onSelect={(v) => {
                                        set_form_data({
                                            ...form_data,
                                            role: v.value,
                                        });
                                    }}
                                />
                                <div className="input-label">Password</div>
                                {is_changing_password ? (
                                    <input
                                        type="password"
                                        placeholder="Enter a new password: "
                                        value={form_data.password}
                                        autoFocus
                                        onChange={(e) =>
                                            set_form_data({
                                                ...form_data,
                                                password: e.target.value,
                                            })
                                        }
                                        className="input"
                                    />
                                ) : (
                                    <div
                                        className="link"
                                        onClick={() =>
                                            set_is_changing_password(true)
                                        }
                                    >
                                        Change Password
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            style={{
                                padding: "30px",
                                paddingTop: "0px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                className="danger-link padded"
                                style={{ fontWeight: "600" }}
                                onClick={closeModal}
                            >
                                Cancel
                            </div>
                            <div
                                className="link padded"
                                style={{ fontWeight: "600" }}
                                onClick={() => {
                                    execute("update_user", {
                                        userid: props.match.params.userid,
                                        updates: form_data,
                                    }).then(closeModal);
                                }}
                            >
                                Save Changes
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

const Users = (props) => {
    const { request, execute } = React.useContext(CacheContext);
    const { has_premium } = useConfig();
    const [creating_new_user, set_creating_new_user] = React.useState(false);
    const [users, set_users] = React.useState(-1);

    React.useEffect(() => {
        const { has_loaded, data } = request("users");

        if (!has_loaded) return;

        set_users(data.users);
    }, [request]);

    if (users === -1) return <div>Loading Users...</div>;

    return (
        <React.Fragment>
            <NewUserModal
                isVisible={creating_new_user}
                onClose={() => set_creating_new_user(false)}
                usernames={users.map((user) => user.username)}
                on_create={(form_data) => {
                    execute("create_user", form_data).finally(() => {
                        set_creating_new_user(false);
                    });
                }}
            />
            <Route path={props.match.url + "/:userid"} component={UserPage} />
            <Route
                path={props.match.url + "/"}
                render={() => {
                    return (
                        <SquareSelection
                            onAdd={() => {
                                set_creating_new_user(true);
                            }}
                            onClick={(userid) => {
                                props.history.push(
                                    props.match.url + "/" + userid
                                );
                            }}
                            disableAdd={!has_premium && users.length >= 3}
                            squares={users.map((user) => {
                                user.name = user.username;
                                return user;
                            })}
                        />
                    );
                }}
            />
        </React.Fragment>
    );
};

const Hubs = (props) => {
    const alert = useAlert();
    const { has_premium } = useConfig();

    const { request, execute } = React.useContext(CacheContext);
    const [hubs, set_hubs] = React.useState(-1);

    React.useEffect(() => {
        const { has_loaded, data } = request("hubs");

        if (!has_loaded) return;

        set_hubs(data.hubs);
    }, [request]);

    if (hubs === -1) return <div>Loading...</div>;

    return (
        <div>
            <SquareSelection
                squares={hubs.sort((a, b) => a.id.localeCompare(b.id))}
                onAdd={() => {
                    alert.show("What would you like to name your new hub?", {
                        type: "prompt",
                        timeout: 0,
                        finishText: "Create Hub",
                        loadingText: "Creating...",
                        onValue: (name) => {
                            return new Promise((resolve, reject) => {
                                if (!name || name === "") {
                                    alert.error(
                                        "The name you provided was invalid"
                                    );
                                    return reject();
                                }

                                execute("create_hub", name).then((hub) => {
                                    resolve();
                                    props.history.push(
                                        `/hubsettings/${hub.id}`
                                    );
                                });
                                // create_hub(name).then((hub) => {
                                //     resolve();
                                //     props.history.push(
                                //         "/hubsettings/" + hub.id
                                //     );
                                // });
                            });
                        },
                    });
                }}
                disableAdd={!has_premium && hubs.length >= 3}
                onClick={(hubid) => {
                    props.history.push("/hubsettings/" + hubid);
                }}
            />
        </div>
    );
};

const Settings = (props) => {
    const { has_premium } = useConfig();

    return (
        <div className={styles.wrap}>
            <div>
                <Breadcrumbs crumbs={[{ name: "Settings" }]} />
                <div className="divided-menu" style={{ marginTop: "20px" }}>
                    <NavLink
                        to={props.match.url + "/hubs"}
                        className="divided-menu-item delink"
                        activeClassName="active"
                    >
                        Hubs
                    </NavLink>
                    <NavLink
                        to={props.match.url + "/users"}
                        className="divided-menu-item delink"
                        activeClassName="active"
                    >
                        Users
                    </NavLink>
                    <NavLink
                        to={props.match.url + "/logs"}
                        className="divided-menu-item delink"
                        activeClassName="active"
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            Logs{" "}
                            {!has_premium && (
                                <span
                                    className="premium-badge"
                                    style={{ marginLeft: "6px" }}
                                >
                                    <i className="fas fa-star"></i>
                                </span>
                            )}
                        </div>
                    </NavLink>
                    <NavLink
                        to={props.match.url + "/more"}
                        className="divided-menu-item delink"
                        activeClassName="active"
                    >
                        More
                    </NavLink>
                </div>
            </div>
            <div style={{ overflowY: "scroll" }}>
                <Switch>
                    <Route path={props.match.url + "/more"} component={More} />
                    <Route
                        path={props.match.url + "/users"}
                        component={Users}
                    />
                    <Route path={props.match.url + "/hubs"} component={Hubs} />
                    <Route path={props.match.url + "/logs"} component={Logs} />
                    <Route
                        exact
                        path={props.match.url + "/"}
                        render={() => (
                            <Redirect to={props.match.path + "/hubs"} />
                        )}
                    />
                </Switch>
            </div>
        </div>
    );
};

export default Settings;
