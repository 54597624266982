import React from "react";

const PhoneItem = props => {
    const inputRef = React.useRef();

    React.useEffect(() => {
        if (props.shouldFocus && inputRef.current) inputRef.current.focus();
    }, [props.shouldFocus]);

    const formatIntoPhone = phone => {
        if (phone.length === 0) return "";

        const groups = [
            {
                max: 3,
                val: ""
            },
            {
                max: 3,
                val: ""
            },
            {
                max: 4,
                val: ""
            }
        ];

        for (var i = 0; i < phone.length; i++) {
            const validGroupIndex = groups.findIndex(
                group => group.val.length < group.max
            );
            groups[validGroupIndex].val += phone[i];
        }

        let textToReturn = "";

        if (groups[0].val !== "") {
            textToReturn += "(" + groups[0].val + ")";
        }

        if (groups[1].val !== "") {
            textToReturn += "-" + groups[1].val;
        }

        if (groups[2].val !== "") {
            textToReturn += "-" + groups[2].val;
        }

        return textToReturn;
    };

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <input
                type="text"
                className="input"
                ref={inputRef}
                maxLength="10"
                value={props.val || ""}
                placeholder={"Enter " + props.attribute.name}
                onChange={e => {
                    let validChars = "";
                    for (var i = 0; i < e.target.value.length; i++) {
                        if (!isNaN(e.target.value[i]))
                            validChars = validChars + e.target.value[i];
                    }
                    e.target.value = validChars;
                    props.setVal(validChars);
                }}
                style={{ width: "50%", marginRight: "16px" }}
            />
            <div style={{ color: "var(--link-text-color)" }}>
                {formatIntoPhone(props.val || "")}
            </div>
        </div>
    );
};

export default PhoneItem;
