import React from "react";
import styles from "./ticketpreview.module.css";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import Waiter from "../waiter/waiter";
import { transformTemplate } from "../../transformTemplate";
import arrayify from "../../arrayify";

const TicketPreview = (props) => {
    const { ticket } = props;

    const location = useLocation();

    const [link_ref, set_link_ref] = React.useState();

    // React.useEffect(() => {
    //     const onPreviews = (prevs) => {
    //         setPreviews(prevs || {});
    //     };

    //     Server.source("hubs")
    //         .source(hubid)
    //         .source("previews")
    //         .subscribe(onPreviews);

    //     return () => {
    //         Server.source("hubs")
    //             .source(hubid)
    //             .source("previews")
    //             .unsubscribe(onPreviews);
    //     };
    // }, [hubid, ticket.presentGroups]);

    React.useEffect(() => {
        const move_into_view = () => {
            const ticketel = link_ref;
            if (!ticketel) return;

            ticketel.scrollIntoView();
        };

        const is_in_view = () => {
            const ticketel = link_ref;
            if (!ticketel) return;

            const ticketsWrap = document.querySelector("#tickets-wrap");
            if (!ticketsWrap) return;

            const {
                top: ticketsTop,
                bottom: ticketsBottom,
            } = ticketsWrap.getBoundingClientRect();

            const posFromTop =
                ticketel.getBoundingClientRect().top - ticketsTop;
            const posFromBottom =
                ticketsBottom - ticketel.getBoundingClientRect().bottom;

            return posFromTop >= 0 && posFromBottom >= 0;
        };

        const { pathname } = location;
        const params = pathname.split("/").slice(1);

        if (params.length !== 2) return;

        const ticketid = params[1];

        if (ticketid === ticket.id && !is_in_view()) move_into_view();
    }, [location, link_ref, ticket.id]);

    return (
        <NavLink
            to={props.match.url + "/" + ticket.id}
            className={styles.wrap}
            activeClassName={styles.active}
            ref={set_link_ref}
        >
            <div className={styles.top}>
                <Waiter
                    initialValue="Loading"
                    func={() => {
                        return transformTemplate(
                            props.previews.title,
                            ticket,
                            props.attributes
                        );
                    }}
                />
                <div className={styles.statuses}>
                    {ticket.members.map((statusid) => {
                        const status = props.statuses.find(
                            (s) => s.id === statusid
                        );

                        if (!status) return null;

                        return (
                            <div
                                className={styles.status}
                                key={`${ticket.id}-${status.id}`}
                                style={{ color: status.color }}
                            >
                                <i className="fas fa-square"></i>
                                {status.name}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={styles.bottom}>
                {arrayify(ticket.presentGroups).map(({ groupid }, index) => {
                    return (
                        <div
                            key={"pv-line-" + ticket.id + "-" + index}
                            style={{ whiteSpace: "pre-line" }}
                        >
                            <Waiter
                                initialValue="Loading"
                                func={() => {
                                    return transformTemplate(
                                        props.previews[groupid],
                                        ticket,
                                        props.attributes
                                    );
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </NavLink>
    );
};

export default withRouter(TicketPreview);
