import React from "react";
import styles from "./ticketview.module.css";
import Dropdown from "../dropdown/dropdown";
import { getList } from "../../listStore";
import Modal from "../modal/modal";
import { useAlert } from "react-alert";
import { Link, Redirect } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import CacheContext from "../../contexts/CacheContext";
import TicketHistory from "../tickethistory/tickethistory";

const attributeTypes = getList("attributeTypes");

const ViewItem = (props) => {
    const ViewElement =
        attributeTypes[props.attribute.type].ViewElement ||
        (() => (
            <div>{props.attribute.type} attributes are not yet configured</div>
        ));

    return (
        <div className={styles.viewItem}>
            <div
                style={{
                    fontSize: "14px",
                    color: "var(--light-text-color)",
                    fontWeight: "500",
                    marginBottom: "6px",
                }}
            >
                {props.attribute.name}
            </div>
            <div style={{ fontSize: "18px", fontWeight: "500" }}>
                <ViewElement {...props} />
            </div>
        </div>
    );
};

const TicketView = (props) => {
    const { get_title } = props;
    const { hubid, ticketid } = props.match.params;
    const { request, execute, get_at_path } = React.useContext(CacheContext);
    const [ticket_title, set_ticket_title] = React.useState(-1);
    const [created_user, set_created_user] = React.useState(-1);
    const [showingHistory, setShowingHistory] = React.useState(false);

    const {
        auth: { role },
    } = React.useContext(AuthContext);
    const alert = useAlert();

    React.useEffect(() => {
        let shouldLoad = true;
        get_title().then((t) => {
            if (shouldLoad) set_ticket_title(t);
        });

        return () => (shouldLoad = false);
    }, [get_title]);

    const { ticket } = props;
    React.useEffect(() => {
        if (!ticket) return;

        set_created_user({
            username: "N/A"
        });

        const usernames = get_at_path("usernames")

        set_created_user({
            username: usernames[ticket.createdBy] || "N/A"
        })
    }, [ticket, request, role, get_at_path]);

    // React.useEffect(() => {
    //     if (titleTemplate !== -1 && ticket !== -1 && hubid) {
    //         transformTemplate(titleTemplate, hubid, "base", ticket).then(
    //             (t) => {
    //                 setTitle(t);
    //             }
    //         );
    //     }
    // }, [hubid, ticket, titleTemplate, title]);

    // React.useEffect(() => {
    //     const onTitleTemp = (temp) => setTitleTemplate(temp || "");

    //     const onGroups = (groups) => {
    //         const present = arrayify({ ...ticket.presentGroups }).map(
    //             (group) => group.groupid
    //         );
    //         const allAtts = arrayify(groups)
    //             .filter((group) => present.includes(group.id))
    //             .reduce((arr, group) => {
    //                 const atts = arrayify(group.attributes);
    //                 atts.map((att) => {
    //                     att.groupid = group.id;
    //                     att.hubid = hubid;
    //                     att.ticketid = ticketid;
    //                     return att;
    //                 });
    //                 arr = arr.concat(atts);
    //                 return arr;
    //             }, []);

    //         setAttributes(allAtts);
    //     };

    //     Server.source("hubs")
    //         .source(hubid)
    //         .source("previews")
    //         .source("title")
    //         .subscribe(onTitleTemp);

    //     Server.source("hubs")
    //         .source(hubid)
    //         .source("groups")
    //         .subscribe(onGroups);

    //     return () => {
    //         Server.source("hubs")
    //             .source(hubid)
    //             .source("groups")
    //             .unsubscribe(onGroups);

    //         Server.source("hubs")
    //             .source(hubid)
    //             .source("previews")
    //             .source("title")
    //             .unsubscribe(onTitleTemp);
    //     };
    // }, [
    //     hubid,
    //     ticketid,
    //     ticket,
    //     ticket.presentGroups,
    //     props.filter,
    //     props.history,
    // ]);

    // React.useEffect(() => {
    //     Server.source("tickets")
    //         .source(hubid)
    //         .source(ticketid)
    //         .subscribe((t) => {
    //             const pseudo = { ...t };
    //             if (!props.filter.run(pseudo)) {
    //                 return props.history.push("/" + hubid);
    //             }

    //             setTicket(pseudo);
    //         });
    // }, [hubid, ticketid, props.filter, props.history]);
    const properlySortAttributes = (atts) => {
        const groups = atts
            .filter((att) => {
                const keys = Object.keys(props.ticket.presentGroups)
                    .filter((key) => key !== "base")
                    .map((key) => props.ticket.presentGroups[key].groupid)
                    .concat(props.ticket.presentGroups.base.groupid);

                return keys.includes(att.groupid);
            })
            .reduce((ob, att) => {
                if (!ob[att.groupid]) ob[att.groupid] = [];
                ob[att.groupid].push(att);

                return ob;
            }, {});
        return Object.values(groups).reduce((arr, atts) => {
            arr.push(...atts.sort((a, b) => a.index - b.index));
            return arr;
        }, []);
    };

    if (!props.ticket) return <Redirect to={"/" + hubid} />;

    if (ticket_title === -1 || created_user === -1)
        return <div>Loading...</div>;

    return (
        <React.Fragment>
            <div className={styles.back}>
                <Link className="link" to={"/" + hubid}>
                    <i
                        className="fas fa-chevron-left"
                        style={{ marginRight: "8px" }}
                    ></i>{" "}
                    Back
                </Link>
            </div>
            <div className={styles.top}>
                <div>
                    <div>{ticket_title}</div>
                    {/* {role === "admin" && ( */}
                        <div className={styles.extra}>
                            Created By{" "}
                            <span
                                style={{
                                    fontWeight: "600",
                                    color: "var(--dark-text-color)",
                                }}
                            >
                                {created_user.username}
                            </span>{" "}
                            on{" "}
                            <span
                                style={{
                                    fontWeight: "600",
                                    color: "var(--dark-text-color)",
                                }}
                            >
                                {new Date(
                                    props.ticket.dateCreated
                                ).toLocaleDateString()}
                            </span>
                        </div>
                    {/* )} */}
                    <div className={styles.statuses}>
                        {ticket.members.map((statusid) => {
                            const status = props.statuses.find(
                                (s) => s.id === statusid
                            );

                            if (!status) return null;

                            return (
                                <div
                                    className={styles.status}
                                    key={`${ticket.id}-${status.id}`}
                                    style={{ color: status.color }}
                                >
                                    <i className="fas fa-square"></i>
                                    {status.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <Dropdown direction="left" toggleStyle={{ fontSize: "16px" }}>
                    {role === "admin" && (
                        <Dropdown.Item onClick={() => setShowingHistory(true)}>
                            <div className="link" style={{ fontWeight: "500" }}>
                                <i
                                    className="fas fa-clock"
                                    style={{ marginRight: "6px" }}
                                ></i>{" "}
                                Ticket History
                            </div>
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item
                        onClick={async () => {
                            alert.show(
                                `Are you sure you would like to delete "${ticket_title}"?`,
                                {
                                    timeout: 0,
                                    type: "confirm",
                                    title: `Delete ${ticket_title}`,
                                    actions: [
                                        {
                                            text: "Delete",
                                            color: "var(--danger-text-color)",
                                            onClick: () => {
                                                execute(
                                                    "delete_ticket",
                                                    ticketid
                                                ).then(() => {
                                                    props.history.push(
                                                        "/" + hubid
                                                    );
                                                });
                                            },
                                        },
                                    ],
                                }
                            );
                        }}
                    >
                        <div className="danger-link">
                            <i
                                className="fas fa-trash-alt"
                                style={{ marginRight: "6px" }}
                            ></i>{" "}
                            Delete Ticket
                        </div>
                    </Dropdown.Item>
                </Dropdown>
            </div>
            <div className={styles.bottom}>
                <div>
                    {properlySortAttributes(
                        props.attributes.filter(
                            (attribute) => attribute.showInForm
                        )
                    ).map((attribute) => {
                        return (
                            <ViewItem
                                key={attribute.id}
                                attribute={attribute}
                                value={props.ticket[attribute.id]}
                                hubid={hubid}
                                ticketid={ticketid}
                                ticket={props.ticket}
                            />
                        );
                    })}
                </div>
                {props.attributes.filter((attribute) => !attribute.showInForm)
                    .length !== 0 && (
                    <div className={styles.actionables}>
                        <div className={styles.actionablewrap}>
                            {props.attributes
                                .filter((attribute) => !attribute.showInForm)
                                .sort((a, b) => a.index - b.index)
                                .map((attribute) => {
                                    return (
                                        <ViewItem
                                            key={attribute.id}
                                            attribute={attribute}
                                            value={props.ticket[attribute.id]}
                                            hubid={hubid}
                                            ticketid={ticketid}
                                            ticket={props.ticket}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                )}
            </div>
            {role === "admin" && (
                <Modal
                    isVisible={showingHistory}
                    onClose={() => setShowingHistory(false)}
                >
                    <TicketHistory ticketid={ticketid} hubid={hubid} />
                </Modal>
            )}
        </React.Fragment>
    );
};

export default TicketView;
