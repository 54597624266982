import axios from "axios";
import { LOADING, LOADED } from "../constants/load_statuses";
import merge_arr_by_key from "./merge_arr_by_key";

const users = {
    listeners: {
        user_created: {
            event_keys: ["user_created"],
            cb: (new_userid, lset_at_path, lcache, on_error) => {
                axios
                    .get(`/users/${new_userid}`)
                    .then(({ data: new_user }) => {
                        lset_at_path("users", [...lcache.users, new_user]);
                    })
                    .catch(on_error);
            },
        },
        user_deleted: {
            event_keys: ["user_deleted"],
            cb: (deleted_userid, lset_at_path, lcache) => {
                lset_at_path(
                    "users",
                    [...lcache.users].filter(
                        (user) => user.id !== deleted_userid
                    )
                );
            },
        },
        user_updated: {
            event_keys: ["user_updated"],
            cb: (updated_userid, lset_at_path, lcache, on_error) => {
                axios
                    .get(`/users/${updated_userid}`)
                    .then(({ data: updated_user }) => {
                        const pseudo = [...lcache.users].map((db_user) => {
                            if (db_user.id === updated_user.id)
                                return updated_user;
                            else return db_user;
                        });
                        lset_at_path("users", pseudo);
                    })
                    .catch(on_error);
            },
        },
    },
    has_loaded: (load_markers) => load_markers.users === LOADED,
    load_data: ({ set_at_path, load_markers, set_load_marker }) => {
        return new Promise((resolve, reject) => {
            if (!load_markers.users) {
                set_load_marker("users", LOADING);
                axios
                    .get("/users")
                    .then(({ data: users }) => {
                        set_at_path("users", users);
                        set_load_marker("users", LOADED);
                        resolve();
                    })
                    .catch(() => {
                        reject("There was an error loading users");
                    });
            } else {
                resolve();
            }
        });
    },
    extract: (cache) => {
        return { users: cache.users };
    },
};

const hubs = {
    listeners: {
        hub_created: {
            event_keys: ["hub_created"],
            cb: (new_hubid, lset_at_path, lcache, on_error) => {
                axios
                    .get(`/hubs/${new_hubid}`)
                    .then(({ data: new_hub }) => {
                        lset_at_path("hubs", [...lcache.hubs, new_hub]);
                    })
                    .catch(on_error);
            },
        },
        hub_deleted: {
            event_keys: ["hub_deleted"],
            cb: (deleted_hubid, lset_at_path, lcache) => {
                lset_at_path(
                    "hubs",
                    [...lcache.hubs].filter((hub) => hub.id !== deleted_hubid)
                );
            },
        },
        hub_updated: {
            event_keys: ["hub_updated"],
            cb: (updated_hubid, lset_at_path, lcache, on_error) => {
                axios
                    .get(`/hubs/${updated_hubid}`)
                    .then(({ data: updated_hub }) => {
                        lset_at_path("hubs", (existing_hubs) =>
                            existing_hubs.map((hub) => {
                                if (hub.id !== updated_hubid) return hub;

                                return {
                                    ...hub,
                                    ...updated_hub,
                                };
                            })
                        );
                    })
                    .catch(on_error);
            },
        },
    },
    has_loaded: (load_markers) => load_markers.hubs === LOADED,
    load_data: ({ load_markers, set_load_marker, set_at_path }) => {
        return new Promise((resolve, reject) => {
            if (!load_markers.hubs) {
                set_load_marker("hubs", LOADING);
                axios
                    .get("/hubs")
                    .then(({ data: hubs }) => {
                        set_at_path("hubs", (existing_hubs) =>
                            merge_arr_by_key(existing_hubs, hubs)
                        );
                        set_load_marker("hubs", LOADED);
                        resolve();
                    })
                    .catch(() => {
                        reject("There was an error loading your hubs");
                    });
            } else {
                resolve();
            }
        });
    },
    extract: (cache) => {
        return {
            hubs: cache.hubs,
        };
    },
};

const hub = (hubid) => {
    const load_marker_key = `hub_${hubid}`;

    const get_hub = () => {
        return new Promise((resolve, reject) => {
            axios.get(`/hubs/${hubid}`).then(resolve).catch(reject);
        });
    };
    const get_groups = () => {
        return new Promise((resolve, reject) => {
            axios.get(`/groups?hubid=${hubid}`).then(resolve).catch(reject);
        });
    };
    const get_filters = () => {
        return new Promise((resolve, reject) => {
            axios.get(`/filters?hubid=${hubid}`).then(resolve).catch(reject);
        });
    };

    const get_statuses = () => {
        return new Promise((resolve, reject) => {
            axios.get(`/statuses?hubid=${hubid}`).then(resolve).catch(reject);
        });
    };

    const get_webhooks = () => {
        return new Promise((resolve, reject) => {
            axios.get(`/webhooks?hubid=${hubid}`).then(resolve).catch(reject);
        });
    };

    return {
        listeners: {
            [`hub_${hubid}_updated`]: {
                event_keys: ["hub_updated"],
                cb: (updated_hubid, lset_at_path, lcache, on_error) => {
                    if (updated_hubid !== hubid) return;

                    axios
                        .get(`/hubs/${updated_hubid}`)
                        .then(({ data: updated_hub }) => {
                            lset_at_path("hubs", (existing_hubs) =>
                                existing_hubs.map((hub) => {
                                    if (hub.id !== updated_hubid) return hub;

                                    return {
                                        ...hub,
                                        ...updated_hub,
                                    };
                                })
                            );
                        })
                        .catch(on_error);
                },
            },
            group_created: {
                event_keys: ["group_created"],
                cb: (new_groupid, lset_at_path, lcache, on_error) => {
                    axios
                        .get(`/groups/${new_groupid}`)
                        .then(({ data: new_group }) => {
                            lset_at_path("groups", [
                                ...(lcache.groups || []),
                                new_group,
                            ]);
                        })
                        .catch(on_error);
                },
            },
            group_deleted: {
                event_keys: ["group_deleted"],
                cb: (deleted_groupid, lset_at_path, lcache) => {
                    lset_at_path(
                        "groups",
                        [...(lcache.groups || [])].filter(
                            (group) => group.id !== deleted_groupid
                        )
                    );
                },
            },
            group_updated: {
                event_keys: ["group_updated"],
                cb: (updated_groupid, lset_at_path, lcache, on_error) => {
                    axios
                        .get(`/groups/${updated_groupid}`)
                        .then(({ data: updates }) => {
                            lset_at_path(
                                "groups",
                                [...(lcache.groups || [])].map((group) => {
                                    if (group.id !== updated_groupid)
                                        return group;
                                    return {
                                        ...group,
                                        ...updates,
                                    };
                                })
                            );
                        })
                        .catch(on_error);
                },
            },
            filter_created: {
                event_keys: ["filter_created"],
                cb: (filterid, lset_at_path, lcache, on_error) => {
                    axios
                        .get(`/filters/${filterid}`)
                        .then(({ data: filter }) => {
                            lset_at_path("filters", [
                                ...(lcache.filters || []),
                                filter,
                            ]);
                        })
                        .catch(on_error);
                },
            },
            filter_deleted: {
                event_keys: ["filter_deleted"],
                cb: (filterid, lset_at_path, lcache) => {
                    lset_at_path(
                        "filters",
                        [...(lcache.filters || [])].filter(
                            (filter) => filter.id !== filterid
                        )
                    );
                },
            },
            filter_updated: {
                event_keys: ["filter_updated"],
                cb: (
                    filterid,
                    lset_at_path,
                    lcache,
                    on_error,
                    { set_load_marker }
                ) => {
                    axios
                        .get(`/filters/${filterid}`)
                        .then(({ data: retrieved_filter }) => {
                            for (const hash in lcache.queries) {
                                const query_params = atob(hash).split("_");

                                if (query_params[2] === filterid) {
                                    set_load_marker(hash, false);
                                }
                            }

                            lset_at_path(
                                "filters",
                                [...(lcache.filters || [])].map((filter) => {
                                    if (filter.id !== filterid) return filter;

                                    return {
                                        ...filter,
                                        ...retrieved_filter,
                                    };
                                })
                            );
                        })
                        .catch(on_error);
                },
            },
            many_filters_updated: {
                event_keys: ["many_filters_updated"],
                cb: (filterids, lset_at_path, lcache, on_error) => {
                    const query = new URLSearchParams({
                        ids: filterids,
                    });
                    axios
                        .get(`/filters/get_many?${query}`)
                        .then(({ data: filters }) => {
                            lset_at_path(
                                "filters",
                                [...(lcache.filters || [])].map((filter) => {
                                    return {
                                        ...filter,
                                        ...(filters.find(
                                            (f) => f.id === filter.id
                                        ) || {}),
                                    };
                                })
                            );
                        })
                        .catch(on_error);
                },
            },
            status_created: {
                event_keys: ["status_created"],
                cb: (statusid, lset_at_path, lcache, on_error) => {
                    axios
                        .get(`/statuses/${statusid}`)
                        .then(({ data: status }) => {
                            lset_at_path("statuses", [
                                ...(lcache.statuses || []),
                                status,
                            ]);
                        })
                        .catch(on_error);
                },
            },
            status_deleted: {
                event_keys: ["status_deleted"],
                cb: (statusid, lset_at_path, lcache) => {
                    lset_at_path(
                        "statuses",
                        [...(lcache.statuses || [])].filter(
                            (status) => status.id !== statusid
                        )
                    );
                },
            },
            status_updated: {
                event_keys: ["status_updated"],
                cb: (statusid, lset_at_path, lcache, on_error) => {
                    axios
                        .get(`/statuses/${statusid}`)
                        .then(({ data: updated_status }) => {
                            lset_at_path(
                                "statuses",
                                [...lcache.statuses].map((status) => {
                                    if (status.id !== statusid) return status;

                                    return {
                                        ...status,
                                        ...updated_status,
                                    };
                                })
                            );
                        })
                        .catch(on_error);
                },
            },
            webhook_created: {
                event_keys: ["webhook_created"],
                cb: (webhookid, lset_at_path, lcache, on_error) => {
                    axios
                        .get(`/webhooks/${webhookid}`)
                        .then(({ data: webhook }) => {
                            lset_at_path(
                                "webhooks",
                                [...(lcache.webhooks || [])].concat(webhook)
                            );
                        })
                        .catch(on_error);
                },
            },
            webhook_deleted: {
                event_keys: ["webhook_deleted"],
                cb: (webhookid, lset_at_path, lcache) => {
                    lset_at_path(
                        "webhooks",
                        [...(lcache.webhooks || [])].filter(
                            (webhook) => webhook.id !== webhookid
                        )
                    );
                },
            },
            webhook_updated: {
                event_keys: ["webhook_updated"],
                cb: (webhookid, lset_at_path, lcache, on_error) => {
                    axios
                        .get(`/webhooks/${webhookid}`)
                        .then(({ data: updated_webhook }) => {
                            lset_at_path(
                                "webhooks",
                                [...(lcache.webhooks || [])].map((webhook) => {
                                    if (webhook.id !== webhookid)
                                        return webhook;

                                    return {
                                        ...webhook,
                                        ...updated_webhook,
                                    };
                                })
                            );
                        })
                        .catch(on_error);
                },
            },
        },
        has_loaded: (load_markers) => load_markers[load_marker_key] === LOADED,
        load_data: ({ load_markers, set_load_marker, set_values_at_paths }) => {
            return new Promise((resolve, reject) => {
                if (!load_markers[load_marker_key]) {
                    set_load_marker(load_marker_key, LOADING);

                    Promise.all([
                        get_hub(),
                        get_groups(),
                        get_filters(),
                        get_statuses(),
                        get_webhooks(),
                    ])
                        .then(
                            ([
                                { data: loaded_hub },
                                { data: loaded_groups },
                                { data: loaded_filters },
                                { data: loaded_statuses },
                                { data: loaded_webhooks },
                            ]) => {
                                set_values_at_paths([
                                    {
                                        path: "hubs",
                                        value: (existing_hubs) =>
                                            merge_arr_by_key(existing_hubs, [
                                                loaded_hub,
                                            ]),
                                    },
                                    {
                                        path: "groups",
                                        value: (existing_groups) =>
                                            merge_arr_by_key(
                                                existing_groups,
                                                loaded_groups
                                            ),
                                    },
                                    {
                                        path: "filters",
                                        value: (existing_filters) =>
                                            merge_arr_by_key(
                                                existing_filters,
                                                loaded_filters
                                            ),
                                    },
                                    {
                                        path: "statuses",
                                        value: (existing_statuses) =>
                                            merge_arr_by_key(
                                                existing_statuses,
                                                loaded_statuses
                                            ),
                                    },
                                    {
                                        path: "webhooks",
                                        value: (existing_webhooks) =>
                                            merge_arr_by_key(
                                                existing_webhooks,
                                                loaded_webhooks
                                            ),
                                    },
                                ]);

                                set_load_marker(load_marker_key, LOADED);
                                resolve();
                            }
                        )
                        .catch(() => {
                            reject("There was an error loading your hub");
                        });
                } else {
                    resolve();
                }
            });
        },
        extract: (cache) => {
            const hub = (cache.hubs || []).find((hub) => hub.id === hubid);

            return {
                ...hub,
                groups: (cache.groups || []).filter(
                    (group) => group.hubid === hubid
                ),
                filters: (cache.filters || []).filter(
                    (filter) => filter.hubid === hubid
                ),
                statuses: (cache.statuses || []).filter(
                    (status) => status.hubid === hubid
                ),
                webhooks: (cache.webhooks || []).filter(
                    (webhook) => webhook.hubid === hubid
                ),
            };
        },
    };
};

const group = (groupid) => {
    const load_marker_key = `group_${groupid}`;

    const get_group = () => {
        return new Promise((resolve, reject) => {
            axios.get(`/groups/${groupid}`).then(resolve).catch(reject);
        });
    };

    const get_attributes = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(`/attributes?groupid=${groupid}`)
                .then(resolve)
                .catch(reject);
        });
    };

    return {
        listeners: {
            attribute_created: {
                event_keys: ["attribute_created"],
                cb: (attributeid, lset_at_path, lcache, on_error) => {
                    axios
                        .get(`/attributes/${attributeid}`)
                        .then(({ data: new_attribute }) => {
                            lset_at_path("attributes", [
                                ...(lcache.attributes || []),
                                new_attribute,
                            ]);
                        })
                        .catch(on_error);
                },
            },
            attribute_deleted: {
                event_keys: ["attribute_deleted"],
                cb: (attributeid, lset_at_path, lcache) => {
                    lset_at_path(
                        "attributes",
                        [...(lcache.attributes || [])].filter(
                            (attribute) => attribute.id !== attributeid
                        )
                    );
                },
            },
            attribute_updated: {
                event_keys: ["attribute_updated"],
                cb: (attributeid, lset_at_path, lcache, on_error) => {
                    axios
                        .get(`/attributes/${attributeid}`)
                        .then(({ data: updates }) => {
                            lset_at_path(
                                "attributes",
                                [...(lcache.attributes || [])].map(
                                    (attribute) => {
                                        if (attribute.id !== attributeid)
                                            return attribute;
                                        return {
                                            ...attribute,
                                            ...updates,
                                        };
                                    }
                                )
                            );
                        })
                        .catch(on_error);
                },
            },
            many_attributes_updated: {
                event_keys: ["many_attributes_updated"],
                cb: (updated_attribute_ids, lset_at_path, lcache, on_error) => {
                    if (updated_attribute_ids.length === 0) return;
                    const query = new URLSearchParams({
                        ids: updated_attribute_ids,
                    });
                    axios
                        .get(`/attributes/get_many?${query}`)
                        .then(({ data: updated_attributes }) => {
                            lset_at_path(
                                "attributes",
                                [...(lcache.attributes || [])].map(
                                    (attribute) => {
                                        if (
                                            !updated_attributes.some(
                                                (updated_attribute) =>
                                                    updated_attribute.id ===
                                                    attribute.id
                                            )
                                        )
                                            return attribute;
                                        return {
                                            ...attribute,
                                            ...updated_attributes.find(
                                                (updated_attribute) =>
                                                    updated_attribute.id ===
                                                    attribute.id
                                            ),
                                        };
                                    }
                                )
                            );
                        })
                        .catch(on_error);
                },
            },
        },
        has_loaded: (load_markers) => load_markers[load_marker_key] === LOADED,
        load_data: ({ load_markers, set_load_marker, set_values_at_paths }) => {
            return new Promise((resolve, reject) => {
                if (!load_markers[load_marker_key]) {
                    set_load_marker(load_marker_key, LOADING);

                    Promise.all([get_group(), get_attributes()])
                        .then(
                            ([
                                { data: loaded_group },
                                { data: loaded_attributes },
                            ]) => {
                                set_values_at_paths([
                                    {
                                        path: "groups",
                                        value: (existing_groups) =>
                                            merge_arr_by_key(existing_groups, [
                                                loaded_group,
                                            ]),
                                    },
                                    {
                                        path: "attributes",
                                        value: (existing_attributes) =>
                                            merge_arr_by_key(
                                                existing_attributes,
                                                loaded_attributes
                                            ),
                                    },
                                ]);
                                set_load_marker(load_marker_key, LOADED);
                                resolve();
                            }
                        )
                        .catch(() => {
                            reject("There was an error loading your group");
                        });
                } else {
                    resolve();
                }
            });
        },
        extract: (cache) => {
            const group = (cache.groups || []).find(
                (group) => group.id === groupid
            );

            return {
                ...group,
                attributes: (cache.attributes || []).filter(
                    (attribute) => attribute.groupid === groupid
                ),
            };
        },
    };
};

// The request parameters are hashed
// When we make a request, the tickets received are saved in the cache
// By hashing the request, we know that if the same request is made, we don't
// have to reload everything.
// Instead, we just use the tickets loaded with the hash, which are kept updated
// by the listener functions, and treat them as the single source of data.
// Cool, right?

const tickets = ({ hubid, search, filter, limit }) => {
    const request_hash = btoa(`${hubid}_${btoa(search)}_${filter}_${limit}`);

    return {
        // when a ticket is created, we have to check each query that we have hashed
        // if the ticket fits the hash, we have to add it
        // when a ticket is updated, we have to check each query that we have hashed
        // and check if it needs to be added or removed from any arrays of ticket ids
        listeners: {
            ticket_created: {
                event_keys: ["ticket_created"],
                cb: (
                    ticketid,
                    lset_at_path,
                    lcache,
                    on_error,
                    { set_values_at_paths }
                ) => {
                    axios
                        .get(`/tickets/${ticketid}`)
                        .then(async ({ data: ticket }) => {
                            const { queries } = lcache;

                            const to_add = [];

                            for (const request_hash in queries) {
                                const { data: valid } = await axios.post(
                                    "/tickets/verify",
                                    {
                                        ticketid: ticket.id,
                                        request_hash,
                                    }
                                );

                                if (valid) to_add.push(request_hash);
                            }

                            const { data: members } = await axios.post(
                                `/statuses/get_statuses_for_ticket`,
                                {
                                    ticket: ticket,
                                }
                            );

                            ticket.members = members;

                            set_values_at_paths([
                                {
                                    path: "tickets",
                                    value: (existing_tickets) =>
                                        (existing_tickets || []).concat(ticket),
                                },
                                {
                                    path: "queries",
                                    value: (existing_queries) => {
                                        const pseudo = { ...existing_queries };

                                        for (const h in pseudo) {
                                            if (to_add.includes(h)) {
                                                pseudo[h].ticketids.push(
                                                    ticket.id
                                                );
                                                pseudo[h].meta.total += 1;
                                            }
                                        }

                                        return pseudo;
                                    },
                                },
                            ]);
                        })
                        .catch(on_error);
                },
            },
            ticket_deleted: {
                event_keys: ["ticket_deleted"],
                cb: (
                    ticketid,
                    lset_at_path,
                    lcache,
                    on_error,
                    { set_values_at_paths }
                ) => {
                    set_values_at_paths([
                        {
                            path: "tickets",
                            value: (existing_tickets) =>
                                existing_tickets.filter(
                                    (ticket) => ticket.id !== ticketid
                                ),
                        },
                        {
                            path: "queries",
                            value: (existing_queries) => {
                                const pseudo = { ...existing_queries };

                                for (const h in pseudo) {
                                    if (pseudo[h].ticketids.includes(ticketid))
                                        pseudo[h].meta.total -= 1;
                                }

                                return pseudo;
                            },
                        },
                    ]);
                },
            },
            ticket_updated: {
                event_keys: ["ticket_updated"],
                cb: (
                    ticketid,
                    lset_at_path,
                    lcache,
                    on_error,
                    { set_values_at_paths }
                ) => {
                    const { queries } = lcache;

                    axios
                        .get(`/tickets/${ticketid}`)
                        .then(({ data: updates }) => {
                            axios
                                .post("/tickets/verify_many", {
                                    ticketid,
                                    hashes: Object.keys(queries),
                                })
                                .then(async ({ data: query_statuses }) => {
                                    const original_ticket =
                                        lcache.tickets?.find(
                                            (t) => t.id === ticketid
                                        ) || {};

                                    const { data: members } = await axios.post(
                                        "/statuses/get_statuses_for_ticket",
                                        {
                                            ticket: {
                                                ...original_ticket,
                                                ...updates,
                                            },
                                        }
                                    );

                                    updates.members = members;

                                    set_values_at_paths([
                                        {
                                            path: "tickets",
                                            value: (existing_tickets) =>
                                                existing_tickets.map(
                                                    (ticket) => {
                                                        if (
                                                            ticket.id !==
                                                            ticketid
                                                        )
                                                            return ticket;

                                                        return {
                                                            ...ticket,
                                                            ...updates,
                                                        };
                                                    }
                                                ),
                                        },
                                        {
                                            path: "queries",
                                            value: (existing_queries) => {
                                                const pseudo = {
                                                    ...existing_queries,
                                                };

                                                for (const h in pseudo) {
                                                    if (query_statuses[h]) {
                                                        if (
                                                            !pseudo[
                                                                h
                                                            ].ticketids.includes(
                                                                ticketid
                                                            )
                                                        ) {
                                                            pseudo[
                                                                h
                                                            ].meta.total += 1;
                                                        }

                                                        pseudo[
                                                            h
                                                        ].ticketids = pseudo[
                                                            h
                                                        ].ticketids.concat(
                                                            ticketid
                                                        );
                                                    } else {
                                                        if (
                                                            pseudo[
                                                                h
                                                            ].ticketids.includes(
                                                                ticketid
                                                            )
                                                        ) {
                                                            pseudo[
                                                                h
                                                            ].meta.total -= 1;
                                                        }

                                                        pseudo[
                                                            h
                                                        ].ticketids = pseudo[
                                                            h
                                                        ].ticketids.filter(
                                                            (t) =>
                                                                t !== ticketid
                                                        );
                                                    }
                                                }

                                                return pseudo;
                                            },
                                        },
                                    ]);
                                })
                                .catch(on_error);
                        })
                        .catch(on_error);
                },
            },
        },
        has_loaded: (load_markers) => load_markers[request_hash] === LOADED,
        load_data: ({
            load_markers,
            set_load_marker,
            set_values_at_paths,
            cache,
        }) => {
            return new Promise((resolve, reject) => {
                if (!load_markers[request_hash]) {
                    set_load_marker(request_hash, LOADING);

                    axios
                        .get(
                            `/tickets/ids_by_query?request_hash=${request_hash}`
                        )
                        .then(async ({ data: { ticketids, meta } }) => {
                            const needs_loaded = ticketids.filter(
                                (ticketid) =>
                                    !(cache.tickets || []).find(
                                        (ticket) => ticket.id === ticketid
                                    )
                            );

                            const paths = [
                                {
                                    path: `queries.${request_hash}.ticketids`,
                                    value: ticketids,
                                },
                                {
                                    path: `queries.${request_hash}.meta`,
                                    value: meta,
                                },
                            ];

                            if (needs_loaded.length !== 0) {
                                let { data: tickets } = await axios.post(
                                    `/tickets/get_many`,
                                    needs_loaded
                                );

                                const {
                                    data: status_members,
                                } = await axios.post(`/statuses/verify_many`, {
                                    hubid,
                                    tickets,
                                });

                                tickets = tickets.map((ticket) => {
                                    const members = [];
                                    for (const statusid in status_members) {
                                        if (
                                            status_members[statusid].includes(
                                                ticket.id
                                            )
                                        )
                                            members.push(statusid);
                                    }

                                    return {
                                        ...ticket,
                                        members,
                                    };
                                });

                                paths.push({
                                    path: "tickets",
                                    value: (existing_tickets) =>
                                        merge_arr_by_key(
                                            existing_tickets,
                                            tickets
                                        ),
                                });
                            }

                            set_values_at_paths(paths);

                            set_load_marker(request_hash, LOADED);
                            resolve();
                        })
                        .catch(() =>
                            reject("There was an error loading your tickets")
                        );
                } else {
                    resolve();
                }
            });
        },
        extract: (cache) => {
            return {
                ticketids: cache.tickets?.filter((ticket) =>
                    cache.queries?.[request_hash]?.ticketids?.includes(
                        ticket.id
                    )
                ),
                meta: cache.queries?.[request_hash]?.meta,
            };
        },
    };
};

const logs = ({ limit }) => {
    return {
        listeners: {
            log_created: {
                event_keys: ["log_created"],
                cb: (logid, lset_at_path, lcache, on_error) => {
                    axios
                        .get(`/logs/${logid}`)
                        .then(({ data: log }) => {
                            lset_at_path("logs", (existing_logs) => [
                                log,
                                ...existing_logs,
                            ]);
                        })
                        .catch(on_error);
                },
                ignore_if_sender: false,
            },
        },
        has_loaded: (load_markers) => typeof load_markers.logs === "number",
        load_data: ({
            cache,
            set_load_marker,
            load_markers,
            set_values_at_paths,
        }) => {
            return new Promise((resolve, reject) => {
                // super fucking complicated ben, probably needlessly verbose
                // but it works and i need to get this shipped already
                if (
                    load_markers.logs === LOADING ||
                    load_markers.logs >= limit ||
                    ((cache.logs_meta || {}).total < limit &&
                        load_markers.logs === (cache.logs_meta || {}).total)
                ) {
                    resolve();
                } else {
                    set_load_marker("logs", LOADING);
                    const params = new URLSearchParams({
                        start: (cache.logs || []).length,
                        end: limit,
                    });

                    axios
                        .get(`/logs?${params}`)
                        .then(({ data: { logs, meta } }) => {
                            set_values_at_paths([
                                {
                                    path: "logs",
                                    value: (existing_logs) =>
                                        (existing_logs || []).concat(logs),
                                },
                                {
                                    path: "logs_meta",
                                    value: meta,
                                },
                            ]);
                            set_load_marker(
                                "logs",
                                (cache.logs || []).length + logs.length
                            );
                            resolve();
                        })
                        .catch(() =>
                            reject("There was an error loading your logs")
                        );
                }
            });
        },
        extract: (cache) => {
            return { logs: cache.logs || [], meta: cache.logs_meta || {} };
        },
    };
};

const ticket_logs = (ticketid) => {
    const load_marker_key = `ticket_logs_${ticketid}`;

    return {
        listeners: {
            log_ticket_updated: {
                event_keys: ["ticket_updated"],
                cb: (ticketid, lset_at_path, lcache, on_error) => {
                    axios
                        .get(`/logs/ticket/${ticketid}`)
                        .then(({ data: logs }) => {
                            lset_at_path(
                                `ticket_logs.${ticketid}`,
                                (existing_logs) =>
                                    merge_arr_by_key(existing_logs, logs)
                            );
                        })
                        .catch(on_error);
                },
            },
        },
        has_loaded: (load_markers) => load_markers[load_marker_key] === LOADED,
        load_data: ({
            load_markers,
            set_load_marker,
            params: ticketid,
            set_at_path,
        }) => {
            return new Promise((resolve, reject) => {
                if (!load_markers[load_marker_key]) {
                    set_load_marker(load_marker_key, LOADING);
                    axios
                        .get(`/logs/ticket/${ticketid}`)
                        .then(({ data: logs }) => {
                            set_at_path(`ticket_logs.${ticketid}`, logs);
                            set_load_marker(load_marker_key, LOADED);
                            resolve();
                        })
                        .catch(() =>
                            reject("There was an error loading the ticket logs")
                        );
                } else {
                    resolve();
                }
            });
        },
        extract: (cache) => {
            return cache?.ticket_logs?.[ticketid] || [];
        },
    };
};

export default {
    users,
    hubs,
    hub,
    group,
    tickets,
    logs,
    ticket_logs,
};
