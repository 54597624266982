import React from "react";

const Waiter = (props) => {
    const [value, setValue] = React.useState(props.initialValue);
    const { func } = props;

    React.useEffect(() => {
        const init = async () => {
            setValue(await func());
        };

        init();
    }, [func]);

    return <span>{value}</span>;
};

export default Waiter;
