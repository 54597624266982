import React from "react";
import styles from "./date_group_list.module.css";
import DatePicker from "react-datepicker";
import { getList } from "../../../listStore";

const CurrentDateGroupList = (props) => {
    const formatter = getList("formatFunctions").date;

    return (
        <div className={styles.currWrap}>
            {formatter(props.value) || (
                <div
                    className="link"
                    onClick={() => {
                        props.onChange(new Date().getTime());
                    }}
                >
                    Set {props.attribute.name}
                </div>
            )}
            {props.value && (
                <div
                    className={"danger-link " + styles.clear}
                    onClick={() => {
                        props.onChange(undefined);
                    }}
                >
                    Clear
                </div>
            )}
        </div>
    );
};

const DateGroupList = (props) => {
    const [editing, setEditing] = React.useState(false);

    const dateRef = React.useRef();

    React.useEffect(() => {
        if (editing && dateRef.current) {
            dateRef.current.input.focus();
        }
    }, [editing]);

    if (props.attribute.datePreset === "curr")
        return <CurrentDateGroupList {...props} />;

    const getDateText = () => {
        const d = new Date(props.value);
        if (isNaN(d)) return false;

        return d.toLocaleDateString();
    };

    if (editing) {
        return (
            <DatePicker
                className="input no-box"
                showPopperArrow={false}
                todayButton="today"
                ref={dateRef}
                onClickOutside={() => setEditing(false)}
                onSelect={(d) => {
                    setEditing(false);
                    props.onChange(d.getTime());
                }}
                selected={(() => {
                    const d = new Date(props.value);

                    if (isNaN(d)) {
                        return undefined;
                    } else {
                        return d;
                    }
                })()}
            />
        );
    } else {
        return (
            <div
                className={styles.value}
                onClick={() => {
                    if (props.attribute.datePreset === "curr") {
                    } else setEditing(true);
                }}
            >
                {getDateText() || (
                    <span className="is-light">Nothing Here...</span>
                )}
            </div>
        );
    }
};

export default DateGroupList;
