import React from "react";
import styles from "./hubAuthenticator.module.css";
import { Redirect } from "react-router-dom";
import Logo from "../logo/logo";
import { useAlert } from "react-alert";
import { AuthContext } from "../../contexts";
import useConfig from "../../hooks/useConfig";

const HubAuthenticator = (props) => {
    const form_data = React.useRef({
        username: "",
        password: "",
    });
    const [isLoading, setIsLoading] = React.useState(false);
    const loginBTN = React.useRef();
    const inputsRef = React.useRef();

    const alert = useAlert();

    const { login, auth } = React.useContext(AuthContext);
    const { config, has_premium } = useConfig();

    React.useEffect(() => {
        if (auth !== -1) {
            window.localStorage.setItem("last_logged_in", auth.id);
        }
    }, [auth]);

    if (!config) return <div>Loading...</div>;

    const handleLogin = () => {
        const { username, password } = form_data.current;

        setIsLoading(true);
        login(username, password).catch((error) => {
            if (error.type === "no-user") {
                inputsRef.current.children[0].select();
            } else if (error.type === "wrong-password") {
                inputsRef.current.children[1].select();
            }
            loginBTN.current.classList.add(styles.fail);
            setTimeout(() => {
                if (loginBTN.current)
                    loginBTN.current.classList.remove(styles.fail);
            }, 1000);
            alert.error(error.message);
            setIsLoading(false);
        });
    };

    const handleEvent = (e) => {
        if (e.type === "keyup") {
            if (e.key === "Enter") {
                return handleLogin();
            }

            const infoKey = e.target.getAttribute("infokey");
            if (!infoKey) return;

            form_data.current[infoKey] = e.target.value.trim();

            return;
        }
    };

    if (auth !== -1) {
        let needsAuth = false;
        if (props.location.state?.needsAuth) {
            needsAuth = props.location.state.needsAuth;
        }
        let redirectLink;
        if (props.location.state && props.location.state.from) {
            redirectLink = props.location.state.from;
        }

        if (
            auth.id !== window.localStorage.getItem("last_logged_in") ||
            (needsAuth && auth.role !== "admin")
        ) {
            redirectLink = "/";
        }

        return <Redirect to={redirectLink || "/"} />;
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.floater}>
                <div>
                    <div className={styles.logo}>
                        <Logo />
                        <div className={styles.logoText}>{config.name}</div>
                        {!has_premium && (
                            <span className="tag is-link">Hubtrack Free</span>
                        )}
                    </div>
                </div>
                <div className={styles.inputs} ref={inputsRef}>
                    <input
                        type="text"
                        className="input"
                        placeholder="Username"
                        infokey="username"
                        autoFocus
                        onKeyUp={handleEvent}
                    />
                    <input
                        type="password"
                        className="input"
                        placeholder="Password"
                        infokey="password"
                        onKeyUp={handleEvent}
                    />
                </div>
                <button
                    className={"button " + styles.loginBTN}
                    style={{ width: "100%", color: "var(--logo-text-color)" }}
                    onClick={handleLogin}
                    ref={loginBTN}
                >
                    {isLoading ? (
                        <i className="fas fa-spin fa-spinner"></i>
                    ) : (
                        "Sign In"
                    )}
                </button>
            </div>
        </div>
    );
};

export default HubAuthenticator;
