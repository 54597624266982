import React from "react";
import axios from "axios";

const AuthContext = React.createContext();

const AuthContextProvider = (props) => {
    const [auth, set_auth] = React.useState(-1);

    const login = (username, password) => {
        return new Promise((resolve, reject) => {
            axios
                .post("/users/login", {
                    username,
                    password,
                })
                .then((response) => {
                    const jwt_token = response.data.jwt;

                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + jwt_token;

                    const user = response.data.user;

                    set_auth({
                        username: user.username,
                        role: user.role,
                        id: user.id,
                    });
                    resolve();

                    window.plausible("SignIn");
                })
                .catch((error) => {
                    const { status } = error.response;
                    const error_frontend = {
                        type: "",
                        message: "",
                    };

                    switch (status) {
                        case 404:
                            error_frontend.type = "no-user";
                            error_frontend.message =
                                "There is no user with that username";
                            break;

                        case 401:
                            error_frontend.type = "wrong-password";
                            error_frontend.message = "Incorrect password";
                            break;

                        default:
                            error_frontend.type = "unknown";
                            error_frontend.message =
                                "An unknown error occured. Please try again...";
                            break;
                    }

                    reject(error_frontend);
                });
        });
    };

    const logout = () => {
        set_auth(-1);
        axios.defaults.headers.common["Authorization"] = "";
    };

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;

export { AuthContextProvider };
