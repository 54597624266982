import React from "react";
import styles from "./status_settings.module.css";
// import Server from "../../Server";
import useConfig from "../../hooks/useConfig";
import StatusEditor from "../statuseditor/statuseditor";
import CacheContext from "../../contexts/CacheContext";

const all_colors = [
    "#e6194b",
    "#3cb44b",
    "#ffe119",
    "#4363d8",
    "#f58231",
    "#911eb4",
    "#46f0f0",
    "#f032e6",
    "#bcf60c",
    "#fabebe",
    "#008080",
    "#e6beff",
    "#9a6324",
    "#fffac8",
    "#800000",
    "#aaffc3",
    "#808000",
    "#ffd8b1",
    "#000075",
    "#808080",
];

const StatusSettings = (props) => {
    const { hubid } = props.match.params;
    const { has_premium, config } = useConfig();
    const { request, execute } = React.useContext(CacheContext);
    const [hub, set_hub] = React.useState(-1);
    const [active_status_id, set_active_status_id] = React.useState(-1);

    const { statuses, filters: all_filters } = hub;

    React.useEffect(() => {
        const { has_loaded, data } = request("hub", hubid);

        if (!has_loaded) return;

        set_hub(data);
    }, [request, hubid]);

    // const [business_info, set_business_info] = React.useState(-1);
    // React.useEffect(() => {
    //     return Server.source("businessInfo").subscribe((b) =>
    //         set_business_info({ ...b })
    //     );
    // }, []);

    React.useEffect(() => {
        if (!statuses || statuses.length === 0) return;

        const status = statuses.find(
            (status) => status.id === active_status_id
        );

        if (active_status_id === -1 || !status) {
            const first = statuses[0];
            if (first) {
                set_active_status_id(first.id);
            }
        }
    }, [active_status_id, statuses]);

    const update_status_value = (id, key, value) => {
        execute("update_status", {
            statusid: id,
            changes: {
                [key]: value,
            },
        });
    };

    const add_status = () => {
        execute("create_status", hubid).then((statusid) => {
            set_active_status_id(statusid);
        });

        // const key = all_statuses_source.push().getKey();

        // all_statuses_source
        //     .source(key)
        //     .set({
        //         name: "New Status",
        //         color:
        //             all_colors[Math.floor(Math.random() * all_colors.length)],
        //         reqs: {
        //             base: {
        //                 type: "group",
        //                 groupType: "and",
        //             },
        //         },
        //     })
        //     .then(() => set_active_status_id(key));
    };

    const delete_status = (id) => {
        execute("delete_status", id).then(() => {
            set_active_status_id(-1);
        });
    };

    const get_colors = (id) => {
        const status = statuses.find(
            (status) => status.id === active_status_id
        );
        return status
            ? {
                  backgroundColor: status.color,
                  color: window.isDark(status.color)
                      ? "#ffffff"
                      : "var(--dark-text-color)",
              }
            : {};
    };

    if (hub === -1) return <div>Loading...</div>;

    if (!has_premium)
        return (
            <div className="notPremium">
                <div>
                    <span
                        className="premium-badge"
                        style={{ marginRight: "8px" }}
                    >
                        <i className="fas fa-star"></i>
                    </span>
                    Statuses are a Hubtrack Premium Feature
                </div>
                <div>
                    With statuses, each ticket in your hub can have small tags
                    next to them, giving you the ability to quickly glean
                    important information that relates to your hub, and
                    efficiently select out tickets that need attention. Statuses
                    are available when you buy Hubtrack Premium. We promise,
                    you're gonna love it.
                </div>
                <a
                    href={"https://hubtrk.com/premium/?code=" + config.code}
                    className="button is-link"
                >
                    Get Hubtrack Premium
                </a>
            </div>
        );

    return (
        <div className={styles.wrap}>
            <div className={styles.statuses}>
                <div>
                    {statuses.map((status) => {
                        if (!status) return null;

                        return (
                            <div
                                key={status.id}
                                className={styles.status}
                                style={{
                                    ...(active_status_id === status.id
                                        ? get_colors(status.id)
                                        : {}),
                                }}
                                onClick={() => set_active_status_id(status.id)}
                            >
                                {status.name}
                                <div
                                    style={{
                                        height: "20px",
                                        width: "20px",
                                        borderRadius: "6px",
                                        backgroundColor: status.color,
                                        border:
                                            active_status_id === status.id
                                                ? "solid 3px " +
                                                  get_colors(status.id).color
                                                : "",
                                    }}
                                ></div>
                            </div>
                        );
                    })}
                    {statuses.length === 0 && (
                        <div
                            style={{
                                fontSize: "18px",
                                color: "var(--light-text-color)",
                                textAlign: "center",
                                padding: "40px 0px",
                            }}
                        >
                            No statuses yet
                        </div>
                    )}
                </div>
                <div className={styles.add_status} onClick={add_status}>
                    New Status
                </div>
            </div>
            <StatusEditor
                status={statuses.find(
                    (status) => status.id === active_status_id
                )}
                colors={get_colors(active_status_id)}
                all_colors={all_colors}
                all_filters={all_filters}
                hubid={hubid}
                update={(...args) =>
                    update_status_value(active_status_id, ...args)
                }
                delete={() => delete_status(active_status_id)}
            />
        </div>
    );
};

export default StatusSettings;
