import React from "react";
// import Server from "../../../../Server";
import Waiter from "../../../waiter/waiter";
import { transformTemplate } from "../../../../transformTemplate";
import styles from "./group_view_item.module.css";
import Modal from "../../../modal/modal";
import { getList } from "../../../../listStore";
import { useAlert } from "react-alert";
import CacheContext from "../../../../contexts/CacheContext";

const FullItemView = (props) => {
    const presentGroups = props.value.presentGroups;
    const { request } = React.useContext(CacheContext);
    const [attributes, setAttributes] = React.useState(-1);
    const types = getList("attributeTypes");

    // const { hubid } = props;
    React.useEffect(() => {
        if (!presentGroups) return;

        const a = [];
        for (const groupid of Object.values(presentGroups)) {
            const { has_loaded, data } = request("group", groupid);
            if (!has_loaded) return;

            a.push(...data.attributes);
        }

        setAttributes(a);
    }, [request, presentGroups]);

    // React.useEffect(() => {
    //     const run = async () => {
    //         let a = {};

    //         const groups = Server.source("hubs").source(hubid).source("groups");
    //         for (const group of Object.values(presentGroups)) {
    //             a = {
    //                 ...a,
    //                 ...(await groups.source(group).source("attributes").get()),
    //             };
    //         }

    //         setAttributes(a);
    //     };

    //     run();
    // }, [presentGroups, hubid]);

    if (attributes === -1) return <div>Loading...</div>;

    return (
        <div className={styles.full}>
            {attributes
                .sort((a, b) => a.index - b.index)
                .map((attribute) => {
                    const type = types[attribute.type];
                    const View = type.GroupListElement || (() => null);

                    return (
                        <div key={attribute.id} className={styles.valueWrap}>
                            <div
                                style={{
                                    fontSize: "14px",
                                    color: "var(--light-text-color)",
                                    marginBottom: "6px",
                                }}
                            >
                                {attribute.name}
                            </div>
                            <View
                                attribute={attribute}
                                value={props.value[attribute.id]}
                                onChange={(val) => {
                                    props.updateValue(val, attribute.id);
                                }}
                                hubid={props.hubid}
                                addGroup={(addid, attid) => {
                                    if (presentGroups[attid] === addid) return;
                                    if (
                                        presentGroups[attid] === undefined &&
                                        addid === ""
                                    )
                                        return;

                                    const pseudo = {
                                        ...presentGroups,
                                        [attid]: addid,
                                    };

                                    for (const key of Object.keys(pseudo)) {
                                        if (pseudo[key] === "")
                                            delete pseudo[key];
                                    }

                                    props.updateValue(pseudo, "presentGroups");
                                }}
                            />
                        </div>
                    );
                })}
        </div>
    );
};

const GroupViewItem = (props) => {
    const { request } = React.useContext(CacheContext);
    const [template, setTemplate] = React.useState(-1);
    const [viewingFull, setViewingFull] = React.useState(false);
    const [attributes, set_attributes] = React.useState(-1);
    const alert = useAlert();

    const { listGroup } = props.attribute;

    React.useEffect(() => {
        const group_req = request("group", listGroup);

        if (!group_req.has_loaded) return;

        set_attributes(group_req.data.attributes);
        setTemplate(group_req.data.preview);
    }, [request, listGroup]);

    if (template === -1 || attributes === -1) return <div>Loading...</div>;

    return (
        <div>
            <Modal
                isVisible={viewingFull}
                onClose={() => setViewingFull(false)}
            >
                <FullItemView
                    {...props}
                    updateValue={(val, key) => {
                        const pseudo = { ...props.value };
                        pseudo[key] = val;
                        props.updateValue(pseudo);
                    }}
                />
            </Modal>
            <div className={styles.wrap} onClick={() => setViewingFull(true)}>
                <Waiter
                    initialValue="Loading..."
                    func={() => {
                        return transformTemplate(
                            template,
                            props.value,
                            attributes
                        );
                    }}
                />
                <i
                    className={"fas fa-trash-alt danger-link " + styles.delete}
                    onClick={async (e) => {
                        e.stopPropagation();

                        const txt = await transformTemplate(
                            template,
                            props.value,
                            attributes
                        );

                        alert.show(
                            `Are you sure you would like to delete "${txt}"?`,
                            {
                                timeout: 0,
                                type: "confirm",
                                title: `Delete ${txt}`,
                                actions: [
                                    {
                                        text: "Delete",
                                        color: "var(--danger-text-color)",
                                        onClick: props.removeValue,
                                    },
                                ],
                            }
                        );
                    }}
                ></i>
            </div>
        </div>
    );
};

export default GroupViewItem;
