import React from "react";
import styles from "./dangerzone.module.css";

const DangerZone = (props) => {
    return (
        <div>
            <div
                style={{
                    fontSize: "24px",
                    fontWeight: "500",
                    color: "var(--danger-text-color)",
                }}
            >
                Danger Zone
            </div>
            <div style={{ marginTop: "10px" }}>
                {(props.groups || []).map((group, index) => {
                    return (
                        <div
                            className={styles.dangerGroup}
                            key={"danger-group-" + index}
                        >
                            <div>
                                <div style={{ fontWeight: "bold" }}>
                                    {group.title}
                                </div>
                                <div>{group.subtitle}</div>
                            </div>
                            <button
                                className="button is-danger"
                                onClick={group.onClick || (() => null)}
                            >
                                {group.title}
                            </button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DangerZone;
