import React from "react";
import Select from "../../../select/select";
import { withRouter } from "react-router-dom";
import CacheContext from "../../../../contexts/CacheContext";

const GroupItemSettings = (props) => {
    const { hubid } = props;
    const { request } = React.useContext(CacheContext);
    const [hub, set_hub] = React.useState(-1);

    React.useEffect(() => {
        const { has_loaded, data } = request("hub", hubid);

        if (!has_loaded) return;

        set_hub(data);
    }, [request, hubid]);

    if (hub === -1) return <div>Loading...</div>;

    const { groups } = hub;

    const groupsArr = groups
        .map((group) => {
            group.value = group.id;
            return group;
        })
        .filter((group) => group.permanent !== true)
        .filter((group) => group.value !== props.groupid);

    return (
        <div>
            {groupsArr.length !== 0 && (
                <Select
                    values={groupsArr}
                    value={groupsArr.find(
                        (group) => group.value === props.attribute.listGroup
                    )}
                    onSelect={(v) => {
                        props.set_value("listGroup", v.value, true);
                        // props.save_changes();
                        // props.adjustPseudo({ listGroup: v.value });
                    }}
                />
            )}
            {groupsArr.length === 0 && (
                <span style={{ color: "var(--light-text-color)" }}>
                    There are no valid groups that can be listed.{" "}
                    <span
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className="link"
                    >
                        Create one here.
                    </span>
                </span>
            )}
        </div>
    );
};

export default withRouter(GroupItemSettings);
