import React from "react";
import styles from "./statuseditor.module.css";
import { ChromePicker } from "react-color";
import { useAlert } from "react-alert";
import FilterEditor from "../filtereditor/filtereditor";
import CacheContext from "../../contexts/CacheContext";
import objectify from "../../objectify";

const StatusEditor = (props) => {
    const { request } = React.useContext(CacheContext);
    const { hubid } = props;
    const [temp_color, set_temp_color] = React.useState("#ffffff");
    const [all_groups, set_all_groups] = React.useState(-1);

    React.useEffect(() => {
        if (!props.status || !props.status.color) return;

        set_temp_color(props.status.color);
    }, [props.status]);

    const alert = useAlert();
    const name_ref = React.useRef();

    React.useEffect(() => {
        const hub_req = request("hub", hubid);

        if (!hub_req.has_loaded) return;

        const g = {};
        for (const group of hub_req.data.groups) {
            const { has_loaded, data } = request("group", group.id);

            if (!has_loaded) return;

            g[group.id] = { ...data, attributes: objectify(data.attributes) };
        }

        set_all_groups(g);
    }, [request, hubid]);

    React.useEffect(() => {
        if (name_ref.current) {
            name_ref.current.value = props.status.name;
            if (props.status.name === "New Status")
                return name_ref.current.select();
        }
    }, [props.status]);

    if (!props.status) {
        return (
            <div
                style={{
                    fontSize: "18px",
                    color: "var(--light-text-color)",
                    textAlign: "center",
                    padding: "50px 0px",
                }}
            >
                No status selected
            </div>
        );
    }

    if (all_groups === -1) return <div>Loading...</div>;

    return (
        <div>
            <div
                className={styles.color_picker}
                onClick={(e) => e.stopPropagation()}
            >
                <ChromePicker
                    onChangeComplete={(color) =>
                        props.update("color", color.hex)
                    }
                    onChange={(c) => set_temp_color(c)}
                    color={temp_color}
                    disableAlpha={true}
                />
            </div>
            <div className={styles.title_block} style={props.colors}>
                <div style={{ flexGrow: "1" }}>
                    <div
                        className={styles.input_label}
                        style={{ color: props.colors.color }}
                    >
                        STATUS
                    </div>
                    <input
                        type="text"
                        className={styles.name_input}
                        ref={name_ref}
                        onKeyUp={(e) => {
                            if (e.keyCode === 13) e.target.blur();
                        }}
                        onBlur={(e) => {
                            props.update("name", e.target.value);
                        }}
                    />
                </div>
                <div
                    className={styles.color_preview}
                    style={{ borderColor: props.colors.color }}
                    onClick={(e) => {
                        const picker = document.querySelector(
                            `.${styles.color_picker}`
                        );
                        const change_color_btn = e.currentTarget;
                        picker.classList.add(styles.active);
                        const rect = change_color_btn.getBoundingClientRect();
                        picker.style.top = `${rect.top + rect.height + 10}px`;
                        picker.style.left = `${rect.left - 115}px`;

                        window.addEventListener("click", (e2) => {
                            if (e2.target !== change_color_btn)
                                picker.classList.remove(styles.active);
                        });
                    }}
                >
                    Change Color
                </div>
                <div
                    style={{
                        borderColor: props.colors.color,
                        marginLeft: "12px",
                        display: "flex",
                        alignItems: "baseline",
                    }}
                    className={styles.delete_status}
                    onClick={() => {
                        alert.show(
                            `Are you sure you would like to delete the status "${props.status.name}"`,
                            {
                                timeout: 0,
                                type: "confirm",
                                title: `Delete ${props.status.name}`,
                                actions: [
                                    {
                                        text: "Delete",
                                        color: "var(--danger-text-color)",
                                        onClick: props.delete,
                                    },
                                ],
                            }
                        );
                    }}
                >
                    <i
                        className="fas fa-trash-alt"
                        style={{ marginRight: "8px" }}
                    ></i>
                    Delete Status
                </div>
            </div>
            <div style={{ padding: "0px 20px" }}>
                <div className={styles.input_label}>FILTER</div>
                <FilterEditor
                    onChange={(reqs) => {
                        props.update("reqs", [...reqs]);
                    }}
                    reqs={props.status.reqs}
                    filters={props.all_filters}
                    hubid={props.hubid}
                    groups={all_groups}
                    base_requirement_id={props.status.base_requirement}
                />
            </div>
        </div>
    );
};

export default StatusEditor;
