import React from "react";
import styles from "./ticketeditor.module.css";
import { getList } from "../../listStore";

const AttributeInput = (props) => {
    const attributes = getList("attributeTypes");
    let AttributeContent = () => null;

    AttributeContent =
        attributes[props.attribute.type].EditorElement || (() => null);

    return (
        <div style={{ marginBottom: "30px" }}>
            <div className={styles.attributeName}>{props.attribute.name}</div>
            <div>
                <AttributeContent {...props} />
            </div>
        </div>
    );
};

export default AttributeInput;
