const unset = require("unset-value");
const objectify = (arr, shouldDelete = false) => {
    if (!arr) return {};
    const pseudo = {};
    for (var i = 0; i < arr.length; i++) {
        const item = { ...arr[i] };
        const id = item.id;
        if (shouldDelete) unset(item, "id");
        pseudo[id] = item;
    }
    return pseudo;
};

export default objectify;
