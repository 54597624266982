import React from "react";
import styles from "./selectsettings.module.css";
import Select from "../../../select/select";
import Dropdown from "../../../dropdown/dropdown";
// import useHub from "../../../../hooks/useHub";
// import useGroup from "../../../../hooks/useGroup";
import axios from "axios";
import { useAlert } from "react-alert";
import CacheContext from "../../../../contexts/CacheContext";

const getAllPaths = (from, to, groups) => {
    const attributes = (groups[from] || {}).attributes || {};
    let next = [];

    for (const attribute of Object.values(attributes)) {
        if (attribute.type !== "select") continue;
        const options = attribute.options || {};

        for (const option of Object.values(options)) {
            if (!option.add) continue;
            next.push(option.add);
        }
    }

    next = next.reduce((arr, curr) => {
        if (arr.indexOf(curr) === -1) {
            arr.push(curr);
        }
        return arr;
    }, []);

    return next.map((n) => {
        let str = from + "." + getAllPaths(n, to, groups);
        return str;
    });
};

const checkValidPath = (from, to, groups) => {
    let paths = getAllPaths("base", to, groups).map((str) => str + from);

    for (const path of paths) {
        let fromIndex = path.indexOf(from);
        let toIndex = path.indexOf(to);

        if (fromIndex === -1 || toIndex === -1) continue;

        if (fromIndex > toIndex) return false;
    }

    return true;
};

const SelectOption = (props) => {
    return (
        <div className={styles.option}>
            <div style={{ flexGrow: "1" }}>
                <div className={styles.inputLabel}>Option Name</div>
                <input
                    type="text"
                    className="input"
                    defaultValue={props.option.name}
                    onBlur={(e) => {
                        props.adjustOption({ name: e.target.value });
                    }}
                />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                    <div className={styles.inputLabel}>Group To Add</div>
                    <Select
                        values={props.groups}
                        value={props.groups.find(
                            (group) => group.id === props.option.add
                        )}
                        onSelect={(v) => {
                            props.adjustOption({ add: v.id });
                        }}
                        wrapStyle={{ fontSize: "12px", padding: "7px" }}
                    />
                </div>
                <Dropdown
                    toggleStyle={{ fontSize: "12px", marginLeft: "12px" }}
                    direction="left"
                >
                    <Dropdown.Item onClick={props.deleteOption}>
                        <div className="danger-link">Delete Option</div>
                    </Dropdown.Item>
                </Dropdown>
            </div>
        </div>
    );
};

const SelectSettings = (props) => {
    const { groupid, hubid } = props;
    const { set_at_path, cache, request } = React.useContext(CacheContext);
    const alert = useAlert();
    const [hub, set_hub] = React.useState(-1);
    const [group, set_group] = React.useState(-1);

    // special case where you don't call a hook to access the cache, prevents double requests
    // MIGHT NOT BE NECESSARY ANYMORE BUT DON'T FIX WHAT ISN'T BROKEN AND IS UNDERSTANDABLE
    // let { groups } = useHub(props.hubid);
    // const { attributes } = useGroup(props.groupid);

    React.useEffect(() => {
        const hub_req = request("hub", hubid);

        if (!hub_req.has_loaded) return;

        set_hub(hub_req.data);

        const group_req = request("group", groupid);

        if (!group_req.has_loaded) return;

        set_group(group_req.data);
    }, [request, groupid, hubid]);

    let { groups } = hub;
    const { attributes } = group;

    if ([hub, group].includes(-1)) return <div>Loading...</div>;

    groups = groups
        .filter((group) => !group.permanent)
        .filter((group) => group.id !== groupid)
        .filter((group) => {
            if (!group.attributes) return true;

            return checkValidPath(groupid, group.id, groups);
        })
        .map((group) => {
            group.value = group.id;
            return group;
        });

    groups.unshift({
        name: "None",
        id: "",
        value: "",
    });

    const { options } =
        attributes.find((attribute) => attribute.id === props.attribute.id) ||
        {};

    const addOption = () => {
        axios
            .post(`/attributes/${props.attribute.id}/options`)
            .then(({ data: new_option }) => {
                set_at_path(
                    "attributes",
                    [...cache.attributes].map((attribute) => {
                        if (attribute.id !== props.attribute.id)
                            return attribute;

                        return {
                            ...attribute,
                            options: [...attribute.options, new_option],
                        };
                    })
                );
            })
            .catch(() => {
                alert.error("There was an error creating your option");
            });
    };

    const update_option = (optionid, changes) => {
        axios
            .patch(
                `/attributes/${props.attribute.id}/options/${optionid}`,
                changes
            )
            .then(({ data: updates }) => {
                set_at_path(
                    "attributes",
                    [...cache.attributes].map((attribute) => {
                        if (attribute.id !== props.attribute.id)
                            return attribute;

                        return {
                            ...attribute,
                            options: [...attribute.options].map((option) => {
                                if (option.id !== optionid) return option;

                                return {
                                    ...option,
                                    ...updates,
                                };
                            }),
                        };
                    })
                );
            })
            .catch(() =>
                alert.error("There was an error updating your option")
            );
    };

    const delete_option = (optionid) => {
        axios
            .delete(`/attributes/${props.attribute.id}/options/${optionid}`)
            .then(() => {
                set_at_path(
                    "attributes",
                    [...cache.attributes].map((attribute) => {
                        if (attribute.id !== props.attribute.id)
                            return attribute;

                        return {
                            ...attribute,
                            options: [...attribute.options].filter(
                                (option) => option.id !== optionid
                            ),
                        };
                    })
                );
            })
            .catch(() =>
                alert.error("There was an error deleting your option")
            );
    };

    return (
        <React.Fragment>
            <div className="input-label">Options</div>
            {options.map((option) => {
                return (
                    <SelectOption
                        key={option.id}
                        option={option}
                        groups={groups}
                        deleteOption={() => {
                            delete_option(option.id);
                        }}
                        adjustOption={(changes) => {
                            update_option(option.id, changes);
                        }}
                    />
                );
            })}
            <div className={styles.add} onClick={addOption}>
                New Option
            </div>
        </React.Fragment>
    );
};

export default SelectSettings;
