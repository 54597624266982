import React from "react";

const FieldItem = (props) => {
    const fieldRef = React.useRef();

    React.useEffect(() => {
        if (props.shouldFocus && fieldRef.current) {
            fieldRef.current.focus();
        }
    }, [props.shouldFocus]);

    let p = {
        ref: fieldRef,
        type: "text",
        className: "input",
        value: props.val || "",
        placeholder: "Enter " + props.attribute.name,
        onChange: (e) => props.setVal(e.target.value),
    };

    if (props.attribute.isLong) {
        return <textarea {...p} style={{ resize: "none" }} />;
    } else {
        return <input {...p} />;
    }
};

export default FieldItem;
