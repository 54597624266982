import React from "react";
import { getList } from "../../../listStore";
// import Server from "../../../Server";
import Modal from "../../modal/modal";
import GroupListEditor from "../../listeditorelements/grouplisteditor/grouplisteditor";
import styles from "./list.module.css";
import CacheContext from "../../../contexts/CacheContext";

const ListViewItem = (props) => {
    const { ticketid } = props;
    const { execute } = React.useContext(CacheContext);
    const type = getList("attributeTypes").group;
    const ViewElement = type.ViewElement || (() => null);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [newValues, setNewValues] = React.useState({});

    const updateValue = (val, index) => {
        const pseudo = { ...(props.value || {}) };
        pseudo[index] = val;
        execute("update_ticket", {
            ticketid,
            changes: {
                [props.attribute.id]: pseudo,
            },
        });
        // Server.source("tickets")
        //     .source(props.attribute.hubid)
        //     .source(props.attribute.ticketid)
        //     .source(props.attribute.id)
        //     .set(pseudo);
    };

    const removeValue = (index) => {
        const pseudo = Object.values({ ...(props.value || {}) });
        pseudo.splice(index, 1);
        execute("update_ticket", {
            ticketid,
            changes: {
                [props.attribute.id]: pseudo,
            },
        });
        // Server.source("tickets")
        //     .source(props.attribute.hubid)
        //     .source(props.attribute.ticketid)
        //     .source(props.attribute.id)
        //     .set(pseudo);
    };

    const addValue = () => {
        setNewValues({});
        setModalVisible(true);
    };

    const clearModal = () => {
        setModalVisible(false);
    };

    const saveNewValues = () => {
        const pseudo = Object.values({ ...(props.value || {}) });
        pseudo.push({
            ...newValues,
        });
        execute("update_ticket", {
            ticketid,
            changes: {
                [props.attribute.id]: pseudo,
            },
        }).then(clearModal);
    };

    return (
        <div>
            <Modal
                isVisible={modalVisible}
                onClose={() => setModalVisible(false)}
            >
                <div className={styles.modalWrap}>
                    <GroupListEditor
                        {...props}
                        hubid={props.attribute.hubid}
                        item={{ ...newValues }}
                        setVal={(changes) => {
                            setNewValues({ ...newValues, ...changes });
                        }}
                    />
                    <div className={styles.modalButtons}>
                        <div
                            className={styles.modalButton}
                            style={{ color: "var(--danger-text-color)" }}
                            onClick={clearModal}
                        >
                            Cancel
                        </div>
                        <div
                            className={styles.modalButton}
                            style={{ color: "var(--link-text-color)" }}
                            onClick={saveNewValues}
                        >
                            Add To {props.attribute?.name}
                        </div>
                    </div>
                </div>
            </Modal>
            {Object.values(props.value || {}).map((value, index) => {
                return (
                    <div
                        key={`${props.attribute.ticketid}-${props.attribute.id}-${index}`}
                        style={{ marginBottom: "15px" }}
                    >
                        <ViewElement
                            {...props}
                            hubid={props.hubid}
                            value={value}
                            updateValue={(val) => {
                                updateValue(val, index);
                            }}
                            removeValue={() => removeValue(index)}
                        />
                    </div>
                );
            })}
            <button className="button is-link is-small" onClick={addValue}>
                <i
                    className="fas fa-plus"
                    style={{ fontSize: "12px", marginRight: "4px" }}
                ></i>{" "}
                Add To {props.attribute.name}
            </button>
        </div>
    );
};

export default ListViewItem;
