import React from "react";
import styles from "./checkbox.module.css";

const Checkbox = (props) => {
    return (
        <div
            className={styles.wrap + " " + (props.disabled && styles.disabled)}
            onClick={() => {
                if (!props.disabled) props.onChange(!props.value);
            }}
        >
            <div
                className={
                    styles.box + " " + (props.value === true && styles.checked)
                }
            ></div>
            <div className={styles.name}>
                {props.name || (props.value === true ? "Yes" : "No")}
            </div>
        </div>
    );
};

export default Checkbox;
