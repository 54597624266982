import React from "react";
import DatePicker from "react-datepicker";
import styles from "./dateitem.module.css";
import "react-datepicker/dist/react-datepicker.css";

const NormalDateItem = (props) => {
    const pickerRef = React.useRef();

    React.useEffect(() => {
        if (props.shouldFocus && pickerRef.current)
            pickerRef.current.setFocus();
    }, [props.shouldFocus]);

    return (
        <DatePicker
            showPopperArrow={false}
            placeholderText={"Enter " + props.attribute.name}
            onChange={(date) => {
                if (date && !isNaN(date)) props.setVal(date.getTime());
            }}
            selected={props.val}
            todayButton="Today"
            ref={pickerRef}
            calendarClassName={styles.calendar}
            wrapperClassName={styles.wrapper}
            className="input"
        />
    );
};

const CurrentDateItem = (props) => {
    if (!props.val || props.val === "") props.setVal(new Date().getTime());

    return (
        <div className={styles.currWrap}>
            {new Date().toLocaleDateString()} <i>(Today)</i>
        </div>
    );
};

const DateItem = (props) => {
    if (props.attribute.datePreset === "none")
        return <NormalDateItem {...props} />;

    if (props.attribute.datePreset === "curr")
        return <CurrentDateItem {...props} />;
};

export default DateItem;
