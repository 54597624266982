import React from "react";
import useConfig from "../../hooks/useConfig";

const Logo = (props) => {
    const { config } = useConfig();

    React.useEffect(() => {
        if (!config) return;

        let { colors } = config;
        if (!colors)
            colors = {
                background: "#76e5ef",
                text: "#333333",
            };

        document.documentElement.style.setProperty(
            "--logo-color",
            colors.background
        );
        document.documentElement.style.setProperty(
            "--logo-text-color",
            colors.text
        );

        if (document.querySelector("link[rel=icon]")) {
            document
                .querySelector("link[rel=icon]")
                .setAttribute(
                    "href",
                    config.logo || process.env.PUBLIC_URL + "/logo.svg"
                );
        } else {
            const favicon = document.createElement("link");

            favicon.setAttribute("rel", "icon");
            favicon.setAttribute(
                "href",
                config.logo || process.env.PUBLIC_URL + "/logo.svg"
            );

            document.head.appendChild(favicon);
        }
    }, [config]);

    if (!config) return <i className="fas fa-spinner fa-spin"></i>;

    return (
        <img
            src={config.logo || process.env.PUBLIC_URL + "/logo.svg"}
            style={{ height: "100%", borderRadius: "2px" }}
            alt="logo"
        />
    );
};

export default Logo;
