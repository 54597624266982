import React from "react";
import styles from "./valuedropdown.module.css";
import Dropdown from "../dropdown/dropdown";

const ValueDropdown = (props) => {
    return (
        <Dropdown
            toggleClass={styles.value}
            hideDropper
            forceOpen={
                props.forceOpen ||
                (props.dontForceOpen !== true && props.value === undefined) //this code, its beautiful
            }
            toggleStyle={{
                backgroundColor: props.backgroundColor || "var(--logo-color)",
                color: props.textColor || "var(--logo-text-color)",
            }}
            baseText={
                props.baseText ||
                ((props.values || []).find((v) => v.id === props.value) || {})
                    .name ||
                "Select..."
            }
        >
            {(props.values || []).map((value) => {
                return (
                    <Dropdown.Item
                        key={value.id}
                        onClick={() => props.onSelect(value.id)}
                    >
                        {value.name}
                    </Dropdown.Item>
                );
            })}
        </Dropdown>
    );
};

export default ValueDropdown;
