import React from "react";
import styles from "./groupsettings.module.css";
import Breadcrumbs from "../breadcrumbs/breadcrumbs";
import { Switch, NavLink, Route, Redirect } from "react-router-dom";
import AttributeSettings from "../attributesettings/attributesettings";
import { useAlert } from "react-alert";
import DangerZone from "../dangerzone/dangerzone";
import CacheContext from "../../contexts/CacheContext";

const More = (props) => {
    const { hubid, groupid } = props.match.params;
    const { request, execute } = React.useContext(CacheContext);

    const [group, set_group] = React.useState(-1);

    const alert = useAlert();

    React.useEffect(() => {
        const { has_loaded, data } = request("group", groupid);

        if (!has_loaded) return;

        set_group(data);
    }, [request, groupid]);

    if (group === -1) return <div>Loading...</div>;

    return (
        <div>
            <div className="input-label">Group Name</div>
            <input
                className="input"
                defaultValue={group.name}
                onBlur={(e) => {
                    execute("update_group", {
                        groupid,
                        changes: {
                            name: e.target.value,
                        },
                    });
                }}
            />
            <div style={{ marginTop: "30px" }}>
                <DangerZone
                    groups={[
                        {
                            title: "Delete group",
                            subtitle: (
                                <span>
                                    Permanently delete the group{" "}
                                    <span
                                        style={{
                                            fontWeight: "500",
                                            color: "var(--dark-text-color)",
                                        }}
                                    >
                                        {group.name}
                                    </span>
                                </span>
                            ),
                            onClick: () => {
                                alert.show(
                                    `Are you sure you would like to delete "${group.name}"`,
                                    {
                                        timeout: 0,
                                        type: "confirm",
                                        title: `Delete ${group.name}`,
                                        actions: [
                                            {
                                                text: "Delete",
                                                color:
                                                    "var(--danger-text-color)",
                                                onClick: () => {
                                                    return new Promise(
                                                        (resolve) => {
                                                            execute(
                                                                "delete_group",
                                                                groupid
                                                            ).then(() => {
                                                                props.history.push(
                                                                    `/hubsettings/${hubid}`
                                                                );
                                                                resolve();
                                                            });
                                                        }
                                                    );
                                                },
                                            },
                                        ],
                                    }
                                );
                            },
                        },
                    ]}
                />
            </div>
        </div>
    );
};

const GroupSettings = (props) => {
    const { request } = React.useContext(CacheContext);
    const { hubid, groupid } = props.match.params;

    const [hub, set_hub] = React.useState(-1);
    const [group, set_group] = React.useState(-1);

    React.useEffect(() => {
        const hub_req = request("hub", hubid);

        if (!hub_req.has_loaded) return;

        set_hub(hub_req.data);

        const group_req = request("group", groupid);

        if (!group_req.has_loaded) return;

        set_group(group_req.data);
    }, [request, hubid, groupid]);

    // React.useEffect(() => {
    //     const { has_loaded, data } = request("hub", hubid);

    //     if (!has_loaded) return;

    //     set_hub(data);
    // }, [request, hubid]);

    // React.useEffect(() => {
    //     const { has_loaded, data } = request("group", groupid);

    //     if (!has_loaded) return;

    //     set_group(data);
    // }, [request, groupid]);

    if (group === -1 || hub === -1) return <div>Loading...</div>;

    return (
        <div className={styles.wrap}>
            <div>
                <Breadcrumbs
                    crumbs={[
                        {
                            name: "Hubs",
                            link: "/settings/hubs",
                        },
                        {
                            name: hub.name,
                        },
                        {
                            name: "Groups",
                            link: "/hubsettings/" + hubid + "/groups",
                        },
                        {
                            name: group.name,
                        },
                    ]}
                />
                <div className="divided-menu" style={{ marginTop: "20px" }}>
                    <NavLink
                        to={props.match.url + "/attributes"}
                        className="divided-menu-item delink"
                        activeClassName="active"
                    >
                        Attributes
                    </NavLink>
                    {!group.permanent && (
                        <NavLink
                            to={props.match.url + "/more"}
                            className="divided-menu-item delink"
                            activeClassName="active"
                        >
                            More
                        </NavLink>
                    )}
                </div>
            </div>
            <div
                style={{
                    overflowY: "hidden",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Switch>
                    {!group.permanent && (
                        <Route
                            path="/groupsettings/:hubid/:groupid/more"
                            render={(routerProps) => {
                                return <More {...routerProps} />;
                            }}
                        />
                    )}

                    <Route
                        path="/groupsettings/:hubid/:groupid/attributes"
                        component={AttributeSettings}
                    />
                    <Route
                        path="/groupsettings/:hubid/:groupid/"
                        exact
                        render={() => {
                            return (
                                <Redirect
                                    to={props.match.url + "/attributes"}
                                />
                            );
                        }}
                    />
                </Switch>
            </div>
        </div>
    );
};

export default GroupSettings;
