import React from "react";
import TicketEditor from "../ticketeditor/ticketeditor";
import CacheContext from "../../contexts/CacheContext";

const NewTicket = (props) => {
    const { hubid } = props.match.params;
    const { execute } = React.useContext(CacheContext);

    return (
        <TicketEditor
            title="New Ticket"
            finish="Create Ticket"
            onCancel={() => props.history.push("/" + hubid)}
            onFinish={(ticket) => {
                execute("create_ticket", {
                    ...ticket,
                    dateCreated: new Date().getTime(),
                    hubid,
                }).then((new_ticket) => {
                    window.plausible("Ticket Created");
                    props.history.push(`/${hubid}/${new_ticket.id}`);
                });
            }}
        />
    );
};

export default NewTicket;
