import React from "react";
import styles from "./date_view_item.module.css";
import { getList } from "../../../listStore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CacheContext from "../../../contexts/CacheContext";

const CurrentDateViewItem = (props) => {
    const { ticketid } = props;
    const { execute } = React.useContext(CacheContext);
    const formatter = getList("formatFunctions").date;

    return (
        <div className={styles.currWrap}>
            {formatter(props.value) || (
                <button
                    className="button is-link is-small"
                    onClick={() => {
                        execute("update_ticket", {
                            ticketid,
                            changes: {
                                [props.attribute.id]: new Date().getTime(),
                            },
                        });
                    }}
                >
                    Set {props.attribute.name}
                </button>
            )}
            {props.value && (
                <div
                    className={"danger-link " + styles.clear}
                    onClick={() => {
                        execute("update_ticket", {
                            ticketid,
                            changes: {
                                [props.attribute.id]: null,
                            },
                        });
                    }}
                >
                    Clear
                </div>
            )}
        </div>
    );
};

const DateViewItem = (props) => {
    const { ticketid } = props;
    const { execute } = React.useContext(CacheContext);
    const [isEditing, setIsEditing] = React.useState(false);
    const formatter = getList("formatFunctions").date;
    const dateRef = React.useRef();
    const clearLink = React.useRef();

    React.useEffect(() => {
        if (isEditing && dateRef.current) {
            dateRef.current.input.focus();
        }
    }, [isEditing]);

    if (props.attribute.datePreset === "curr")
        return <CurrentDateViewItem {...props} />;

    if (isEditing) {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <DatePicker
                    showPopperArrow={false}
                    ref={dateRef}
                    todayButton="Today"
                    onClickOutside={(e) => {
                        if (e.target === clearLink.current) return;
                        setIsEditing(false);
                    }}
                    onSelect={(d) => {
                        setIsEditing(false);

                        if (d.getTime() !== props.value)
                            execute("update_ticket", {
                                ticketid,
                                changes: {
                                    [props.attribute.id]: d.getTime(),
                                },
                            });
                    }}
                    selected={(() => {
                        const d = new Date(props.value);

                        if (props.value === null || isNaN(d.getTime())) {
                            return undefined;
                        } else {
                            return d;
                        }
                    })()}
                    className="input"
                />
                <div
                    className="danger-link"
                    style={{ fontSize: "16px", marginLeft: "12px" }}
                    onClick={() => {
                        setIsEditing(false);
                        execute("update_ticket", {
                            ticketid,
                            changes: {
                                [props.attribute.id]: null,
                            },
                        });
                    }}
                    ref={clearLink}
                >
                    Clear
                </div>
            </div>
        );
    } else {
        return (
            <div
                onClick={() => {
                    setIsEditing(true);
                }}
                style={{ cursor: "pointer" }}
            >
                {formatter(props.value) ||
                    (props.attribute.showInForm ? (
                        <span
                            style={{
                                color: "var(--light-text-color)",
                                fontWeight: "400",
                            }}
                        >
                            Nothing Here...
                        </span>
                    ) : (
                        <div>
                            <button className="button is-link is-small">
                                <i className="fas fa-calendar-plus"></i> Set{" "}
                                {props.attribute.name}
                            </button>
                        </div>
                    ))}
            </div>
        );
    }
};

export default DateViewItem;
