import React from "react";
import ReactDOM from "react-dom";
import Main from "./components/main/main";
import AlertTemplate from "./components/alertTemplate/alertTemplate";
import axios from "axios";
import { Provider as AlertProvider } from "react-alert";
import "./index.css";
import { CacheContextProvider, AuthContextProvider } from "./contexts";
import { sockets_connect } from "./sockets";

Object.filter = (obj, predicate) =>
    Object.keys(obj)
        .filter((key) => predicate(obj[key]))
        .reduce((res, key) => {
            res[key] = obj[key];
            return res;
        }, {});

window.isDark = (color) => {
    const reggie = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    if (!reggie.test(color)) {
        console.error("Invalid color: " + color);
        return;
    }

    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);

    const v = r * 0.299 + g * 0.587 + b * 0.114;

    return v <= 186;
};

window.randomID = () => Math.random().toString(36).substr(2, 9);

const is_production = process.env.NODE_ENV === "production";

if (is_production) {
    sockets_connect("https://hubtrk.com/");

    axios.defaults.baseURL = "https://hubtrk.com/server";
} else {
    const all_requests = [];
    sockets_connect("http://localhost:5633");

    axios.defaults.baseURL = "http://localhost:6969";

    axios.interceptors.request.use((config) => {
        config.headers["request-start-time"] = new Date().getTime();
        all_requests.push(config.url);
        return config;
    });

    axios.interceptors.response.use((response) => {
        const start_time = response.config.headers["request-start-time"];
        const now = Date.now();

        const duration = now - start_time;

        console.log(
            (all_requests.filter((u) => u === response.config.url).length > 1 &&
            response.config.method === "GET"
                ? "DUPLICATE "
                : "") +
                `${response.config.method} - ${response.config.url} took ${duration}ms`
        );

        return response;
    });
}

ReactDOM.render(
    <AlertProvider template={AlertTemplate} timeout={2500}>
        <CacheContextProvider>
            <AuthContextProvider>
                <Main />
            </AuthContextProvider>
        </CacheContextProvider>
    </AlertProvider>,
    document.getElementById("root")
);
