import React from "react";
import axios from "axios";
import { CacheContext } from "../contexts";
import { useAlert } from "react-alert";
import ColorThief from "colorthief";
import { join_room_using_code } from "../sockets";

// gonna leave this here for now, it seems to be working independently very well
// don't fix what isn't broken

const useConfig = () => {
    const { cache, set_at_path } = React.useContext(CacheContext);
    const alert = useAlert();

    const get_colors = (logo) => {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.src = logo;

            img.onload = () => {
                const ct = new ColorThief();

                let hex = "#";
                for (const c of ct.getColor(img)) {
                    let hexForm = c.toString(16);
                    if (hexForm.length === 1) hexForm = "0" + hexForm;
                    hex += hexForm;
                }

                resolve({
                    background: hex,
                    text: window.isDark(hex) ? "#f8f8f8" : "#333333",
                });
            };
        });
    };

    const set_logo = (logo_base64) => {
        get_colors(logo_base64)
            .then((colors) => {
                axios
                    .patch("/config", {
                        logo: logo_base64,
                        colors,
                    })
                    .then((response) => {
                        const clone = {
                            ...cache.config,
                            logo: response.data.logo,
                            colors: response.data.colors,
                        };
                        set_at_path("config", clone);
                    })
                    .catch((error) =>
                        alert.error("There was an error uploading your logo")
                    );
            })
            .catch((error) =>
                alert.error("There was an error adjusting the color scheme")
            );
    };

    const set_business_code = (code) => {
        axios
            .get("/api/v1/" + code)
            .then((response) => {
                set_at_path("config", { ...response.data });
                join_room_using_code(code);
                axios.defaults.baseURL =
                    axios.defaults.baseURL + "/api/v1/" + code;
                document.querySelector("title").innerHTML =
                    response.data.name + " | Hubtrack";
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 404) {
                        window.location =
                            "https://hubtrk.com/not-found/?id=" + code;
                    }
                }
            });
    };

    return {
        config: cache.config || undefined,
        has_premium: cache.config?.has_premium,
        set_logo,
        set_business_code,
    };
};

export default useConfig;
