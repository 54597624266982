import React from "react";
import styles from "./phone_group_list.module.css";
import { formatPhone } from "../../../helpers";

const PhoneGroupList = (props) => {
    const onEditingEnd = props.onEditingEnd;
    const [editing, setEditing] = React.useState(false);

    React.useEffect(() => {
        if (editing === false && onEditingEnd) onEditingEnd();
    }, [editing, onEditingEnd]);

    if (editing) {
        return (
            <input
                type="tel"
                className={"input " + (editing ? styles.editing : "no-box")}
                value={props.value || ""}
                onChange={(e) => {
                    if (isNaN(e.target.value)) return;
                    if (e.target.value.length > 10) return;

                    props.onChange(e.target.value);
                }}
                autoFocus
                onBlur={() => {
                    setEditing(false);
                    if (props.onBlur) props.onBlur();
                }}
                onFocus={(e) => e.currentTarget.select()}
                style={{
                    fontSize: "inherit",
                    resize: "none",
                    fontWeight: "inherit",
                    color: "var(--dark-text-color)",
                }}
            />
        );
    } else {
        return (
            <div className={styles.value} onClick={() => setEditing(true)}>
                {formatPhone(props.value) || (
                    <span className="is-light">Nothing Here...</span>
                )}
            </div>
        );
    }
};

export default PhoneGroupList;
