import React from "react";
import styles from "./ticketeditor.module.css";
import { withRouter } from "react-router-dom";
import AttributeInput from "./attributeInput";
import CacheContext from "../../contexts/CacheContext";

// const TicketEditor = (props) => {
//     const { hubid } = props.match.params;
//     const [hubName, setHubName] = React.useState("");
//     const [ticket, setTicket] = React.useState({ ...(props.defaults || {}) });
//     const [atts, setAtts] = React.useState([]);
//     const presentGroups = React.useRef({
//         base: {
//             groupid: "base",
//         },
//     });

//     React.useEffect(() => {
//         const onGroupos = (groupos) => {
//             setAtts([
//                 [
//                     ...arrayify(groupos.base.attributes).filter((att) => {
//                         return att.showInForm === true;
//                     }),
//                 ],
//             ]);
//         };

//         const onHubName = (name) => setHubName(name);

//         const groupSource = Server.source("hubs")
//             .source(hubid)
//             .source("groups");

//         const nameSource = Server.source("hubs").source(hubid).source("name");

//         nameSource.subscribe(onHubName);
//         groupSource.subscribe(onGroupos);

//         return () => {
//             nameSource.unsubscribe(onHubName);
//             groupSource.unsubscribe(onGroupos);
//         };
//     }, [hubid]);

//     const addAttributes = async (addID, attributeID) => {
//         /*
//             - must have property in ref called presentGroups with structure of {'id': {groupid: 'id'}}
//             - adds array of attributes to the index right after the adding group (this allows for select attributes to not have to come last, preventing awkward validation)
//             - attributes understand the index they have control over, and manipulate it when their value changes
//         */
//         // Server.source("hubs")
//         //     .source(hubid)
//         //     .source("groups")
//         //     .source(addID)
//         //     .source("attributes")
//         //     .get((toAdd) => {
//         //         let pseudo = [...atts];
//         //         const groupIndex = atts.findIndex((group) => {
//         //             return (
//         //                 group.findIndex((a) => {
//         //                     return a.id === attributeID;
//         //                 }) !== -1
//         //             );
//         //         });
//         //         const toRemove = arrayify(presentGroups.current)[
//         //             groupIndex + 1
//         //         ];
//         //         if (toRemove) {
//         //             delete presentGroups.current[toRemove.groupid];
//         //         }
//         //         pseudo = pseudo.slice(0, groupIndex + 1);
//         //         if (!pseudo[groupIndex + 1]) {
//         //             pseudo.splice(groupIndex + 1, 0, []);
//         //         }
//         //         pseudo[groupIndex + 1] = [...arrayify(toAdd)];
//         //         if (addID && addID !== "") {
//         //             presentGroups.current[addID] = {
//         //                 groupid: addID,
//         //             };
//         //         }
//         //         setAtts(pseudo);
//         //     });
//     };

//     return (
//         <div className={styles.wrap}>
//             <div className={styles.formWrap}>
//                 <div className={styles.content}>
//                     <div className="title" style={{ marginBottom: "30px" }}>
//                         {props.title} -
//                         <span style={{ color: "var(--link-text-color)" }}>
//                             {" "}
//                             {hubName}
//                         </span>
//                     </div>
//                     {atts
//                         .sort((a, b) => a.index - b.index)
//                         .map((group) => {
//                             return group.map((attribute, index) => {
//                                 return (
//                                     <AttributeInput
//                                         shouldFocus={index === 0}
//                                         key={attribute.id}
//                                         attribute={attribute}
//                                         val={ticket[attribute.id]}
//                                         setVal={(val) => {
//                                             setTicket({
//                                                 ...ticket,
//                                                 [attribute.id]: val,
//                                             });
//                                         }}
//                                         addAttributes={(addID) =>
//                                             addAttributes(addID, attribute.id)
//                                         }
//                                     />
//                                 );
//                             });
//                         })}
//                 </div>
//                 <div className={styles.bottom}>
//                     <div
//                         className={styles.bottomItem}
//                         style={{ color: "#ff6961" }}
//                         onClick={props.onCancel}
//                     >
//                         Cancel
//                     </div>
//                     <div
//                         className={styles.bottomItem}
//                         style={{ color: "var(--link-text-color)" }}
//                         onClick={() => {
//                             if (props.onFinish) {
//                                 props.onFinish({
//                                     ...ticket,
//                                     presentGroups: presentGroups.current,
//                                 });
//                             }
//                         }}
//                     >
//                         {props.finish}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

const TicketEditor = (props) => {
    const { hubid } = props.match.params;
    const { request } = React.useContext(CacheContext);
    const [baseAttributes, setBaseAttributes] = React.useState(-1);
    const [ticket, setTicket] = React.useState({ ...(props.defaults || {}) });

    // change base to hub.base_group
    const presentGroups = React.useRef({
        base: {},
    });

    React.useEffect(() => {
        const hub_req = request("hub", hubid);
        if (!hub_req.has_loaded) return;

        const group_req = request("group", hub_req.data.base_group);
        presentGroups.current.base.groupid = hub_req.data.base_group;

        if (!group_req.has_loaded) return;

        setBaseAttributes(group_req.data.attributes);
    }, [request, hubid]);

    // React.useEffect(() => {
    //     const onBase = (base) => {
    //         setBaseAttributes(base || {});
    //     };

    //     const baseSource = Server.source("hubs")
    //         .source(hubid)
    //         .source("groups")
    //         .source("base")
    //         .source("attributes");

    //     baseSource.subscribe(onBase);

    //     return () => {
    //         baseSource.unsubscribe(onBase);
    //     };
    // }, [hubid]);

    if (baseAttributes === -1) return <div>Loading...</div>;

    return (
        <div className={styles.wrap}>
            <div className={styles.formWrap}>
                <div className={styles.content}>
                    {baseAttributes
                        .filter((att) => att.showInForm === true)
                        .sort((a, b) => a.index - b.index)
                        .map((attribute, index) => {
                            return (
                                <AttributeInput
                                    shouldFocus={index === 0}
                                    key={attribute.id}
                                    attribute={attribute}
                                    val={ticket[attribute.id]}
                                    hubid={hubid}
                                    ticket={ticket}
                                    setTicketValue={(key, val) => {
                                        setTicket({
                                            ...ticket,
                                            [key]: val,
                                        });
                                    }}
                                    setVal={(val) => {
                                        setTicket({
                                            ...ticket,
                                            [attribute.id]: val,
                                        });
                                    }}
                                    setAddedGroup={(attribute, group) => {
                                        presentGroups.current[attribute] = {
                                            groupid: group,
                                        };
                                    }}
                                />
                            );
                        })}
                </div>
                <div className={styles.bottom}>
                    <div
                        className={styles.bottomItem}
                        style={{ color: "#ff6961" }}
                        onClick={props.onCancel}
                    >
                        Cancel
                    </div>
                    <div
                        className={styles.bottomItem}
                        style={{ color: "var(--link-text-color)" }}
                        onClick={() => {
                            if (props.onFinish) {
                                props.onFinish({
                                    ...ticket,
                                    presentGroups: Object.filter(
                                        presentGroups.current,
                                        (g) => g.groupid !== ""
                                    ),
                                });
                            }
                        }}
                    >
                        {props.finish}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(TicketEditor);
