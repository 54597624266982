import React from "react";
import styles from "./webhooksettings.module.css";
import Modal from "../modal/modal";
import Select from "../select/select";
import arrayify from "../../arrayify";
import _ from "lodash";
// import Server from "../../Server";
import CacheContext from "../../contexts/CacheContext";
import useConfig from "../../hooks/useConfig";

const event_key_lookup = {
    add_ticket: {
        name: "Ticket Created",
    },
    attribute_changed: {
        name: "Attribute Changed",
    },
    delete_ticket: {
        name: "Ticket Deleted",
    },
};

const WebhookSettings = (props) => {
    const { hubid } = props.match.params;
    const { request, execute } = React.useContext(CacheContext);
    const [active_webhook_id, set_active_webhook_id] = React.useState(-1);
    const { has_premium, config } = useConfig();

    const [hub, set_hub] = React.useState(-1);
    const { webhooks } = hub;

    const active_webhook = (webhooks || []).find(
        (webhook) => webhook.id === active_webhook_id
    );

    const urlref = React.useRef();

    React.useEffect(() => {
        const { has_loaded, data } = request("hub", hubid);

        if (!has_loaded) return;

        set_hub(data);
    }, [request, hubid]);

    React.useEffect(() => {
        if (active_webhook) {
            if (active_webhook.url !== urlref.current.value)
                urlref.current.value = active_webhook.url;
        }
    }, [active_webhook]);

    // this is just for the fuckers that try to use premium features without paying
    // fair enough excuse for me to have fun with it and not make it pretty.
    // React.useEffect(() => {
    //     if (!webhooks) return;
    //     if (webhooks.includes(undefined)) {
    //         set_webhooks(
    //             webhooks
    //                 .filter((webhook) => webhook)
    //                 .reduce((ob, webhook) => {
    //                     ob[webhook.id] = { ...webhook };
    //                     return ob;
    //                 }, {})
    //         );
    //     }
    // }, [webhooks]);

    // React.useEffect(() => {
    //     return Server.source("businessInfo").subscribe((b) =>
    //         set_business_info({ ...b })
    //     );
    // }, []);

    const add_webhook = () => {
        execute("create_webhook", hubid).then(set_active_webhook_id);
        // const new_webhook_id = Server.source("hubs")
        //     .source(hubid)
        //     .source("webhooks")
        //     .push({
        //         url: "",
        //         event_key: "add_ticket",
        //     })
        //     .getKey();

        // set_active_webhook(new_webhook_id);
    };

    const edit_webhook = (id, key, value) => {
        execute("update_webhook", {
            webhookid: id,
            changes: {
                [key]: value,
            },
        });
    };

    const delete_webhook = (id) => {
        set_active_webhook_id(-1);
        execute("delete_webhook", id);
    };

    const url_debouncer = _.debounce(
        (id, value) => edit_webhook(id, "url", value),
        500
    );

    if (hub === -1) return <div>Loading...</div>;

    // const webhook_being_edited = webhooks[active_webhook_id] || {};
    const num_webhooks = webhooks.length;

    if (!has_premium)
        return (
            <div className="notPremium">
                <div>
                    <span
                        className="premium-badge"
                        style={{ marginRight: "8px" }}
                    >
                        <i className="fas fa-star"></i>
                    </span>
                    Webhooks are a Hubtrack Premium Feature
                </div>
                <div>
                    With Webhooks, you have an open API that allows you to be
                    notified everytime a change occurs on your hub. This allows
                    everything from printing to live status boards, and is all
                    possible when you buy Hubtrack Premium. We promise, you're
                    gonna love it.
                </div>
                <a
                    href={"https://hubtrk.com/premium/?code=" + config.code}
                    className="button is-link"
                >
                    Get Hubtrack Premium
                </a>
            </div>
        );

    return (
        <div>
            <Modal
                isVisible={active_webhook !== undefined}
                onClose={() => set_active_webhook_id(-1)}
            >
                <div className={styles.modal_wrap}>
                    {active_webhook && (
                        <React.Fragment>
                            <div className="input-label">Event Type</div>
                            <Select
                                values={arrayify(event_key_lookup, "value")}
                                value={arrayify(event_key_lookup, "value").find(
                                    (hook) =>
                                        hook.value === active_webhook.event_key
                                )}
                                onSelect={(v) => {
                                    edit_webhook(
                                        active_webhook_id,
                                        "event_key",
                                        v.value
                                    );
                                }}
                                wrapStyle={{ maxWidth: "none" }}
                            />
                            <div className="input-label">URL</div>
                            <input
                                type="text"
                                className="input"
                                ref={urlref}
                                onChange={(e) =>
                                    url_debouncer(
                                        active_webhook_id,
                                        e.target.value
                                    )
                                }
                            />
                            <div
                                className="danger-link"
                                onClick={() =>
                                    delete_webhook(active_webhook_id)
                                }
                                style={{ marginTop: "30px" }}
                            >
                                <i
                                    className="fas fa-trash-alt"
                                    style={{
                                        marginRight: "6px",
                                        fontSize: "14px",
                                    }}
                                ></i>
                                Delete Webhook
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </Modal>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <div style={{ fontSize: "24px", fontWeight: "600" }}>
                    {num_webhooks} Active Webhook{num_webhooks !== 1 && "s"}
                </div>
                <button className="button" onClick={add_webhook}>
                    <i
                        className="fas fa-plus"
                        style={{ marginRight: "6px" }}
                    ></i>{" "}
                    New Webhook
                </button>
            </div>
            <div style={{ marginTop: "20px" }}>
                {webhooks.map((webhook) => {
                    if (!webhook) return null;

                    return (
                        <div
                            key={webhook.id}
                            className={styles.webhook}
                            onClick={() => set_active_webhook_id(webhook.id)}
                        >
                            <span className={styles.event_type}>
                                {event_key_lookup[webhook.event_key].name}
                            </span>{" "}
                            <span className="link" style={{ fontSize: "18px" }}>
                                {webhook.url}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default WebhookSettings;
