import React from "react";
import styles from "./dropdown.module.css";

const Dropdown = (props) => {
    const [isActive, setIsActive] = React.useState(false);
    const [selected, setSelected] = React.useState(-1);
    const clickHandlers = React.useRef({});

    React.useEffect(() => {
        const hide = () => setIsActive(false);

        if (isActive) window.addEventListener("click", hide);

        return () => {
            window.removeEventListener("click", hide);
        };
    }, [isActive]);

    const { children } = props;

    React.useEffect(() => {
        const handleKey = (e) => {
            const reactChildren = React.Children.toArray(children);

            const preventers = [40, 38, 13];
            if (preventers.includes(e.keyCode)) {
                e.preventDefault();
            }

            if (e.keyCode === 40) {
                setSelected(
                    selected === reactChildren.length - 1
                        ? selected
                        : selected + 1
                );
            } else if (e.keyCode === 38) {
                setSelected(selected === 0 ? selected : selected - 1);
            } else if (e.keyCode === 13) {
                if (clickHandlers.current[selected])
                    clickHandlers.current[selected]();
            }
        };

        if (isActive || props.forceOpen || props.hideToggler) {
            window.addEventListener("keydown", handleKey);
        }

        return () => {
            window.removeEventListener("keydown", handleKey);
        };
    }, [isActive, props.forceOpen, props.hideToggler, selected, children]);

    const additionalMenuStyle = (() => {
        const additional = {};

        if (!props.direction || props.direction === "right") {
            additional.left = "0px";
        } else if (props.direction === "left") {
            additional.right = "0px";
        }

        additional.display = isActive || props.forceOpen ? "block" : "none";

        return additional;
    })();

    return (
        <div className={styles.wrap}>
            {props.hideToggler !== true && (
                <div
                    className={
                        styles.toggler +
                        " " +
                        ((isActive || props.forceOpen || props.hideToggler) &&
                            styles.active) +
                        " " +
                        props.toggleClass
                    }
                    style={props.toggleStyle}
                    onClick={() => setIsActive(!isActive)}
                >
                    {props.baseText || "More"}
                    {props.hideDropper !== true && (
                        <i
                            className="fas fa-chevron-down"
                            style={{ fontSize: "inherit" }}
                        ></i>
                    )}
                </div>
            )}

            <div
                className={
                    styles.menu +
                    " " +
                    ((isActive || props.forceOpen || props.hideToggler) &&
                        styles.active)
                }
                style={additionalMenuStyle}
            >
                {React.Children.toArray(props.children).map((child, index) => {
                    clickHandlers.current[index] = child.props.onClick;
                    return React.cloneElement(child, {
                        selected: selected === index,
                        adjustClickHandler: (func) => {
                            clickHandlers.current[index] = func;
                        },
                    });
                })}
            </div>
        </div>
    );
};

const DropdownItem = (props) => {
    const clickHandler = props.onClick || (() => {});
    const specialStyles = props.style || {};

    const { adjustClickHandler } = props;
    React.useEffect(() => {
        adjustClickHandler(clickHandler);
    }, [clickHandler, adjustClickHandler]);

    return (
        <div
            className={
                styles.menuItem + " " + (props.selected && styles.selected)
            }
            onClick={clickHandler}
            style={specialStyles}
        >
            {props.children}
        </div>
    );
};

Dropdown.Item = DropdownItem;

export default Dropdown;
