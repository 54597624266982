import React from "react";
import styles from "./modal.module.css";

const Modal = props => {
    const { onClose } = props;

    React.useEffect(() => {
        const onKeyUp = e => {
            if (e.key === "Escape") {
                onClose();
            }
        };

        window.addEventListener("keyup", onKeyUp);

        return () => {
            window.removeEventListener("keyup", onKeyUp);
        };
    }, [onClose]);

    return (
        <div
            className={
                styles.wrap + " " + (props.isVisible ? styles.active : "")
            }
            id="modalbg"
            onClick={e => {
                if (e.target.getAttribute("id") === "modalbg") onClose();
            }}
        >
            <div className={styles.modalContent}>{props.children}</div>
        </div>
    );
};

export default Modal;
