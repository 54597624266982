import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { AuthContext, CacheContext } from '../../contexts';
import GroupSettings from '../groupsettings/groupsettings';
import Home from '../home/home';
import Hub from '../hub/hub';
import HubSettings from '../hubsettings/hubsettings';
import HubAuthenticator from '../hub_authenticator/hub_authenticator';
import Nav from '../nav/nav';
import NewTicket from '../new_ticket/new_ticket';
import SaveStatusIndicator from '../savestatusindicator/savestatusindicator';
import Settings from '../settings/settings';
import styles from './main.module.css';
import useConfig from '../../hooks/useConfig';
import Axios from 'axios';

const ProtectedRoute = (props) => {
    const { auth } = React.useContext(AuthContext);

    if (auth !== -1 && (!props.needsAuth || auth.role === 'admin')) {
        return <Route {...props} />;
    } else {
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: {
                        from: props.location.pathname,
                        needsAuth: props.needsAuth,
                    },
                }}
            />
        );
    }
};

const Main = (props) => {
    const { config, set_business_code } = useConfig();
    const { set_at_path } = React.useContext(CacheContext);

    // 0 unloaded, 1 loading, 2 loaded, 3 error
    const [usernames_load_status, set_usernames_load_status] = React.useState(0);

    React.useEffect(() => {
        if (config) return;
        const { hostname } = window.location;

        const business_code = hostname.split('.')[0];

        set_business_code(business_code);
    }, [set_business_code, config]);

    React.useEffect(() => {
        if (config === -1 || !config || usernames_load_status !== 0) return;

        set_usernames_load_status(1);

        Axios.get('/users/userInfoById').then((res) => {
            const idToUsernameMap = res.data;

            set_at_path('usernames', idToUsernameMap);

            set_usernames_load_status(2);
        }).catch(() => {
            set_usernames_load_status(3);
        });
    }, [usernames_load_status, config]);

    if (config === -1 || usernames_load_status < 2) {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '50px 0px',
                    fontSize: '35px',
                    fontWeight: '600',
                    height: '100vh',
                    color: 'var(--link-text-color)',
                }}
            >
                <i className='fas fa-spinner fa-spin'></i>
            </div>
        );
    }

    if (usernames_load_status === 3) {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '50px 0px',
                    fontSize: '35px',
                    fontWeight: '600',
                    height: '100vh',
                }}
            >
                Error loading usernames :(
            </div>
        );
    }

    return (
        <Router>
            <Nav />
            <SaveStatusIndicator />
            <div className={styles.wrap}>
                <Switch>
                    <ProtectedRoute exact path='/' component={Home} />
                    <Route exact path='/login' component={HubAuthenticator} />
                    <ProtectedRoute needsAuth path='/settings' component={Settings} />
                    <ProtectedRoute needsAuth path='/hubsettings/:hubid' component={HubSettings} />
                    <ProtectedRoute
                        needsAuth
                        path='/groupsettings/:hubid/:groupid'
                        component={GroupSettings}
                    />
                    <ProtectedRoute path='/:hubid/new' component={NewTicket} />
                    <ProtectedRoute path='/:hubid' component={Hub} />
                </Switch>
            </div>
        </Router>
    );
};

export default Main;
