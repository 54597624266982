import React from "react";
import Select from "../../../select/select";
import { getList } from "../../../../listStore";
import arrayify from "../../../../arrayify";

const presets = getList("presetDateValues");

const DateSettings = (props) => {
    let preset = props.attribute.datePreset;

    return (
        <React.Fragment>
            <div className="input-label">Preset Date</div>
            <Select
                values={arrayify(presets, "value")}
                value={arrayify(presets, "value").find(
                    (p) => p.value === preset
                )}
                onSelect={(v) => {
                    props.set_value("datePreset", v.value, true);
                    // props.adjustPseudo({
                    //     datePreset: v.value,
                    // });
                }}
            />
        </React.Fragment>
    );
};

export default DateSettings;
