import React from "react";
import styles from "./alertTemplate.module.css";

const icons = {
    success: "fas fa-check",
    error: "fas fa-exclamation-triangle",
    info: "fas fa-info",
};

const AlertTemplate = ({ id, style, close, message, options }) => {
    const [promptValue, setPromptValue] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    if (options.type === "confirm") {
        return (
            <div className={styles.wrap}>
                <div className={styles.content}>
                    {options.title && (
                        <div className={styles.confirmTitle}>
                            {options.title}
                        </div>
                    )}
                    <div className={styles.confirmMessage}>{message}</div>
                    <div className={styles.confirmActions}>
                        <div
                            style={{
                                color: "var(--link-text-color)",
                                marginRight:
                                    (options.actions || []).length > 1
                                        ? "30px"
                                        : "10px",
                            }}
                            className={styles.confirmAction}
                            onClick={close}
                        >
                            Cancel
                        </div>
                        {(options.actions || []).map((action, index) => {
                            return (
                                <div
                                    style={{
                                        color: action.color || "",
                                    }}
                                    className={styles.confirmAction}
                                    key={"action-" + index}
                                    onClick={() => {
                                        if (action.onClick) action.onClick();
                                        close();
                                    }}
                                >
                                    {action.text}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

    if (options.type === "prompt") {
        const onFinish = () => {
            if (options.onValue) {
                setLoading(true);

                Promise.resolve(options.onValue(promptValue)).then(() => {
                    setLoading(true);
                    close();
                });
            } else {
                close();
            }
        };

        return (
            <div className={styles.wrap}>
                <div
                    className={
                        styles.content + " " + (loading && styles.loading)
                    }
                    style={{ maxWidth: "600px", width: "100%" }}
                >
                    <div className={styles.promptMessage}>{message}</div>
                    <input
                        type="text"
                        className="input"
                        autoFocus
                        value={promptValue}
                        disabled={loading}
                        onChange={(e) => setPromptValue(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                onFinish();
                            }
                        }}
                    />
                    <div
                        className={
                            styles.promptActions +
                            " " +
                            (loading && styles.loading)
                        }
                    >
                        <div
                            style={{ color: "var(--danger-text-color)" }}
                            onClick={close}
                            className={styles.promptAction}
                        >
                            Cancel
                        </div>
                        <div
                            onClick={onFinish}
                            className={styles.promptAction}
                            style={{ color: "var(--link-text-color)" }}
                        >
                            {loading && (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <div>
                                        {options.loadingText || "Loading..."}
                                    </div>
                                    <i
                                        className="fas fa-spinner fa-spin"
                                        style={{ marginLeft: "8px" }}
                                    ></i>
                                </div>
                            )}

                            {!loading && (options.finishText || "Enter")}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            style={style}
            className={styles.regularWrap + " " + styles[options.type]}
        >
            <i
                className={icons[options.type]}
                style={{ marginRight: "10px" }}
            ></i>
            {message}
        </div>
    );
};

export default AlertTemplate;
