import React from "react";
import styles from "./text_group_list.module.css";

const TextGroupList = (props) => {
    const [editing, setEditing] = React.useState(false);

    if (editing) {
        const p = {
            className: "input " + (editing ? styles.editing : "no-box"),
            value: props.value || "",
            style: { color: "var(--dark-text-color)", resize: "none" },
            onChange: (e) => props.onChange(e.target.value),
            onFocus: (e) => e.currentTarget.select(),
            autoFocus: true,
            onBlur: () => {
                setEditing(false);
                // props.onChange(pseudo);
                if (props.onBlur) props.onBlur();
            },
        };

        return props.attribute.isLong ? <textarea {...p} /> : <input {...p} />;
    } else {
        return (
            <div className={styles.value} onClick={() => setEditing(true)}>
                {(typeof props.value === "string" && props.value) || (
                    <span className="is-light">Nothing Here...</span>
                )}
            </div>
        );
    }
};

export default TextGroupList;
