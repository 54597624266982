import React from "react";
import styles from "./grouplisteditor.module.css";
import { getList } from "../../../listStore";
import CacheContext from "../../../contexts/CacheContext";

const GroupListEditor = (props) => {
    const types = getList("attributeTypes");
    const { request } = React.useContext(CacheContext);
    const [attributes, setAttributes] = React.useState(-1);
    const [groups, setGroups] = React.useState({
        base: props.attribute?.listGroup,
    });

    const updatePresentGroups = React.useCallback(() => {
        const removeEmptyFromGroups = (g) => {
            for (const n in g) {
                if (g[n] === "") delete g[n];
            }
            return g;
        };

        for (const key of Object.keys(groups)) {
            if (
                !props.item.presentGroups ||
                groups[key] !== props.item.presentGroups[key]
            ) {
                return props.setVal({
                    presentGroups: { ...removeEmptyFromGroups(groups) },
                });
            }
        }
    }, [groups, props]);

    const { hubid } = props;

    React.useEffect(() => {
        const run = () => {
            if (!groups) return;

            const p = [];
            for (const group in groups) {
                const { has_loaded, data } = request("group", groups[group]);

                if (!has_loaded) return;

                p.push(...data.attributes);
            }

            setAttributes(p);
        };

        updatePresentGroups();

        run();
    }, [groups, request, updatePresentGroups]);
    // React.useEffect(() => {
    //     const run = async () => {
    //         let atts = {};

    //         for (const group of Object.values(groups)) {
    //             const newAtts = await Server.source("hubs")
    //                 .source(hubid)
    //                 .source("groups")
    //                 .source(group)
    //                 .source("attributes")
    //                 .get();

    //             atts = {
    //                 ...atts,
    //                 ...newAtts,
    //             };
    //         }

    //         setAttributes({ ...atts });
    //     };

    //     updatePresentGroups();

    //     run();
    // }, [hubid, groups, updatePresentGroups]);

    if (attributes === -1) return <div>Loading...</div>;

    return (
        <div className={styles.wrap}>
            {attributes
                .filter((attribute) => attribute.showInForm !== false)
                .sort((a, b) => {
                    return a.index - b.index;
                })
                .map((attribute, index) => {
                    const EditorEl =
                        types[attribute.type].EditorElement || (() => null);

                    return (
                        <div
                            key={attribute.id + "-" + props.index}
                            style={{
                                marginBottom:
                                    index === attributes.length - 1
                                        ? "0px"
                                        : "20px",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "14px",
                                    marginBottom: "6px",
                                    color: "var(--light-text-color)",
                                }}
                            >
                                {attribute.name}
                            </div>
                            <EditorEl
                                setVal={(v) => {
                                    props.setVal({
                                        [attribute.id]: v,
                                    });
                                }}
                                setAddedGroup={(attributeid, groupid) => {
                                    setGroups({
                                        ...groups,
                                        [attributeid]: groupid,
                                    });
                                }}
                                val={props.item[attribute.id]}
                                attribute={attribute}
                                hubid={hubid}
                            />
                        </div>
                    );
                })}
        </div>
    );
};

export default GroupListEditor;
