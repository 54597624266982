import { formatPhone } from "./helpers";
import TextViewItem from "./components/ticketview/items/text_view_item";
import PhoneViewItem from "./components/ticketview/items/phone_view_item";
import DateViewItem from "./components/ticketview/items/date_view_item";
import SelectViewItem from "./components/ticketview/items/select_view_item";
import FieldItem from "./components/ticketeditor/items/fielditem";
import PhoneItem from "./components/ticketeditor/items/phoneitem";
import SelectItem from "./components/ticketeditor/items/selectitem";
import DateItem from "./components/ticketeditor/items/dateitem";
import ListItem from "./components/ticketeditor/items/listitem";
import ListViewItem from "./components/ticketview/items/list_view_item";
import NumberViewItem from "./components/ticketview/items/number_view_item";
import NumberItem from "./components/ticketeditor/items/numberitem";
import GroupItemSettings from "./components/attributesettings/items/groupitemsettings/groupitemsettings";
import GroupListEditor from "./components/listeditorelements/grouplisteditor/grouplisteditor";
import GroupViewItem from "./components/ticketview/items/group_view_item/group_view_item";
import TextGroupList from "./components/groupListViewElements/text_group_list/text_group_list";
import NumberGroupList from "./components/groupListViewElements/number_group_list/number_group_list";
import SelectGroupList from "./components/groupListViewElements/select_group_list/select_group_list";
import PhoneGroupList from "./components/groupListViewElements/phone_group_list/phone_group_list";
import DateGroupList from "./components/groupListViewElements/date_group_list/date_group_list";
import ListGroupList from "./components/groupListViewElements/list_group_list copy/list_group_list";

const attributeTypes = {
    text: {
        name: "Text",
        defaults: {
            isLong: false,
        },
        ViewElement: TextViewItem,
        EditorElement: FieldItem,
        GroupListElement: TextGroupList,
    },
    number: {
        name: "Number",
        defaults: {},
        ViewElement: NumberViewItem,
        EditorElement: NumberItem,
        GroupListElement: NumberGroupList,
    },
    phone: {
        name: "Phone",
        defaults: {},
        ViewElement: PhoneViewItem,
        EditorElement: PhoneItem,
        GroupListElement: PhoneGroupList,
    },
    select: {
        name: "Select",
        defaults: {
            options: {},
        },
        ViewElement: SelectViewItem,
        EditorElement: SelectItem,
        GroupListElement: SelectGroupList,
    },
    date: {
        name: "Date",
        defaults: {
            datePreset: "none",
        },
        ViewElement: DateViewItem,
        EditorElement: DateItem,
        GroupListElement: DateGroupList,
    },
    list: {
        name: "List",
        defaults: {
            itemType: "text",
        },
        itemDefault: [],
        ViewElement: ListViewItem,
        EditorElement: ListItem,
        ListEditorElement: GroupListEditor,
        GroupListElement: ListGroupList,
    },
    group: {
        name: "Group",
        itemDefault: {},
        hidden: true, //this is not a normal value, it's only here to serve the list type
        SettingsEl: GroupItemSettings,
        ListEditorElement: GroupListEditor,
        ViewElement: GroupViewItem,
    },
};

const attributeDefaults = {
    showInForm: true,
    name: "",
    type: "text",
};

const optionDefaults = {
    name: "",
    add: "",
};

const userRoles = {
    normal: {
        name: "Normal",
    },
    admin: {
        name: "Admin",
    },
};

const formatFunctions = {
    text: (value) => value || "",
    number: (value) => (value === undefined ? "" : +value),
    phone: (value) => formatPhone(value),
    date: (value) => {
        const proposedDate = new Date(value);
        return value === null || isNaN(proposedDate.getTime())
            ? ""
            : proposedDate.toLocaleDateString();
    },
    select: (value, attribute) => {
        const option =
            (attribute.options || []).find((option) => option.id === value) ||
            {};

        return option.name || "";
    },
    list: (value, attribute) => {
        let formatted = "";

        for (const item of Object.values(value || {})) {
            if (attribute.itemType)
                formatted =
                    formatted +
                    formatFunctions[attribute.itemType](item, attribute) +
                    ", ";
        }

        return formatted.slice(0, -2); //remves trailing comma
    },
    group: (value) => {
        return "";
    },
};

const defaultFilters = {
    all: {
        name: "All",
        run: () => true,
    },
};

const filterOptions = {
    type: {
        condition: {
            name: "Condition",
        },
        group: {
            name: "Group",
        },
        // filter: {
        //     name: "Another Filter",
        // },
    },
    groupType: {
        and: {
            name: "All Are True",
        },
        or: {
            name: "Any Are True",
        },
    },
};

const filterSequenceValueInputs = {
    text: {
        type: "input",
        key: "value",
        valueType: "text",
    },
    number: {
        type: "input",
        key: "value",
        valueType: "number",
    },
    select: {
        type: "input",
        key: "value",
        valueType: "select",
    },
    date: {
        type: "input",
        key: "value",
        valueType: "date",
    },
};

const quantifiers = {
    isSet: {
        name: "is set",
        evaluate: (val) => {
            return val !== undefined;
        },
    },
    isNotSet: {
        name: "is not set",
        evaluate: (val) => {
            return val === undefined;
        },
    },
    equal: {
        name: "is equal to",
        value: filterSequenceValueInputs.text,
        evaluate: (val, req) => {
            return (val || "") === req.value;
        },
    },
    notEqual: {
        name: "is not equal to",
        value: filterSequenceValueInputs.text,
        evaluate: (val, req) => {
            return !((val || "") === req.value);
        },
    },
    contains: {
        name: "contains",
        value: filterSequenceValueInputs.text,
        evaluate: (val, req) => {
            return (val || "").includes(req.value);
        },
    },
    doesNotContain: {
        name: "doesn't contain",
        value: filterSequenceValueInputs.text,
        evaluate: (val, req) => {
            return !(val || "").includes(req.value);
        },
    },
    numEqual: {
        name: "is equal to",
        value: filterSequenceValueInputs.number,
        evaluate: (val, req) => {
            if (isNaN(val) || isNaN(req.value)) return false;

            return +val === +req.value;
        },
    },
    numNotEqual: {
        name: "is not equal to",
        value: filterSequenceValueInputs.number,
        evaluate: (val, req) => {
            if (isNaN(val) || isNaN(req.value)) return false;

            return +val !== +req.value;
        },
    },
    greaterThan: {
        name: "is greater than",
        value: filterSequenceValueInputs.number,
        evaluate: (val, req) => {
            if (isNaN(val) || isNaN(req.value)) return false;

            return +val > +req.value;
        },
    },
    lessThan: {
        name: "is less than",
        value: filterSequenceValueInputs.number,
        evaluate: (val, req) => {
            if (isNaN(val) || isNaN(req.value)) return false;

            return +val < +req.value;
        },
    },
    selectEqual: {
        name: "is equal to",
        value: filterSequenceValueInputs.select,
        evaluate: (val, req) => {
            return val === req.value;
        },
    },
    selectNotEqual: {
        name: "is not equal to",
        value: filterSequenceValueInputs.select,
        evaluate: (val, req) => {
            return val !== req.value;
        },
    },
    dateEqual: {
        name: "is equal to",
        value: filterSequenceValueInputs.date,
        evaluate: (val, req) => {
            let d1 = new Date(val);
            let d2 = new Date(req.value);

            if (isNaN(d1) || isNaN(d2)) return false;

            return (
                d1.getDate() === d2.getDate() &&
                d1.getMonth() === d2.getMonth() &&
                d1.getFullYear() === d2.getFullYear()
            );
        },
    },
    dateNotEqual: {
        name: "is not equal to",
        value: filterSequenceValueInputs.date,
        evaluate: (val, req) => {
            let d1 = new Date(val);
            let d2 = new Date(req.value);

            if (isNaN(d1) || isNaN(d2)) return false;

            return (
                d1.getDate() !== d2.getDate() ||
                d1.getMonth() !== d2.getMonth() ||
                d1.getFullYear() !== d2.getFullYear()
            );
        },
    },
    isBefore: {
        name: "is before",
        value: filterSequenceValueInputs.date,
        evaluate: (val, req) => {
            let d1 = new Date(val);
            let d2 = new Date(req.value);

            if (isNaN(d1) || isNaN(d2)) return false;

            return d1.getTime() < d2.getTime();
        },
    },
    isAfter: {
        name: "is after",
        value: filterSequenceValueInputs.date,
        evaluate: (val, req) => {
            let d1 = new Date(val);
            let d2 = new Date(req.value);

            if (isNaN(d1) || isNaN(d2)) return false;

            return d1.getTime() > d2.getTime();
        },
    },
    lessAgo: {
        name: "is less than n days ago",
        value: filterSequenceValueInputs.number,
        evaluate: (val, req) => {
            const daysBetween = Math.abs(
                Math.round(+val - new Date().getTime()) / (1000 * 60 * 60 * 24)
            );

            return +val < new Date().getTime() && daysBetween < +req.value;
        },
    },
    moreAgo: {
        name: "is more than n days ago",
        value: filterSequenceValueInputs.number,
        evaluate: (val, req) => {
            const daysBetween = Math.abs(
                Math.round(+val - new Date().getTime()) / (1000 * 60 * 60 * 24)
            );

            return +val < new Date().getTime() && daysBetween > +req.value;
        },
    },
    lessAway: {
        name: "is less than n days away",
        value: filterSequenceValueInputs.number,
        evaluate: (val, req) => {
            const daysBetween = Math.abs(
                Math.round(+val - new Date().getTime()) / (1000 * 60 * 60 * 24)
            );

            return +val > new Date().getTime() && daysBetween < +req.value;
        },
    },
    moreAway: {
        name: "is more than n days away",
        value: filterSequenceValueInputs.number,
        evaluate: (val, req) => {
            const daysBetween = Math.abs(
                Math.round(+val - new Date().getTime()) / (1000 * 60 * 60 * 24)
            );

            return +val > new Date().getTime() && daysBetween > +req.value;
        },
    },
};

const arrayToProperObject = (arr, source) => {
    const ob = {};

    for (const id of arr) {
        ob[id] = { ...source[id], id };
    }

    return ob;
};

const typeFilterSequences = {
    text: {
        type: "list",
        key: "quantifier",
        getValues: () => {
            let q = [
                "isSet",
                "isNotSet",
                "equal",
                "notEqual",
                "contains",
                "doesNotContain",
            ];
            return arrayToProperObject(q, quantifiers);
        },
        getNext: (props) => {
            let quantifier = quantifiers[props.sequence.quantifier];
            return quantifier?.value || {};
        },
    },
    number: {
        type: "list",
        key: "quantifier",
        getValues: () => {
            let q = [
                "isSet",
                "isNotSet",
                "numEqual",
                "numNotEqual",
                "greaterThan",
                "lessThan",
            ];
            return arrayToProperObject(q, quantifiers);
        },
        getNext: (props) => {
            let quantifier = quantifiers[props.sequence.quantifier];
            return quantifier?.value || {};
        },
    },
    list: {
        type: "list",
        key: "subAttribute",
        getValues: () => {
            let subs = ["len", "firstEl", "lastEl", "anyEl", "noEl", "allEl"];
            return arrayToProperObject(subs, subAttributes);
        },
        getNext: (props) => {
            let sub = subAttributes[props.sequence.subAttribute];

            return sub.next || {};
        },
    },
    phone: {
        type: "list",
        key: "quantifier",
        getValues: () => {
            let q = ["isSet", "isNotSet"];
            return arrayToProperObject(q, quantifiers);
        },
        getNext: (props) => {
            let quantifier = quantifiers[props.sequence.quantifier];
            return quantifier?.value || {};
        },
    },
    select: {
        type: "list",
        key: "quantifier",
        getValues: () => {
            let q = ["isSet", "isNotSet", "selectEqual", "selectNotEqual"];
            return arrayToProperObject(q, quantifiers);
        },
        getNext: (props) => {
            let quantifier = quantifiers[props.sequence.quantifier];
            return quantifier?.value || {};
        },
    },
    date: {
        type: "list",
        key: "quantifier",
        getValues: () => {
            let q = [
                "isSet",
                "isNotSet",
                "dateEqual",
                "dateNotEqual",
                "isAfter",
                "isBefore",
                "lessAgo",
                "moreAgo",
                "lessAway",
                "moreAway",
            ];

            return arrayToProperObject(q, quantifiers);
        },
        getNext: (props) => {
            let quantifier = quantifiers[props.sequence.quantifier];
            return quantifier?.value || {};
        },
    },
};

const groupSequence = {
    type: "list",
    key: "groupAttribute",
    getValues: (props) => {
        const group = props.attributes.find(
            (a) => a.id === props.sequence.attribute
        ).listGroup;
        const atts = {};

        for (const att of props.attributes) {
            if (att.groupid === group)
                atts[att.id] = props.attributes.find((a) => a.id === att.id);
        }

        return { ...atts };
    },
    getNext: (props) => {
        const type = (
            props.attributes.find(
                (a) => a.id === props.sequence.groupAttribute
            ) || {}
        ).type;

        return typeFilterSequences[type] || {};
    },
};

const subAttributes = {
    len: {
        name: "number of items",
        next: typeFilterSequences.number,
        defineValue: (val) => {
            return Object.keys(val || {}).length;
        },
    },
    firstEl: {
        name: "first element's",
        next: groupSequence,
        defineValue: (val, req) => {
            const keys = Object.keys(val || {});
            if (keys.length === 0) return "";

            const el = val[keys[0]];

            if (req.groupAttribute) {
                return el[req.groupAttribute];
            } else return {};
        },
    },
    lastEl: {
        name: "last element's",
        next: groupSequence,
        defineValue: (val, req) => {
            const keys = Object.keys(val || {});
            if (keys.length === 0) return "";

            const el = val[keys[keys.length - 1]];

            if (req.groupAttribute) {
                return el[req.groupAttribute];
            } else return {};
        },
    },
    anyEl: {
        name: "has any element where",
        next: groupSequence,
        defineValue: (val, req) => {
            const keys = Object.keys(val || {});

            if (!req.groupAttribute) return [];

            return keys.map((key) => val[key][req.groupAttribute]);
        },
        evaluate: (func, vals, req) => {
            for (const val of vals || []) {
                if (func(val, req)) return true;
            }
            return false;
        },
    },
    noEl: {
        name: "has no element where",
        next: groupSequence,
        defineValue: (val, req) => {
            const keys = Object.keys(val || {});

            if (!req.groupAttribute) return [];

            return keys.map((key) => val[key][req.groupAttribute]);
        },
        evaluate: (func, vals, req) => {
            for (const val of vals || []) {
                if (func(val, req)) return false;
            }
            return true;
        },
    },
    allEl: {
        name: "has all elements where",
        next: groupSequence,
        defineValue: (val, req) => {
            const keys = Object.keys(val || {});

            if (!req.groupAttribute) return [];

            return keys.map((key) => val[key][req.groupAttribute]);
        },
        evaluate: (func, vals, req) => {
            if ((vals || []).length === 0) return false;
            for (const val of vals) {
                if (!func(val, req)) return false;
            }
            return true;
        },
    },
};

const filterSequences = {
    attribute: {
        type: "list",
        key: "attribute",
        getValues: (props) => {
            return props.attributes
                .filter((attribute) => !attribute.isListed)
                .reduce((ob, att) => {
                    ob[att.id] = att;
                    return ob;
                }, {});
        },
        getNext: ({ attributes, sequence }) => {
            const attributeType = (
                attributes.find((a) => a.id === sequence.attribute) || {}
            ).type;

            return { ...(typeFilterSequences[attributeType] || {}) };
        },
    },
};

const presetDateValues = {
    none: {
        name: "None",
    },
    curr: {
        name: "Current Date",
    },
};

const master = {
    attributeTypes,
    attributeDefaults,
    optionDefaults,
    userRoles,
    formatFunctions,
    defaultFilters,
    filterOptions,
    presetDateValues,
    filterSequences,
    subAttributes,
    quantifiers,
};

export const getList = (id) => {
    return master[id] || {};
};
