import React from "react";
import styles from "./square_selection.module.css";

const SquareSelection = (props) => {
    let squares = props.squares || [];

    if (props.onAdd) {
        squares = squares.concat(["adder"]);
    }

    if (squares.length === 0) {
        return (
            <div className={styles.noSquares}>
                {props.noSquares || "Nothing Here..."}
            </div>
        );
    }

    return (
        <div className={styles.squares}>
            {squares.map((square) => {
                if (square === "adder") {
                    return (
                        <div
                            className={
                                styles.squareAdder +
                                " " +
                                (props.disableAdd && "disabled")
                            }
                            key={"square-adder"}
                            onClick={props.onAdd}
                        >
                            <i className="fas fa-plus"></i>
                        </div>
                    );
                }

                return (
                    <div
                        key={"square-" + square.id}
                        className={styles.square}
                        style={{
                            backgroundColor:
                                props.squareColor || "var(--logo-color)",
                            color:
                                props.squareTextColor ||
                                "var(--logo-text-color)",
                        }}
                        onClick={() => {
                            if (props.onClick) props.onClick(square.id);
                        }}
                    >
                        {square.name}
                    </div>
                );
            })}
        </div>
    );
};

export default SquareSelection;
