import React from "react";
import { getList } from "../../../listStore";

const ListItem = (props) => {
    const type = getList("attributeTypes").group;

    const items = props.val || [];

    const addItem = () => {
        props.setVal(items.concat(type.itemDefault || ""));
    };

    const removeItem = (index) => {
        const pseudo = [...items];
        pseudo.splice(index, 1);
        props.setVal(pseudo);
    };

    return (
        <div>
            {items.map((item, index) => {
                const El = type.ListEditorElement || (() => null);

                return (
                    <div
                        style={{
                            marginBottom: "20px",
                            display: "flex",
                            alignItems: "center",
                        }}
                        key={props.attribute.id + "-item-" + index}
                    >
                        <div
                            className="danger-link"
                            style={{ marginRight: "20px" }}
                            onClick={() => removeItem(index)}
                        >
                            <i className="fas fa-trash-alt"></i>
                        </div>
                        <El
                            {...props}
                            item={item}
                            index={index}
                            hubid={props.hubid}
                            setVal={(changes) => {
                                const pseudo = [...items];
                                pseudo[index] = {
                                    ...pseudo[index],
                                    ...changes,
                                };
                                props.setVal(pseudo);
                            }}
                        />
                    </div>
                );
            })}
            <button className="button is-link is-small" onClick={addItem}>
                <i
                    className="fas fa-plus"
                    style={{ fontSize: "12px", marginRight: "4px" }}
                ></i>{" "}
                Add To {props.attribute.name}
            </button>
        </div>
    );
};

export default ListItem;
