import React from "react";
import Checkbox from "../../../checkbox/checkbox";

const TextSettings = (props) => {
    return (
        <React.Fragment>
            <div className="input-label">Size</div>
            <Checkbox
                name="Long Text"
                value={props.attribute.isLong}
                onChange={(val) => {
                    props.set_value("isLong", val, true);
                }}
            />
        </React.Fragment>
    );
};

export default TextSettings;
