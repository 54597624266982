import React from "react";
import GroupItemSettings from "../groupitemsettings/groupitemsettings";

const ListSettings = (props) => {
    return (
        <React.Fragment>
            <div className="input-label">Listed Group</div>
            <GroupItemSettings {...props} />
        </React.Fragment>
    );
};

export default ListSettings;
