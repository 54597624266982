// import Server from "./Server";
import { getList } from "./listStore";

const specialCases = {
    dateCreated: {
        type: "date",
    },
};

export const transformTemplate = async (template, values, attributes) => {
    if (!attributes)
        throw new Error("NO ATTRIBUTES PROVIDED TO TRANSFORM TEMPLATE");
    if (!template) return "";
    if (!values) return "";

    const formatFunctions = getList("formatFunctions");

    const reggie = new RegExp("{{2}(.*?)}{2}", "g");
    const matches = template.match(reggie) || [];

    let toReturn = [];
    let lastIndex = 0;

    for (const match of matches) {
        const index = template.indexOf(match);
        const leading = template
            .substring(lastIndex, index)
            .replace(/\\n/g, "\n");

        toReturn.push(leading);

        const id = match.substring(2, match.length - 2);

        let attribute;

        if (specialCases[id]) {
            attribute = { ...specialCases[id] };
        } else {
            attribute = attributes.find((a) => a.id === id);
        }

        if (!attribute) continue;

        toReturn.push(formatFunctions[attribute.type](values[id], attribute));

        lastIndex = index + match.length;
    }
    toReturn.push(template.substring(lastIndex, template.length));

    return toReturn.join("");
};
