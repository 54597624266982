import React from "react";
import styles from "./attributesettings.module.css";
import CacheContext from "../../contexts/CacheContext";
import { sortableElement, sortableContainer } from "react-sortable-hoc";
import arrayMove from "array-move";
import Select from "../select/select";
import arrayify from "../../arrayify";
import { getList } from "../../listStore";
import Dropdown from "../dropdown/dropdown";
import Checkbox from "../checkbox/checkbox";
import SelectSettings from "./items/selectsettings/selectsettings";
import ListSettings from "./items/listsettings/listsettings";
import TextSettings from "./items/textsettings/textsettings";
import DateSettings from "./items/datesettings/datesettings";
import useConfig from "../../hooks/useConfig";

const attributeTypes = getList("attributeTypes");

const settingsElements = {
    select: SelectSettings,
    list: ListSettings,
    text: TextSettings,
    date: DateSettings,
};

const AttributeEditor = (props) => {
    const { has_premium } = useConfig();

    if (props.attribute === -1) {
        return (
            <div
                style={{
                    color: "var(--light-text-color)",
                    fontSize: "18px",
                    fontWeight: "500",
                    textAlign: "center",
                    marginTop: "20px",
                }}
            >
                No attribute selected...
            </div>
        );
    }

    const Settings = settingsElements[props.attribute.type] || (() => null);

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                <div
                    style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                    }}
                >
                    Edit Attribute
                </div>
                <Dropdown direction="left">
                    <Dropdown.Item onClick={props.on_delete}>
                        <div className="danger-link">
                            <i
                                className="fas fa-trash-alt"
                                style={{ marginRight: "6px" }}
                            ></i>
                            Delete Attribute
                        </div>
                    </Dropdown.Item>
                </Dropdown>
            </div>

            <div className="input-label">Name</div>
            <input
                type="text"
                className="input"
                value={props.attribute.name || ""}
                onChange={(e) => props.set_value("name", e.target.value)}
                onBlur={() => props.save_changes()}
            />
            <div className="input-label">Visibility</div>
            <Checkbox
                name="Show In Form"
                value={props.attribute.showInForm}
                onChange={(v) => {
                    props.set_value("showInForm", v, true);
                }}
            />
            <div className="input-label">Type</div>
            <Select
                values={arrayify(attributeTypes, "value").filter(
                    (type) => type.hidden !== true
                )}
                value={(() => {
                    const found = arrayify(attributeTypes, "value").find(
                        (att) => att.value === props.attribute.type
                    );
                    return found;
                })()}
                onSelect={(v) => {
                    props.set_value("type", v.value, true);
                }}
            />
            <Settings
                hubid={props.hubid}
                groupid={props.groupid}
                attribute={props.attribute}
                set_value={props.set_value}
                save_changes={props.save_changes}
            />
        </div>
    );
};

const AttributeListItem = sortableElement(
    ({ attribute, selected, on_select }) => {
        return (
            <div
                onClick={on_select}
                className={
                    styles.attribute + " " + (selected && styles.selected)
                }
            >
                {attribute.name || (
                    <span style={{ fontWeight: "400" }}>No Name</span>
                )}
            </div>
        );
    }
);

const AttributeSettings = (props) => {
    const { hubid, groupid } = props.match.params;
    const { has_premium } = useConfig();
    const { request, execute } = React.useContext(CacheContext);
    const [group, set_group] = React.useState(-1);
    const [selected_attribute, set_selected_attribute] = React.useState(-1);

    React.useEffect(() => {
        const { has_loaded, data } = request("group", groupid);

        if (!has_loaded) return;

        set_group(data);
    }, [request, groupid]);

    if (group === -1) {
        return (
            <div
                className="link"
                style={{
                    padding: "30px 0px",
                    textAlign: "center",
                    fontSize: "24px",
                }}
            >
                <i className="fas fa-circle-notch fa-spin"></i>
            </div>
        );
    }

    const { attributes } = group;

    const handle_sort_end = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return;
        const re_sorted = arrayMove([...attributes], oldIndex, newIndex).filter(
            (a) => a
        );

        execute(
            "update_many_attributes",
            re_sorted.reduce((ob, attribute, index) => {
                ob[attribute.id] = { index };
                return ob;
            }, {})
        );
    };

    const AttributeList = sortableContainer((props) => {
        return <div className={styles.attributes}>{props.children}</div>;
    });

    return (
        <div className={styles.wrap}>
            <div className={styles.left}>
                <AttributeList onSortEnd={handle_sort_end}>
                    {attributes
                        .sort((a, b) => a.index - b.index)
                        .map((attribute) => (
                            <AttributeListItem
                                key={attribute.id}
                                attribute={attribute}
                                index={attribute.index}
                                selected={
                                    selected_attribute.id === attribute.id
                                }
                                on_select={() =>
                                    set_selected_attribute(attribute)
                                }
                            />
                        ))}
                </AttributeList>
                <div
                    className={
                        styles.add_attribute_wrap +
                        " " +
                        (!has_premium && attributes.length >= 10 && "disabled")
                    }
                    onClick={() => {
                        execute("create_attribute", groupid).then((att) => {
                            set_selected_attribute(att);
                        });
                    }}
                >
                    <div className={styles.add_attribute}>New Attribute</div>
                </div>
            </div>
            <div className={styles.right}>
                <AttributeEditor
                    attribute={selected_attribute}
                    groupid={groupid}
                    hubid={hubid}
                    on_delete={() => {
                        execute(
                            "delete_attribute",
                            selected_attribute.id
                        ).then(() => set_selected_attribute(-1));
                    }}
                    set_value={(key, value, save_immediately = false) => {
                        const updated_attribute = {
                            ...selected_attribute,
                            [key]: value,
                        };
                        set_selected_attribute({
                            ...selected_attribute,
                            [key]: value,
                        });

                        if (save_immediately) {
                            execute("update_attribute", {
                                attributeid: selected_attribute.id,
                                changes: {
                                    ...updated_attribute,
                                },
                            });
                        }
                    }}
                    save_changes={() => {
                        execute("update_attribute", {
                            attributeid: selected_attribute.id,
                            changes: {
                                ...selected_attribute,
                            },
                        });
                    }}
                />
            </div>
        </div>
    );
};

export default AttributeSettings;
