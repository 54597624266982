import React from "react";
import NumberGroupList from "../../groupListViewElements/number_group_list/number_group_list";
import CacheContext from "../../../contexts/CacheContext";

const NumberViewItem = (props) => {
    const { ticketid } = props;
    const { execute } = React.useContext(CacheContext);
    const [proposed, setProposed] = React.useState();

    React.useEffect(() => {
        setProposed(props.value);
    }, [props.value]);

    return (
        <NumberGroupList
            attribute={props.attribute}
            value={proposed}
            onChange={(val) => {
                setProposed(val);
            }}
            onBlur={() => {
                if (proposed !== props.value)
                    execute("update_ticket", {
                        ticketid,
                        changes: {
                            [props.attribute.id]: proposed,
                        },
                    });
            }}
        />
    );
};

export default NumberViewItem;
