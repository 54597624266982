import React from "react";
import styles from "./home.module.css";
import SquareSelection from "../square_selection/square_selection";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts";
import CacheContext from "../../contexts/CacheContext";

const Home = (props) => {
    const { auth } = React.useContext(AuthContext);
    const { request } = React.useContext(CacheContext);
    const [hubs, set_hubs] = React.useState(-1);

    React.useEffect(() => {
        const { has_loaded, data } = request("hubs");

        if (!has_loaded) return;

        set_hubs(data.hubs);
    }, [request]);

    if (hubs === -1) return <div>Loading...</div>;

    return (
        <div
            style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}
        >
            {hubs.length === 0 ? (
                <div className={styles.wrap}>
                    <div className="title" style={{ textAlign: "center" }}>
                        You don't have any hubs yet...
                    </div>

                    {auth.role === "admin" && (
                        <div style={{ marginTop: "30px" }}>
                            <Link
                                className="button is-light"
                                style={{ marginRight: "10px" }}
                                to="/settings/hubs"
                            >
                                Create Hub
                            </Link>
                            <span
                                className="button"
                                onClick={() => {
                                    window.open(
                                        "https://learn.hubtrk.com/getting-started/quick-start-guide",
                                        "_blank"
                                    );
                                }}
                            >
                                Quick Start Guide
                            </span>
                        </div>
                    )}
                </div>
            ) : (
                <SquareSelection
                    onClick={(value) => {
                        props.history.push("/" + value);
                    }}
                    squares={hubs.sort((a, b) => a.id.localeCompare(b.id))}
                />
            )}
        </div>
    );
};

export default Home;
